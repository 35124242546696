import { useNavigate } from 'react-router-dom';
import '../estilos/itemGM.css'; 
import { obtenerImagen } from '../funcionesFront';
import { useState, useEffect } from 'react';

function ItemGM({ data }) {

    const [img, setImg] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        obtenerImagen('medicos', data.id, (img) => setImg(img));
    }, []);


  return (
    <div className="doctor-container" onClick={() => navigate(`/guia-medica/infoMedico/${data.id}`)}>
      <div className="doctor-photo" ><img className="foto-medico" src={img}></img></div>
      <div className='doctor-info'>
        <div className="doctor-name">{`${data.sexo == 'Masculino' ? 'Dr. ' : 'Dra. '}` + data.nombres + " " + data.apellidos}</div>
        <div className="doctor-name">{data.especialidad}</div>
        {data.ciudades.split(',').map((ciudad, index) => (
          <div className="doctor-location">{`${ciudad}, ${data.estados.split(',')[index]}`}</div>
        ))}
      </div>
      
    </div>
  );
}

export default ItemGM;
