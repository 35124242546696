import '../estilos/paginaReservacion.css';
import DatosMedico from './datosMedico';
import BotonCita from './botonCita';
import Modal from './ventanaConfirmacion';
import VentanaInfoCita from './ventanaInfoCita';
import VentanaAgregarDia from './ventanaAgregarDia';
import SimboloMas from '../iconos/simboloMas';
import IconoCalendarioX from '../iconos/iconoCalendarioX';
import IconoCalendario from '../iconos/iconoCalendario';
import IconoList from '../iconos/iconoList';
import { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import {determinarCitas, determinarDias, obtenerImagen, obtenerDatosUsuario, buscarEstadoAcento, buscarCiudadAcento, fechaHoy, fechaHoyConGuion, isDateLessOrEqual} from '../funcionesFront.js';
import { useParams } from 'react-router-dom';
import { cambiarTitulo } from '../funcionesFront.js';
import VentanaModificarDia from './ventanaModificarDia';
import VentanaProgramarDiaLibre from './ventanaProgramarDiaLibre';
import moment from 'moment';
import Cookies from 'js-cookie';
import AuthContext from './authContext';
import CirculoGirador from '../iconos/circuloGirador';
import MedicoNoDisponible from './medicoNoDisponible';
import AvisoPago from './avisoPago';
import apiUrl from '../url.js';
import sinFoto from '../imagenes/sinfoto.jpg';




function PaginaReservacion() {

  const navigate = useNavigate();
  
  const {setDatosUsuario} = useContext(AuthContext);
  
  const {idMedico} = useParams();

  var datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

  const medicoTitular = datosUsuario.rol === 'medicos' && datosUsuario.id == idMedico;

  //obtiene la informacion al dar clic al boton de una cita disponible para poder mostrarla en la ventana de confirmacion
  const [horarioReserva, setReserva] = useState('');

  //data o respuesta que se recibe del servidor para actualizar el estado y renderizar de nuevo
  const [data, setData] = useState('');
  var tituloPagina = datosUsuario.rol === "medicos" ? "Administración de citas" : `Reservación | ${data.sexo === 'Masculino' ? "Dr. " : "Dra. "} ${data.apellidos}`;
  cambiarTitulo(document, tituloPagina);  
  
  //reacomoda las citas a partir de la data, tomando en cuenta el horario, la proyeccion y los descansos
  const [citas, setCitas] = useState('');
  const [citasHorarioNuevo, setCitasHorarioNuevo] = useState({
    fecha: ''
  });
  const [proximosDias, setProximosDias] = useState('');
  const [diasCancelados, setDiasCancelados] = useState('');
  const [ubicacion, setUbicacion] = useState('');

  //maneja el estado para abrir o cerrar la ventana de confirmacion
  const [isModalOpen, setModalOpen] = useState(false);

  const [isVentanaInfoCitaOpen, setVentanaInfoCitaOpen] = useState(false);
  const [infoCita, setInfoCita] = useState('');  //para que los medicos puedan ver las citas ya reservadas
  const [imgPaciente, setImgPaciente] = useState(sinFoto);

  const [ventanaAgregarDiaOpen, setVentanaAgregarDiaOpen] = useState(false);

  const [ventanaModificarDiaOpen, setVentanaModificarDiaOpen] = useState(false);
  const [diaAModificar, setDiaAModificar] = useState('');

  const [ventanaProgramarDiaLibreOpen, setVentanaProgramarDiaLibreOpen] = useState(false);
  const [modoVentanaCalendario, setModoVentanaCalendario] = useState('')

  //para determinar los horarios que no estan disponibles
  const [horariosReservados, setHorariosReservados] = useState('');

  const [desplegarMenu, setDesplegarMenu] = useState(false);

  useEffect(() => {
    fetch(apiUrl + `/api/datosPaginaReservacion/${idMedico}`, {
      method: "GET", 
      headers: {
          'Content-Type': 'application/json',
          datos: {
            tabla: datosUsuario.rol,
            id: datosUsuario.id
          },
      },
          
   })
    .then((res) => res.json())
    .then((data) => data === '!s1' ? navigate('/*') : setData(data)); // !s1 significa que el cliente no tiene el servicio de adm de citas 
    
  }, [isModalOpen, isVentanaInfoCitaOpen, ventanaAgregarDiaOpen, ventanaModificarDiaOpen, ventanaProgramarDiaLibreOpen]);

  useEffect(() => {
    if(data && ubicacion === ''){
      setUbicacion(data.ubicaciones[0]);
    }
  }, [data]);


  useEffect(() => {
    if (ubicacion) {
      setCitas(determinarCitas(ubicacion));
      if(ubicacion.cambioHorarios){
        const cambioHorarios = ubicacion.cambioHorarios.split(';');
        setCitasHorarioNuevo({
          citas:
                determinarCitas({
                  horarioNormal: cambioHorarios[2],
                  duracionCita: cambioHorarios[3],
                  descansoDiario: cambioHorarios[4],
              }),
          fecha: cambioHorarios[0],
          diasDeTrabajo: cambioHorarios[1],
          proyeccionCitas: cambioHorarios[5]
          })
      }else{
        setCitasHorarioNuevo({
          fecha: ''
        })
      }
      setProximosDias(determinarDias(ubicacion.proyeccionCitas));
      setHorariosReservados(data.reservaciones.filter(reservacion => reservacion.ubicacion == ubicacion.id));
      setDiasCancelados(Object.values(data.diasCancelados.filter(dia => dia.ubicacion == ubicacion.id)).map(a => a['fecha']));
    }  
  }, [ubicacion, data]);

  const acciones = (citaReservada, datos) => {

    if(citaReservada==false){
        setModalOpen(true);
        setReserva(datos);
    }
  };

    //para medicos
  const mostrarDatosCita = (idReserva) => {

    fetch(apiUrl + `/api/paginaReservacion/consultarcita/${idReserva}`, {
        method: "GET", 
        headers: {
            'Content-Type': 'application/json',
            'auth': Cookies.get('authToken'),
            'datosUsuario': Cookies.get('datosUsuario')
        },
            
     })


    .then((res) => res.json())
    .then((data) => {
      
          
         //setDatosUsuario({});
         setInfoCita(data);
        
         if (data.idPaciente !== 0){
            data.idPaciente == -1 ? setImgPaciente(sinFoto) : obtenerImagen('pacientes', data.id, (img) => setImgPaciente(img));
          }

    });

    setVentanaInfoCitaOpen(true);
    
}

  var reservado = false; //maneja si se manda un prop al boton si esta reservado o no

  var horariosEspeciales = {};
  var mismoDia;
  if(data){
    
		data.horariosEspeciales.forEach(objeto => {
			objeto.ubicacion == ubicacion.id ? horariosEspeciales[objeto.fecha] = objeto.hora.split(';') : null;
		});

		/*if(Object.keys(horariosEspeciales).includes(fechaHoyConGuion()) && ubicacion.reservaMismoDia == false && datosUsuario.rol !== 'medicos'){
			delete horariosEspeciales[fechaHoyConGuion()];
		}*/

		
 
    }

  const handleModificarDia = (dia, modificacion) => {
    setDiaAModificar({
      fecha: dia,
      modificacion: modificacion
    });
    setVentanaModificarDiaOpen(true);
  };

  const abrirVentanaCalendario = (modo) => {
      setModoVentanaCalendario(modo);
      setVentanaProgramarDiaLibreOpen(true);
  }
  

var diaCancelado;


return (
  
  <div className="App">
    	{citas && proximosDias ? (
      		<>
        		<DatosMedico data={data}/>
        		Ubicaciones Disponibles
        		<div className="dropdown">
            		<button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              		{`${ubicacion.direccion}, ${buscarCiudadAcento(ubicacion.estado, ubicacion.ciudad)} - Edo. ${buscarEstadoAcento(ubicacion.estado)}`}
            		</button>
            		<ul className="dropdown-menu menu-fecha">
                		{data.ubicaciones.map((ubicacion, index) => 
                
                			<li key={ubicacion.direccion}><a className="dropdown-item dias-no-laborables" onClick={() => setUbicacion(data.ubicaciones[index])}>{ `${ubicacion.direccion}, ${buscarCiudadAcento(ubicacion.estado, ubicacion.ciudad)} - Edo. ${buscarEstadoAcento(ubicacion.estado)}` }</a></li>
                
                		)}
            		</ul>
        		</div>
            <h2>Reserva de Citas</h2>
        		{proximosDias.map((dia) => ( 
					dia != fechaHoyConGuion() || (ubicacion.reservaMismoDia || datosUsuario.rol == 'medicos') ? 
						((isDateLessOrEqual(citasHorarioNuevo.fecha, dia) ? ubicacion.diasDeTrabajo : citasHorarioNuevo.diasDeTrabajo).includes(moment(dia).format("dddd, D \\d\\e MMMM \\d\\e YYYY").substring(0,2)) || Object.keys(horariosEspeciales).includes(dia)) ? 				
							(<>
								<div key={dia} className="row contenedor-dia">
									<span className='header-dia'>
										<h3 style={{fontSize: '1.5rem'}}>{moment(dia).format("dddd, D \\d\\e MMMM \\d\\e YYYY").replace(/^\w/, (c) => c.toUpperCase())}</h3>
										{diaCancelado = diasCancelados.includes(dia)}
										{medicoTitular ? <button className={`btn-${diaCancelado ? 'success' : 'danger'} boton-cancelar-dia`} onClick={!diaCancelado ? () => handleModificarDia(dia, "retirardia") : () => handleModificarDia(dia, "reincorporardia")}>{!diaCancelado ? "Cancelar día de trabajo" : "Reincorporar día de trabajo"}</button> : null}
									</span>
									{!diaCancelado ? (
										(Object.keys(horariosEspeciales).includes(dia) ? horariosEspeciales[dia] : (isDateLessOrEqual(citasHorarioNuevo.fecha, dia) ? citas : citasHorarioNuevo.citas)).map((cita) => (
										
											<BotonCita reservar={acciones} reservado={horariosReservados.find((horariosReservados) => horariosReservados.fecha == dia && horariosReservados.hora == cita) || ''} key={cita} datos={{"cita": cita, "fecha": dia}} mostrarDatosCita={mostrarDatosCita}>
											{cita}
											</BotonCita>
										))
									) 
									: 
									null
									}
								</div>
							</>) 
						: null
					:
					null
						
				)
				)}
        		<Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} datosReserva={horarioReserva} data={data} ubicacion={ubicacion}/>
        		{medicoTitular ? (
				<span>
					<VentanaInfoCita isOpen={isVentanaInfoCitaOpen} onClose={() => setVentanaInfoCitaOpen(false)} datos={infoCita} imgPaciente={imgPaciente} ubicacion={ubicacion}/>
					<VentanaAgregarDia isOpen={ventanaAgregarDiaOpen} onClose={() => setVentanaAgregarDiaOpen(false)} datos={data} citasNormales={citas} diasEspeciales={Object.keys(horariosEspeciales)} ubicacion={ubicacion}/>
					<VentanaModificarDia isOpen={ventanaModificarDiaOpen} onClose={() => setVentanaModificarDiaOpen(false)} dia={diaAModificar} ubicacion={ubicacion} diasCancelados={diasCancelados}/>
					<VentanaProgramarDiaLibre isOpen={ventanaProgramarDiaLibreOpen} onClose={() => setVentanaProgramarDiaLibreOpen(false)} mode={modoVentanaCalendario} ubicacion={ubicacion} diasCancelados={diasCancelados}/>
				</span>
            
        ) : null
        }
        {medicoTitular ? (

            <span>
				<button className={`btn btn-success boton-list ${desplegarMenu ? 'rotated' : '' }`} onClick={() => setDesplegarMenu(!desplegarMenu)}>
					<IconoList/>
				</button>

              	<span className={`menu-botones ${desplegarMenu ? 'desplegado' : ''}`}>
                	<button className='btn btn-success boton-agregar-dia-de-trabajo' onClick={() => setVentanaAgregarDiaOpen(true)}>
                  		<SimboloMas tamano={48}/>
                	</button>
					<button className='btn btn-success boton-calendario' onClick={() => abrirVentanaCalendario("reincorporardia")}>
						<IconoCalendario/>
					</button>
					<button className='btn btn-danger boton-agregar-programar-dia-libre' onClick={() => abrirVentanaCalendario("retirardia")}>
						<IconoCalendarioX/>
					</button>
              	</span>
            </span>
        )
        :
        null
        }
      </>
    ) : (
      data === '' ? 
			<CirculoGirador /> 
			: 
			datosUsuario.rol !== 'medicos' ? 
				<MedicoNoDisponible /> 
				: 
				<AvisoPago />
    )}
    
  </div>
  
);
}

export default PaginaReservacion;
