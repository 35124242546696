import React, { useState } from 'react';
import '../estilos/paginaAtencionAlCliente.css';
import { cambiarTitulo, fetchData } from '../funcionesFront';
import VentanaResultado from './ventanaResultado';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const PaginaAtencionAlCliente = () => {

  cambiarTitulo(document, 'Atención al Cliente');

   const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

   const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState('Sugerencia');
  const [details, setDetails] = useState('');

  const [resultado, setResultado] = useState('');
  const [modalResultado, setModalResultado] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleDetailsChange = (e) => {
    setDetails(e.target.value);
  };

  const enviar = async (e) => {

    e.preventDefault();
    if(details !== ""){
        const datos = {
            metodo:  "POST",
            peticion: `config/registrarRequerimiento/${datosUsuario.rol}/${datosUsuario.id}`,
            idElemento: 'boton-enviar-requerimiento',
            datosReq: {
                    tipo: selectedOption,
                    detalles: details
            }
    }

    await fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
    }else {
        setResultado({
            success: false,
            message: 'Debe llenar todos los campos'
        });

        setModalResultado(true);
    }
}


  const opciones = ["Sugerencia", "Reporte de fallas de la página", "Reclamos"];

  return (
    <div className="customer-support-container" style={{minWidth: '40vw'}}>
      <h2 className='titulo-atencion-cliente'>Atención al Cliente</h2>
      <form onSubmit={enviar}>
        <div className="form-group">
          <label className='text-form' >Selecciona una opción:</label>
          <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedOption}
                    </button>
                    <ul className="dropdown-menu menu-metodos-pago">
                        {opciones.map((opcion) => 
                            <li key={opcion}><a className="dropdown-item metodo-pago" onClick={() => setSelectedOption(opcion)}>{opcion}</a></li>
                        )}
                    </ul>
        </div>
        <div className="form-group">
          <label className='text-form'>Detalles del asunto:</label>
          <textarea
            value={details}
            onChange={handleDetailsChange}
            placeholder="Escribe los detalles aquí..."
          />
        </div>
        <button type="submit" id='boton-enviar-requerimiento'>Enviar</button>
      </form>
      <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => navigate('/')} data={resultado}/>
    </div>
  );
};

export default PaginaAtencionAlCliente;
