import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import '../estilos/carousel.css';

import { Pagination, Navigation, EffectFade, Autoplay } from 'swiper/modules';

export default function Carousel() {
  return (
    <Swiper
      modules={[Pagination, Navigation, EffectFade, Autoplay]}
      spaceBetween={30}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      effect="fade"
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false
      }}
      className="mySwiper"
      autoHeight={true}
    >
      <SwiperSlide>
        <img src="/image/swiper/banner.png" alt="Banner" />
        <div className="text-overlay">
          <h1 className="slide-heading">
            Porque <span className="highlight">tu salud es prioridad</span>, agenda una cita hoy! Con <span className="highlight">CitasFácil</span>, ir al médico jamás fue tan fácil
          </h1>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/image/swiper/carrusel1.jpg" alt="Carrusel 1" />
        <div className="text-overlay">
          <h1 className="slide-heading">
            ¡Explora nuestros servicios, administra tus citas!
          </h1>
          
          <h1 className="slide-heading">
            Con <span className="highlight">CitasFácil</span>, !estas a un solo clic!
          </h1>
        
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/image/swiper/carrusel2.jpg" alt="Carrusel 2" />
        
      </SwiperSlide>
      <SwiperSlide>
        <img src="/image/swiper/carrusel3.jpg" alt="Carrusel 3" />
        <div className="text-overlay">
          
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
