import '../estilos/ventanaAvisoUnloged.css'
import { useNavigate, useParams } from 'react-router-dom';
import SimboloExclamacion from '../iconos/simboloExclamacion';
import { useState } from 'react';
import VentanaResultado from './ventanaResultado';
import { fetchData, isNumeric, validarEmail } from '../funcionesFront';
import { da } from 'date-fns/locale';

function VentanaReservarInvitado (props) {

    const {idMedico} = useParams();

    const navigate = useNavigate();

    const [colorInput, setColorInput] = useState('#999');

    const [resultado, setResultado] = useState('');

    const [modalResultado, setModalResultado] = useState(false);

    const [checkEmail, setCheckEmail] = useState(false);

    const [datosBasicos, setDatosBasicos] = useState({
        'Nombre y Apellido': "",
        "Edad": "",
        "Correo Electrónico" : "",
        "Teléfono": "",
    });

    const inputs = {
        'Nombre y Apellido': 'Juan Pérez',
        "Edad": "Edad",
        "Correo Electrónico" : "juan@example.com",
        "Teléfono": '04125471475',
    }

    const handleCambioDatoBasicoChange = (event) => {
        const {name, value} = event.target;

        setDatosBasicos(prevState => ({
            ...prevState, 
            [name]: value,
        }));
    }

    const reservar = () => {

        const valores = Object.values(datosBasicos);

        if(valores[0] !== '' && isNumeric(valores[1]) && checkEmail && valores[3].length == 11){

            var data = props.data;
            data.idPaciente = -1;
            data.invitado = datosBasicos;

            console.log(data)
        
            const datos = {
                metodo: "POST",
                peticion: 'datosPaginaReservacion/reservar/invitado/confirmacion',
                idElemento: 'boton-reservar-cita',
                datosReq: data
            };


            fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
            setResultado('');

        }else{
            setResultado({success: false, message: "Algunos datos no son válidos, por favor verifique e intente de nuevo."});
            setModalResultado(true);
        }
    }

    return(
        props.isOpen ? (
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Reservar como invitado</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
                        </div>
                        
                        <div className="modal-body">
                            {Object.keys(inputs).map(dato => (
                                <div key={dato} onChange={handleCambioDatoBasicoChange}>
                                    <label htmlFor="exampleFormControlInput1" className="form-label" style={{paddingTop: '1.5vh'}}>{dato}</label>
                                    <input type="text" className="form-control" id={dato} placeholder={inputs[dato]} name={dato} value={datosBasicos[dato]} onChange={dato !== "Correo Electrónico" ? null : (event) => validarEmail(event, setCheckEmail) } style={{borderColor: dato !== "Correo Electrónico" ? "#999" : colorInput}}/>
                                </div>
                            ))}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success boton-aviso-iniciar-sesion" id='boton-reservar-cita' onClick={() => reservar()}>Confirmar</button>
                            <button type="button" className="btn btn-danger boton-aviso-cerrar" data-bs-dismiss="modal" onClick={props.onClose}>Cancelar</button>  
                        </div>
                        <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => navigate('/')} data={resultado}/>
                    </div>
                </div>
        </div>
        
        ) : null
    );
}

export default VentanaReservarInvitado;