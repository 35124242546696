import React from 'react';
import '../estilos/beneficios.css'; // Importa los estilos CSS
import agendar from '../imagenes/agendar.jpeg'
import medicos from '../imagenes/medicos.jpg'
import tiempo from '../imagenes/tiempo.jpg'

function BenefitsSection() {
    return (
        <div className="benefits-container">
            <h2 className="benefits-title">Beneficios de CitasFácil</h2>
            <div className="benefits-section">
                <div className="benefit-card">
                    <img src={agendar} alt="Organización" className="benefit-image"/>
                    <h3 className="benefit-title">Organización</h3>
                    <p className="benefit-text">Mejora la gestión de tus horarios y reduce los tiempos de espera con una organización eficiente de citas.</p>
                </div>
                <div className="benefit-card">
                    <img src={medicos} alt="Acceso fácil" className="benefit-image"/>
                    <h3 className="benefit-title">Fácil Acceso a Citas</h3>
                    <p className="benefit-text">Accede y gestiona tus citas médicas fácilmente desde cualquier dispositivo, en cualquier momento.</p>
                </div>
                <div className="benefit-card">
                    <img src={tiempo} alt="Publicitación" className="benefit-image"/>
                    <h3 className="benefit-title">Tiempo</h3>
                    <p className="benefit-text">Optimiza tu agenda y maximiza tu productividad. Aumenta la eficiencia de tus citas, permitiendo que tanto los profesionales como los pacientes aprovechen mejor cada minuto de su día.</p>
                </div>
            </div>
        </div>
    );
}

export default BenefitsSection;
