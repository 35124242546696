import '../estilos/paginaAccesoDenegado.css'

function MedicoNoDisponible (){
    return (
        <div className="contenedor-acceso-denegado">
            <h1 >Médico no Disponible en este momento</h1>
            <h3>Por favor intente más tarde</h3>
        </div>
    );
}

export default MedicoNoDisponible;