import { useEffect, useState } from "react";
import { solicitarDatos, convertirNumero, revertirFormatoNumero, fetchData } from "../../funcionesFront";
import CirculoGirador from '../../iconos/circuloGirador';
import VentanaResultado from '../ventanaResultado'
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import apiUrl from "../../url";


function OpcionTasa (props) {

    const [data, setData] = useState(null);

    const [resultado, setResultado] = useState('');
    const [modalResultado, setModalResultado] = useState(false);

    const navigate = useNavigate();

    //useEffect( () => solicitarDatos('/api/admin/operador/obtenerTasa', "GET", {}, setData), []);
    useEffect(() => {
        fetch(apiUrl + `/api/admin/operador/obtenerTasa`, {
          method: "GET", 
          headers: {
            'Content-Type': 'application/json',
            'auth': Cookies.get('authToken'),
            'datosUsuario': Cookies.get('datosUsuario')
              
          },
              
       })
        .then((res) => res.json())
        .then((data) => setData(data));
        
      }, []);
    

    const enviar = async () => {
        
        if(data !== ''){
            const datos = {
                metodo:  "POST",
                peticion: `admin/operador/cambiarTasa`,
                idElemento: 'boton-guardar-cambios',
                datosReq: {
                        tasa: data
                }
        }

        await fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
        }else {
            setResultado({
                success: false,
                message: 'Debe llenar el campo'
            });

            setModalResultado(true);
        }
    }

    return(
        props.opcionSeleccionada === "Tasa" ?
            data !== '' ? (
                <div className="contenedor-inicio">
                    <div className="pagos-pendientes">
                        <h1>Tasa BCV</h1>
                        <br/>

                        <label htmlFor="exampleFormControlInput1" className="form-label" >Tasa del día</label>
                        <input type="numeric" className="form-control" id="exampleFormControlInput1" placeholder='1052.24' value={data} onChange={(event) => setData((event.target.value).replace(/[^0-9.]/g, ''))} />

                        <button className="btn btn-success boton-guardar-cambios" id='boton-guardar-cambios' onClick={() => enviar()}>Guardar Cambios</button>
                        
                    </div>
                    <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => navigate('/admin/inicio')} data={resultado}/>
                </div>
            )

            : 
            
            <div>
                
                <CirculoGirador />
            </div>
        :

        null
    );
}

export default OpcionTasa;