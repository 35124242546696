import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  width: 300px;
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ProductName = styled.h2`
  font-size: 1.5em;
  margin: 0 0 8px 0;
`;

const Description = styled.p`
  font-size: 1em;

`;

const Price = styled.p`
  font-size: 3em;
  color: #007bff;
  margin: 0 0 8px 0;
`;

const Simbol = styled.p`
  font-size: 0.5em;
  color: #007bff;
  margin: 0 0 8px 5px;
  display: inline;
`;

const Divider = styled.hr`
  border: 0;
  border-top: 1px solid blue;
  margin: 16px 0;
`;

const Features = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 8px 0;
`;

const CheckIcon = styled.svg`
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  fill: green;
  margin-right: 8px;
`;

const FeatureText = styled.span`
  word-break: break-word;
  flex: 1;
`;


const ProductCard = ({ name, description, price, features }) => {
  return (
    <Card>
      <ProductName>{name}</ProductName>
      <Description>{description}</Description>
      <Price>{`${price}`}<Simbol>$/mes</Simbol></Price>
      <p>Prueba gratis de un (1) mes</p>
      <Divider />
      <Features>
        {features.map((feature, index) => (
          <FeatureItem key={index}>
            <CheckIcon xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
            </CheckIcon>
            <FeatureText>{feature}</FeatureText>
          </FeatureItem>
        ))}
      </Features>
    </Card>
  );
};

export default ProductCard;
