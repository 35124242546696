import PaginaReservacion from './componentes/paginaReservacion';
import PaginaInicio from './componentes/paginaInicio';
import PaginaLogin from './componentes/paginaLogin';
import BarraNavegacion from './componentes/barraNavegacion';
import FooterPagina from './componentes/footerPagina';
import PaginaBusqueda from './componentes/paginaBusqueda';
import PaginaCambiarContrasena from './componentes/paginaCambiarContrasena';
import PaginaConfiguracionMedicos from './componentes/paginaConfiguracionMedicos';
import PaginaConfiguracionPacientes from './componentes/paginaConfiguracionPacientes';
import PaginaRegistroMedico from './componentes/paginaRegistroMedico';
import PaginaRegistroPacientes from './componentes/paginaRegistroPacientes';
import PaginaAccesoDenegado from './componentes/paginaAccesoDenegado';
import PaginaNoEncontrada from './componentes/paginaNoEncontrada';
import RutaPrivada from './componentes/privateRoute';
import { BrowserRouter, Route, Routes, useLocation, Navigate} from 'react-router-dom';
import { useState, useLayoutEffect } from 'react';
import Cookies from 'js-cookie';
import AuthContext from './componentes/authContext';
import './App.css';
import PaginaAdmDeCuenta from './componentes/paginaAdmDeCuenta';
import PaginaRegistrarPago from './componentes/paginaRegistrarPago';
import RutaPrivadaAdmin from './componentes/administradores/privateAdmin';
import PaginaInicioAdm from './componentes/administradores/paginaInicioAdm';
import PaginaLoginAdmin from './componentes/administradores/paginaLoginAdmin';
import PaginaAtencionAlCliente from './componentes/paginaAtencionAlCliente';
import PaginaProximasCitasPaciente from './componentes/paginaProximasCitasPaciente';
import Nosotros from './componentes/nosotros';
import Contacto from './componentes/contacto';
import PaginaRestablecerContrasena from './componentes/paginaRestablecerContrasena';
import PaginaGM_infoMedico from './componentes/paginaGM_infoMedico';
import PaginaGM from './componentes/paginaGM';
import PaginaServicios from './componentes/paginaServicios';
import LegalDocuments from './componentes/politicas';

function App () {

    const [datosUsuarios, setDatosUsuario] = useState({

            id: '',
            rol: ''
    });

    if(Cookies.get('datosUsuario') === undefined){
        Cookies.set('datosUsuario', JSON.stringify({
            id: '',
            rol: ''
        }), {
            httpOnly: false,
            sameSite: 'strict',
            secure: true,
        });
      }

      var datosUsuario = JSON.parse(Cookies.get('datosUsuario'));
    
    
    //console.log(datosUsuario.token)


    const Wrapper = ({ children }) => {
        const location = useLocation();
        useLayoutEffect(() => {
          document.documentElement.scrollTo(0, 0);
        }, [location.pathname]);
        return children;
    };

    return (
        <AuthContext.Provider value={{datosUsuario, setDatosUsuario}}>
            <BrowserRouter>
            
            <Wrapper>
            <BarraNavegacion />
                <Routes>
                    <Route path='/' exact Component={PaginaInicio} />
                    <Route path='/login/pacientes/:idMedicoReservar' Component={(props) => <PaginaLogin rol='Pacientes'/>} />  
                    <Route path='/login/medicos' Component={(props) => <PaginaLogin rol='Médicos'/>}/>
                    <Route path='/reservar/:idMedico' Component={PaginaReservacion}/>
                    <Route path='/guia-medica' Component={PaginaGM}/>
                    <Route path='/guia-medica/infoMedico/:idMedico' Component={PaginaGM_infoMedico}/>
                    <Route path='/search/:busqueda' Component={PaginaBusqueda}/>
                    <Route path='/configuracion/medicos' Component={(props) => <RutaPrivada Componente={PaginaConfiguracionMedicos}/>}/>
                    <Route path='/configuracion/pacientes' Component={(props) => <RutaPrivada Componente={PaginaConfiguracionPacientes}/>}/>
                    <Route path='/registro/medicos' Component={PaginaRegistroMedico}/>
                    <Route path='/registro/pacientes' Component={PaginaRegistroPacientes}/>
                    <Route path='/cambiarcontrasena' Component={(props) => <RutaPrivada Componente={PaginaCambiarContrasena}/>} />
                    <Route path='/restablecer-contrasena/:token' Component={PaginaRestablecerContrasena}/>
                    <Route path='/admdecuenta' Component={(props) => <RutaPrivada Componente={PaginaAdmDeCuenta}/>} />
                    <Route path='/admdecuenta/registrarpago' Component={(props) => <RutaPrivada Componente={PaginaRegistrarPago}/>} />
                    <Route path='/proximascitas' Component={(props) => <RutaPrivada Componente={PaginaProximasCitasPaciente}/>} />
                    <Route path='/atencionalcliente' Component={(props) => <RutaPrivada Componente={PaginaAtencionAlCliente}/>} />
                    <Route path='/accesodenegado' Component={PaginaAccesoDenegado}/>
                    <Route path='/admin/inicio' Component={(props) => <RutaPrivadaAdmin Componente={PaginaInicioAdm}/>} />
                    <Route path='/admin/login' Component={PaginaLoginAdmin}/>
                    <Route path='/nosotros' Component={Nosotros}/>
                    <Route path='/contacto' Component={Contacto}/>
                    <Route path='/servicios' Component={PaginaServicios}/>
                    <Route path='/terminos-y-condiciones' Component={LegalDocuments}/>
                    <Route path='/*' Component={PaginaNoEncontrada}/>    
                </Routes>
                <FooterPagina />
            </Wrapper>
            </BrowserRouter>
        </AuthContext.Provider>
    );
}

export default App;