import React, { useContext } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../estilos/paginaLogin.css';
import { cambiarTitulo, validarEmail } from '../../funcionesFront';
import BotonVisibilidadContrasena from '../botonVisibilidadContrasena';
import Cookies from 'js-cookie';
import apiUrl from '../../url';



function PaginaLoginAdmin (props) {

    cambiarTitulo(document, "Iniciar Sesión | Admins");

    const navigate = useNavigate();

    const [checkEmail, setCheckEmail] = useState(false)
    const [formData, setFormData] = useState({  //estado de los valores del login 
        email: '',
        password: ''
    });

    //para indicar que los valores no coinciden
    const [mensajeLoginInvalido, setMensajeLoginInvalido] = useState(''); 
    //para indicar que los valores no coinciden
    const [colorLogin, setColorLogin] = useState('white'); 

    const [passwordIsVisible, setPasswordIsVisible] = useState(false);

    //maneja el cambio en el login para actualizar el estado
    const handleChange = (event) => {     
        const {name, value} = event.target;
        setFormData({...formData, [name]: value, });
        name === 'email' ? validarEmail(event, setCheckEmail) : null;
    };

    const handleSubmit = (event) => {
    
        event.preventDefault();
        const botonSubmit = document.getElementById('boton-iniciar-sesion');
        botonSubmit.setAttribute('disabled', 'disabled'); //desabilitacion temporal del boton para que no le den 2 veces

    
        const url = apiUrl + `/api/admin/login/admins`;
        fetch(url, {
            method: "POST", 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData),
        })

        .then((res) => res.json())
        .then((data) => {


            if(data.success==true){

                setMensajeLoginInvalido('');
                setColorLogin('white');
                Cookies.set('datosUsuario', JSON.stringify(data));
                navigate('/admin/inicio');
                
            }else{

                setMensajeLoginInvalido(data.message);
                setColorLogin('red');
            }
        })

        .catch((error) => {
            console.log(error);
            console.error("Error al enviar los datos");
        })

        
        botonSubmit.removeAttribute('disabled');

    }

    const handleEnter = (event) => {
        event.key === 'Enter' ? handleSubmit : null;
    }


    return (

        <div className='contenedor-login'>
            <form onSubmit={handleSubmit}>
            <br/>
            <br/>
            <div className="mb-3">
                <h1 className='letra-h1'>Inicio de Sesión para Administradores</h1>
                <br/>
                <label htmlFor="exampleFormControlInput1" className="form-label">User</label>
                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="User" style={{border: `1px ${colorLogin} solid`}} name="email" value={formData.email} onChange={handleChange}/>
                <br/>
                <br/>
                <label htmlFor="inputPassword5" className="form-label">Contraseña</label>
                <div className='input-contrasena'>
                    <input type={passwordIsVisible ? "text" : "password"} id="inputPassword5" className="form-control" aria-describedby="passwordHelpBlock" placeholder='Password' style={{border: `1px ${colorLogin} solid`}} name="password" value={formData.password} onChange={handleChange} />
                    <BotonVisibilidadContrasena passwordIsVisible={passwordIsVisible} setPasswordIsVisible={setPasswordIsVisible}/>
                </div>
                <button id='boton-iniciar-sesion' className='btn btn-outline-success' type="submit" onClick={handleSubmit} >Iniciar Sesión</button>
                <br/>
                <br/>
                {mensajeLoginInvalido && <h2 style={{color: 'red'}}>{mensajeLoginInvalido}</h2>}
            </div>
            </form>
        </div>
    );
}

export default PaginaLoginAdmin;