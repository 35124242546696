import React from 'react';
import {Navigate} from 'react-router-dom';
import Cookies from 'js-cookie';



function RutaPrivadaAdmin({Componente}) {

    var datosAdmin;
    try{
    
        datosAdmin = JSON.parse(Cookies.get('datosUsuario'));
        
        
    }catch{
        datosAdmin = {rol: '' };
    }
    return datosAdmin.rol === 'admins'  ? (
         <Componente />
    ) : (
         <Navigate to="/*" />
    );
}

export default RutaPrivadaAdmin;