const estados = {
  
      "Distrito Capital": ["Caracas"],
    
      "Amazonas": ["La Esmeralda", "San Fernando de Atabapo", "Puerto Ayacucho", "Isla Ratón", "San Juan de Manapiare", "Maroa", "San Carlos de Río Negro"],
    
      "Anzoátegui": ["Anaco", "Aragua de Barcelona", "Barcelona", "Clarines", "Onoto", "Valle de Guanape", "Lechería", "Cantaura", "San José de Guanipa", "Guanta", "Soledad", "San Mateo", "McGregor", "Pariaguán", "San Diego de Cabrutica", "Puerto Píritu", "Píritu", "Boca de Uchire", "Santa Ana", "El Tigre", "Puerto La Cruz"],
    
      "Apure": ["Achaguas", "Biruaca", "Bruzual", "Guasdualito", "San Juan de Payara", "Elorza", "San Fernando de Apure"],
    
      "Aragua": ["San Mateo", "Camatagua", "Santa Rita", "Maracay", "Santa Cruz", "La Victoria", "El Consejo", "Palo Negro", "El Limón", "Ocumare de la Costa de Oro", "San Casimiro", "San Sebastián de los Reyes", "Turmero", "Las Tejerías", "Cagua", "Colonia Tovar", "Barbacoas", "Villa de Cura"],
    
      "Barinas": ["Sabaneta", "El Cantón", "Socopó", "Arismendi", "Barinas", "Barinitas", "Barrancas", "Santa Bárbara", "Obispos", "Ciudad Bolivia", "Libertad", "Ciudad de Nutrias"],
    
      "Bolívar": ["Ciudad Guayana", "Caicara del Orinoco", "El Callao", "Santa Elena de Uairén", "Ciudad Bolívar", "Upata", "Ciudad Piar", "Guasipati", "Tumeremo", "Sifontes", "Maripa", "El Palmar"],
    
      "Carabobo": ["Bejuma", "Güigüe", "Mariara", "Guacara", "Morón", "Tocuyito", "Los Guayos", "Miranda", "Montalbán", "Naguanagua", "Puerto Cabello", "San Diego", "San Joaquín", "Valencia"],
    
      "Cojedes": ["Anzoátegui", "Tinaquillo", "El Baúl", "Macapo", "El Pao", "Libertad", "Rómulo Gallegos"],
    
      "Delta Amacuro": ["Curiapo", "Sierra Imataca", "Pedernales", "Tucupita"],
    
      "Falcón": ["San Juan de los Cayos", "San Luis", "Capatárida", "Yaracal", "Punto Fijo", "La Vela de Coro", "Dabajuro", "Pedregal", "Pueblo Nuevo", "Churuguara", "Jacura", "Santa Cruz de Los Taques", "Mene de Mauroa", "Santa Ana de Coro", "Chichiriviche", "Palmasola", "Cabure", "Píritu", "Mirimire", "Tucacas", "La Cruz de Taratara", "Tocópero", "Santa Cruz de Bucaral", "Urumaco", "Puerto Cumarebo"],
    
      "Guárico": ["Camaguan", "Chaguaramas", "El Socorro", "Calabozo", "Tucupido", "Altagracia de Orituco", "San Juan de Los Morros", "El Sombrero", "Las Mercedes", "Valle de La Pascua", "Pedro Zaraza", "Ortíz", "San Gerónimo de Guayabal", "San José de Guaribe", "Santa María de Ipire"],
    
        "Lara": ["Sanare", "Duaca", "Barquisimeto", "Quibor", "El Tocuyo", "Cabudare", "Sarare", "Carora", "Siquisique"],
      
        "Mérida": ["Mérida", "Bailadores", "El Vigía", "Ejido", "La Azulita", "Santa Cruz de Mora", "Lagunillas", "Tovar", "Mucuchíes",  "Nueva Bolivia", "Zea", ],
      
        "Miranda": ["Caucagua", "San José de Barlovento", "Baruta", "Higuerote", "Mamporal", "Carrizal", "Chacao", "Charallave", "El Hatillo", "Los Teques", "Santa Teresa del Tuy", "Ocumare del Tuy", "San Antonio de los Altos", "Río Chico", "Santa Lucía", "Cúpira", "Guarenas", "San Francisco de Yare", "Petare", "Cúa", "Guatire"],
      
        "Monagas": ["San Antonio de Capayacuar", "Aguasay", "Caripe", "Caicara", "Punta de Mata", "Temblador", "Maturín", "Piar", "Quiriquire", "Santa Bárbara", "Barrancas del Orinco", "Uracoa"],
      
        "Nueva Esparta": ["La Plaza de Paraguachí", "La Asunción", "San Juan Bautista", "El Valle del Espíritu Santo", "Santa Ana", "Pampatar", "Juan Griego", "Porlamar", "Boca de Río", "Punta de Piedras", "San Pedro de Coche"],
      
        "Portuguesa": ["Agua Blanca", "Araure", "Píritu", "Guanare", "Guanarito", "Chabasquén de Unda", "Ospino", "Acarigua", "Papelón", "San Genaro de Boconoíto", "San Rafael de Onoto", "Santa Rosalía", "Biscucuy", "Villa Bruzual"],
      
        "Sucre": ["Casanay", "San José de Aerocuar", "Río Caribe", "El Pilar", "Carúpano", "Marigüitar", "Yaguaraparo", "Araya", "Tunapuy", "Irapa", "San Antonio del Golfo", "Montes", "Cariaco", "Cumaná", "Güiria"],
      
        "Táchira": ["Cordero", "Las Mesas", "El Colón", "San Antonio del Táchira", "Táriba", "Santa Ana de Táchira", "San Rafael del Piñal", "San José de Bolívar", "La Fría", "Palmira", "Capacho Nuevo", "La Grita", "El Cobre", "Rubio", "Umuquena", "Capacho Viejo", "Abejales", "Lobatera", "Michelena", "Coloncito", "Ureña", "Delicias", "La Tendida", "San Cristóbal", "Seboruco", "San Simón", "Queniquea", "San Josesito", "Pregonero"],
      
        "Trujillo": ["Santa Isabel", "Boconó", "Sabana Grande", "Chejendé", "Carache", "Escuque", "El Paradero", "Campo Elías", "Santa Apolonia", "El Dividive", "Pampán", "Trujillo", "San Lazaro", "Pampanito"],
      
        "La Guaira": ["La Guaira"],
      
        "Yaracuy": ["San Pablo", "San Felipe", "Aroa", "Chivacoa", "Cocorote", "Independencia", "Yumare", "Los Horcones", "La Gotera"],
      
        "Zulia": ["El Toro", "San Timoteo", "Cabimas", "Encontrados", "San Carlos del Zulia", "Pueblo Nuevo-El Chivo", "La Concepción", "Casigua El Cubo"],
      
}

module.exports = {estados};