//import { da } from 'date-fns/locale';
import moment from 'moment';
import 'moment/locale/es';
import { useEffect, useContext } from 'react';
import IconoCheck from './iconos/iconoCheck';
import IconoError from './iconos/iconoError';
import IconoRelojArena from './iconos/iconoRelojArena';
import Cookies from 'js-cookie';
import imageCompression from 'browser-image-compression';
import { saveAs } from 'file-saver';
import apiUrl from './url.js';
import { estados } from './venezuela.js';
import sinFoto from './imagenes/sinfoto.jpg';

moment.locale('es');

// Función para verificar si un horario está dentro del intervalo de descanso
function estaEnDescanso(horarios, intervaloDescanso) {
    const horaInicioDescanso = moment(`2023-07-31 ${intervaloDescanso.split("-")[0]}`, "YYYY-MM-DD hh:mm A");
    const horaFinDescanso = moment(`2023-07-31 ${intervaloDescanso.split("-")[1]}`, "YYYY-MM-DD hh:mm A");
 
    const horariosFiltrados = horarios.filter((horario) => {
         const [inicio, fin] = horario.split('-').map(h => moment(`2023-07-31 ${h}`, "YYYY-MM-DD hh:mm A"));
         return !(inicio.isBefore(horaFinDescanso) && fin.isAfter(horaInicioDescanso));
    });
 
    return horariosFiltrados;
}

function obtenerDatosUsuario (){
  var datosUsuario;
  try {

    datosUsuario = JSON.parse(Cookies.get('datosUsuario'));
    
  } catch (error) {
      Cookies.set('datosUsuario', JSON.stringify({id: '', rol: ''}));
      datosUsuario = {
          id: '',
          rol: ''
      };
  } finally {
    return datosUsuario
  }

  
}

function determinarCitas (data) {      //determinar los horarios del dia de las citas

   
    let inicio = data.horarioNormal.split('-')[0];
    let fin = data.horarioNormal.split('-')[1];
  
    //let horaInicio = new Date(`2023-07-31 ${inicio}`); 
    //let horaFin = new Date(`2023-07-31 ${fin}`);
    let horaInicio = moment(`2023-07-31 ${inicio}`).toDate(); 
    let horaFin = moment(`2023-07-31 ${fin}`).toDate();
    //let duracionEnFecha = new Date(`2023-07-31 ${data.duracionCita}`); //poner la duracion en formato de fecha
    let duracionEnFecha = moment(`2023-07-31 ${data.duracionCita}`).toDate();
    const horasDeTrabajo = (horaFin - horaInicio)/1000/3600;
    
    var numeroDeCitasDiario = horasDeTrabajo/(duracionEnFecha.getHours() + (duracionEnFecha.getMinutes()/60));
  
    var citas = [];
    citas.push(horaInicio); 
    
    let i, a;
    var citasDef = [];
    for(i=0;i<numeroDeCitasDiario;i++){
      //a = new Date(citas[i]);
      a = moment(citas[i]).toDate();
      a.setHours(a.getHours() + duracionEnFecha.getHours());
      a.setMinutes(a.getMinutes() + duracionEnFecha.getMinutes());
      citas.push(a);
      
    }
  
    citas.forEach(cita => {
      citasDef.push(moment(cita).format("hh:mm A"));
    });
  
    var horariosCitas = []
    for(i=0;i<citasDef.length-1;i++){
      horariosCitas.push(citasDef[i] + '-' + citasDef[i+1]);
    }
    
    if(data.descansoDiario){
        horariosCitas = estaEnDescanso(horariosCitas, data.descansoDiario);
    }


    
    return horariosCitas;
}

function determinarDias (proyeccionCitas) {    //obtener las fechas a partir de la proyeccion suministrada por el medico
    const today = new Date();
    // Obtener las fechas de los 4 días posteriores
    const nextDates = [];
    var diaAux;
    for (let i = 1; i <= proyeccionCitas; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      diaAux = moment(nextDate).format('YYYY-MM-DD') //.format("dddd, D \\d\\e MMMM \\d\\e YYYY").replace(/^\w/, (c) => c.toUpperCase())
      nextDates.push(diaAux);
    }

    diaAux = moment(today).format('YYYY-MM-DD'); //.format("dddd, D \\d\\e MMMM \\d\\e YYYY").replace(/^\w/, (c) => c.toUpperCase())
    nextDates.unshift(diaAux);

    return nextDates;
}

const cambiarTitulo = (document, titulo) => {

    useEffect(() => {
      document.title = titulo;
})
}

const handleSubmit = async (datos) => {
  
    const botonSubmit = document.getElementById(datos.idElemento);
    //botonSubmit.setAttribute('disabled', 'disabled');

    const url = apiUrl + `/api/${datos.peticion}`;
    try {
        const response = await fetch(url, {
            method: datos.metodo, 
            headers: {
                'Content-Type': 'application/json',
                'datosUsuario': Cookies.get('datosUsuario')
            },
            body: JSON.stringify(datos.datosReq),
  
        });

        const data = await response.json();
        botonSubmit.removeAttribute('disabled');
        if(data.success === false && data.message === 'tokenExp'){
            window.location.href = `/`
        }
        return data;
    } catch (error) {
        botonSubmit.removeAttribute('disabled');
        console.error("Error al enviar los datos");
        throw error;
    }
}

async function fetchData(datos, resultados, ventana) {
  try {
      
      ventana(); 
      const data = await handleSubmit(datos);
      resultados(data);   //cambia el resultado del componente que maneka
             //abre la ventana de resultado
      
  } catch (error) {
      console.error('Error al obtener respuesta:', error);
  }
}

async function cambiarFoto (tipo, rol, id, imgAEnviar){

  
    fetch(apiUrl + `/api/${tipo}/cambiarFoto/${rol}/${id}`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'image/jpg',
          'auth': Cookies.get('authToken'),
          'datosUsuario': Cookies.get('datosUsuario')
      },
      body: await convertToJPG(imgAEnviar)
  })
}

function obtenerImagen (rol, id, setImg) {
  fetch(apiUrl + `/api/datosPaginaReservacion/obtenerImagen/${rol}/${id + '.jpg'}`, {
    method: "GET", 
    headers: {
        'Content-Type': 'application/json'
    },
        
  })

  .then((res) => res.blob())
  .then((blob) => {

    setImg(blob.type === 'image/jpeg' ? URL.createObjectURL(blob) : sinFoto);
  
  })
      
  
}

function calcularEdad(fechaNacimiento) {
  const fechaActual = new Date();
  const fechaNac = new Date(fechaNacimiento);
  
  let edad = fechaActual.getFullYear() - fechaNac.getFullYear();

  const mesActual = fechaActual.getMonth();
  const mesNac = fechaNac.getMonth();

  if (mesNac > mesActual || (mesNac === mesActual && fechaNac.getDate() > fechaActual.getDate())) {
    edad--;
  }

  return edad;
}

const validarEmail = (event, setCheckEmail) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  setCheckEmail(regex.test(event.target.value));
}

const fechaHoy = () => {
  return(moment(Date()).format('DD/MM/YYYY'));
}

const fechaHoyConGuion = () => {
  return(moment(Date()).format('YYYY-MM-DD'));
}

function haPasadoLaFecha(fechaDePago) {
  // Obtener la fecha actual en UTC
  const fechaActual = new Date();
  const anioActual = fechaActual.getUTCFullYear();
  const mesActual = fechaActual.getUTCMonth();
  const diaActual = fechaActual.getUTCDate();

  // Obtener la fecha de pago en UTC
  const [anioPago, mesPago, diaPago] = fechaDePago.split('-');
  const fechaPagoUTC = Date.UTC(anioPago, mesPago - 1, diaPago); // mesPago - 1 porque los meses en JavaScript van de 0 a 11

  // Obtener la fecha actual en UTC
  const fechaActualUTC = Date.UTC(anioActual, mesActual, diaActual);

  // Comparar las fechas (sin tener en cuenta las horas, minutos, segundos y milisegundos)
  return fechaPagoUTC > fechaActualUTC;
}

const formatearFecha = (fecha) => {
  return(moment(fecha).format('DD/MM/YYYY'));
}

function convertirNumero(numero) {
  // Convertir el número a una cadena y dividirlo en parte entera y parte decimal
  const partes = numero.toString().split('.');
  const parteEntera = partes[0];
  const parteDecimal = partes.length > 1 ? partes[1] : '00';

  // Agregar separadores de miles y coma decimal
  const parteEnteraConSeparadores = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const numeroFormateado = `${parteEnteraConSeparadores},${parteDecimal}`;

  return numeroFormateado;
}

function revertirFormatoNumero(numeroFormateado) {
  // Eliminar los separadores de miles y cambiar el separador decimal a un punto
  const numeroSinSeparadores = numeroFormateado.replace(/\./g, '').replace(',', '.');

  // Convertir el número a punto flotante, teniendo en cuenta decimales con ceros a la derecha
  const numero = parseFloat(numeroSinSeparadores);

  // Convertir el número a cadena y luego nuevamente a número para asegurarse de que se manejen los decimales con ceros a la derecha
  return Number(numero.toFixed(2));
}

const formatoDatosTablaPagos = (dato, index, indice, admin) => {
  const estilo = {textAlign: 'center'};

  if(index === 0){
    return (formatearFecha(dato))
  }else if(index === 1){
    return (convertirNumero(dato));
  }else if (index === 2) {
    return ('#' + dato.slice(-4));
  }else if(index === 3){
    return (dato);
  }else{
    if(admin.admin === false){
      if(dato === 1){
        return(<p style={estilo}>Verificado <IconoCheck tamano={35}/></p>);
      }else if (dato === 0) { 
      return(<p style={estilo}>En espera <IconoRelojArena tamano={35}/></p>);
    }else if (dato === 2){ 
      return(<p style={estilo}>Rechazado <IconoError tamano={35}/></p>);
      }
    }
}
}

const verDetalles = (setModalOpen, setDetalles, dato) => {
  setDetalles(dato);
  setModalOpen(true);
}

const formatoDatosTablaAtencion = (dato, index, setModalOpen, setDetalles) => {
  if(index === 0){
    return (formatearFecha(dato))
  }else if(index === 1){
    return (dato);
  }else if (index === 4){
    return(<button onClick={() => verDetalles(setModalOpen, setDetalles, dato)}>Ver Detalles</button>)
  }else {
    return(dato);
  }
}

function solicitarDatos (api, metodo, datos, setData){
  fetch(api, {
    method: metodo, 
    headers: {
        'Content-Type': 'application/json',
        datos: datos,
    },
        
 })
  .then((res) => res.json())
  .then((data) => setData(data));
}

function ocultarElemento (nombre) {
  var elemento = document.getElementsById(nombre);
  elemento.style.display = 'none';
}

async function convertToJPG(file) {
    try {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: 'image/jpg', // Establece el tipo de archivo de salida como JPEG
        };

        const compressedImage = await imageCompression(file, options);

        return compressedImage;

        
        
        // Opcional: Guardar el archivo convertido localmente

        // Aquí puede implementar lógica adicional, como mostrar la imagen en la interfaz de usuario
        // o enviarla a un servidor.
        
    } catch (error) {
        console.error(error);
    }
}

const sortWeekdays = (diasDeTrabajoChecked) => {
  // Define el orden deseado de los días de la semana
  const desiredOrder = ["lu", "ma", "mi", "ju", "vi", "sá", "do"];

  // Ordena los días de la semana según el orden definido
  const sortedWeekdays = diasDeTrabajoChecked.sort((a, b) =>
    desiredOrder.indexOf(a) - desiredOrder.indexOf(b)
  );

  // Actualiza el estado con los días de la semana ordenados
  return sortedWeekdays;
};

function convertirTiempo(valor) {
  // Verificar si el valor es un número en minutos
  if (typeof valor === 'number') {
    const horas = Math.floor(valor / 60);
    const minutos = valor % 60;
    return `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;
  }
  // Verificar si el valor es un formato de tiempo (HH:MM)
  else if (typeof valor === 'string' && /^[0-9]{2}:[0-9]{2}$/.test(valor)) {
    const partes = valor.split(':');
    const horas = parseInt(partes[0]);
    const minutos = parseInt(partes[1]);
    return horas * 60 + minutos;
  }
  // En caso de que el valor no sea válido, devolver null
  return null;
}

function capitalizarPalabras(str) {
  // Dividir el string en palabras utilizando espacio como separador
  return str.split(' ').map(palabra => {
    // Verifica que la palabra no esté vacía para evitar errores
    if (palabra.length > 0) {
      // Convierte la primera letra a mayúscula y el resto a minúsculas
      return palabra[0].toUpperCase() + palabra.substring(1).toLowerCase();
    } else {
      return palabra;
    }
  }).join(' '); // Une las palabras transformadas con un espacio
}

function eliminarAcentos(str) {
    // Normalizar el string y luego reemplazar los caracteres acentuados por su equivalente sin acento
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  

function buscarEstadoAcento (estado) {

	let estadoAcentuado = '';
	
	for(let i=0;i<Object.keys(estados).length;i++){
		if(eliminarAcentos(Object.keys(estados)[i]) == estado){
			estadoAcentuado = Object.keys(estados)[i];
			break;
		} 
	}

	return estadoAcentuado;

	
}

function buscarCiudadAcento (estado, ciudad) {
	
	let ciudadAcentuada = ''
	const estadoAcentuado = buscarEstadoAcento(estado);

	
	for(let i=0;i<estados[estadoAcentuado].length;i++){
		if(eliminarAcentos(estados[estadoAcentuado][i]) == ciudad) {
			ciudadAcentuada = estados[estadoAcentuado][i];
			break;
		}
	}

	return ciudadAcentuada;


	
}

function isNumeric(str) {
  const num = parseFloat(str);
  return Number.isInteger(num) && num.toString() === str;
}


const verificarCedula = (event, cedula) => {  //cedula es el valor anterior
  const { value } = event.target;

  // Primero, convertimos a mayúsculas para estandarizar las entradas.
  const formattedValue = value.toUpperCase();
  
  if(value.length < cedula.length){
      return value
  }
  // Verificar y formatear automáticamente el prefijo si es necesario.
  if (formattedValue.match(/^(V|E)$/)) {
      // Si se ingresa solo 'V' o 'E', añadir automáticamente el guión.
      return `${formattedValue}-`;
  } else if (formattedValue.match(/^(V-|E-)\d*$/)) {
      // Aceptar 'V-' o 'E-' seguido de cualquier número de dígitos.
      return formattedValue;
  } else if (formattedValue.match(/^\d+$/)) {
      // Si se ingresan solo números, añadir automáticamente "V-".
      return `V-${formattedValue}`;
  }

  // En cualquier otro caso, devolvemos el valor que cumpla con la condición 'V-' o 'E-' seguido de números,
  // o simplemente 'V-' o 'E-' si no se han añadido números aún.
  // Si el valor no cumple las condiciones anteriores, se devuelve un string vacío o se maneja de alguna manera.
  return cedula;
};

function isDateLessOrEqual(date1, date2) {

  if(date1 == ''){
    return true
  }
  // Convertir las cadenas de fechas a objetos Date
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Comparar las fechas
  return d1 > d2;
}

export {  determinarCitas, determinarDias, obtenerDatosUsuario,
          cambiarTitulo, handleSubmit, 
          calcularEdad, fetchData, cambiarFoto, 
          obtenerImagen, validarEmail, formatearFecha, 
          formatoDatosTablaPagos, fechaHoy, haPasadoLaFecha, 
          convertirNumero, revertirFormatoNumero, 
          solicitarDatos, formatoDatosTablaAtencion, ocultarElemento, sortWeekdays,
          convertirTiempo, capitalizarPalabras, buscarEstadoAcento, buscarCiudadAcento,
          isNumeric, verificarCedula, fechaHoyConGuion, isDateLessOrEqual
        
        };


