import { convertirNumero } from "../funcionesFront";

function TotalDelServicio({ servicios, serviciosChecked }) {

    var total = 0;
    serviciosChecked.forEach(element => {
        servicios.forEach(servicio => {
            element == servicio.id ? total+= servicio.costo : null;
        })
        
    });

    const textoAdicional = 'En CitasFácil, el primer mes de servicio, ¡Te sale gratis!'

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f0f4f8',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
      }}>
        <div style={{
          fontSize: '24px',
          color: '#333',
          marginBottom: '10px',
        }}>
          Total: <span style={{ fontWeight: 'bold' }}>USD {convertirNumero(total)}/mes</span>
        </div>
        <div style={{
          fontSize: '16px',
          color: '#666',
          textAlign: 'center',
        }}>
          {textoAdicional}
        </div>
      </div>
    );
  }

  
  export default TotalDelServicio;