import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../estilos/buscadorPaginaGM.css'

function BuscadorMedico() {

  const [nombre, setNombre] = useState('');
  const [especialidad, setEspecialidad] = useState('');
  const [localizacion, setLocalizacion] = useState('');

  const navigate = useNavigate();

  const manejarBusqueda = () => {
    const cadenaBusqueda = `${nombre} ${especialidad} ${localizacion}`.trim();
    navigate(`/search/${cadenaBusqueda}`);; // Luego puedes reemplazar esto con la lógica de búsqueda real.
  };

  // Estilos en línea
  const estilos = {
    buscadorContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      marginBottom: '20px',
      padding: '20px',
      borderRadius: '8px',
      background: 'linear-gradient(135deg, #007bff, #00d4ff)', // Gradiente de azul a cian
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    },
    input: {
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ddd',
      width: '200px', // Ajusta según la necesidad
      backgroundColor: 'white', // Fondo blanco para los inputs para mayor legibilidad
    },
    botonBuscar: {
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      backgroundColor: '#007bff', // Azul que combina con el gradiente
      color: 'white',
    }
  };
  

  return (
    <div className="buscador-container">
            <input
                type="text"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                placeholder="Nombre"
                className="input-buscador"
            />
            <input
                type="text"
                value={especialidad}
                onChange={(e) => setEspecialidad(e.target.value)}
                placeholder="Especialidad"
                className="input-buscador"
            />
            <input
                type="text"
                value={localizacion}
                onChange={(e) => setLocalizacion(e.target.value)}
                placeholder="Ciudad"
                className="input-buscador"
            />
            <button onClick={manejarBusqueda} className="boton-buscar">
                Buscar
            </button>
        </div>
  );
}

export default BuscadorMedico;
