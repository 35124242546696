import React from 'react';
import {Navigate} from 'react-router-dom';
import Cookies from 'js-cookie';



function RutaPrivada({Componente}) {

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));
    return datosUsuario.id !== '' ? (
         <Componente />
    ) : (
        <Navigate to="/accesodenegado" />
    );
}


export default RutaPrivada;
