import '../estilos/paginaLogin.css';
import { useEffect, useState } from 'react';
import CrearContrasena from './crearContrasena';
import { fetchData } from '../funcionesFront';
import VentanaResultado from './ventanaResultado';
import { cambiarTitulo } from '../funcionesFront';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


function PaginaCambiarContrasena () {

  cambiarTitulo(document, "Cambio de contraseña");

    const navigate = useNavigate();

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

    const [oldPassword, setOldPassword] = useState('');  //estado de los valores del login 
        
    const [checkContrasenaNueva, setCheckContrasenaNueva] = useState(false);
    //para indicar que los valores no coinciden
    const [mensajeLoginInvalido, setMensajeLoginInvalido] = useState(''); 
    const [colorLogin, setColorLogin] = useState('white'); 

    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: ''
    });

    const [checkEnviar, setCheckEnviar] = useState(false);

    const [modalAvisoIsOpen, setModalAvisoIsOpen] = useState(false);

    const [resultados, setResultados] = useState('');

    const handleOldPasswordChange = (event) => {
        const {value} = event.target;

        setFormData(prevState => ({
            ...prevState,
            oldPassword: value
        }));
    };

    const handleMessage = (message) => {  //recibe la contrasena nueva


        if(message!==''){

            if(formData.newPassword !== message.newPassword){
                
                setFormData(prevState => ({
                    ...prevState, 
                    newPassword: message.newPassword
                }));

            }

            setCheckContrasenaNueva(true);   //para determinar si la contrasena nueva cumple y esta lista para procesarla
        
        } else {
            setCheckContrasenaNueva(false);
        }

    } 

    const enviar = (event) => {

        event.preventDefault();
        if(formData.oldPassword !== formData.newPassword){
          setCheckEnviar(!checkEnviar);
          setColorLogin('white');
          setMensajeLoginInvalido('');
        }else {
            setResultados({success: false, message: 'Las contraseñas son iguales'});
            setModalAvisoIsOpen(true);
        }
    }

    useEffect(() => {
        if (checkContrasenaNueva) {
          const datos = {
            metodo: "PUT",
            idElemento: 'boton-cambiar-contrasena',
            peticion: 'config/cambiarcontrasena',
            datosReq: {
              contrasenas: formData,
              datosUsuario: datosUsuario
            }
          };
          fetchData(datos, (resultado) => setResultados(resultado), () => setModalAvisoIsOpen(true));
        }else if(checkEnviar){
            setResultados({success: false, message: 'La contraseña no cumple con los requisitos'});
            setModalAvisoIsOpen(true);
        }
      }, [checkEnviar]);

      const acciones = () => {
        setModalAvisoIsOpen(false)
        setResultados('');
    }

    return (

        <div className='contenedor-cambiar-contrasena' >
            
            <br/>
            <br/>
            <div className="mb-3">
                <h1 className='letra-h1'>Cambiar Contraseña</h1>
                
                <label htmlFor="exampleFormControlInput1" className="form-label">Contraseña anterior</label>
                <input  type="password" id="old-password" className="form-control" aria-describedby="passwordHelpBlock" style={{border: `1px ${colorLogin} solid`}} placeholder='Password' name="oldPassword" onChange={handleOldPasswordChange}/>
                
                <CrearContrasena oldPassword={oldPassword} onMessage={handleMessage} colorLogin={colorLogin}/>
                <button id='boton-cambiar-contrasena' className='btn btn-outline-success' type='submit' onClick={enviar} >Cambiar Contraseña</button>
                <br/>
                {mensajeLoginInvalido && <h2 style={{color: 'red'}}>{mensajeLoginInvalido}</h2>}
                <VentanaResultado isOpen={modalAvisoIsOpen} onClose={() => acciones()} cerrarVentanaConfirmacion={() => navigate('/')} data={resultados}/>
            </div>
            
        </div>
    );

}

export default PaginaCambiarContrasena;