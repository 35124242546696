import { useState } from "react";
import { fetchData } from "../funcionesFront";
import VentanaResultado from "./ventanaResultado";
import Cookies from "js-cookie";
import IconoError from "../iconos/iconoError";
import '../estilos/ventanaAvisoUnloged.css'
import CirculoGirador from "../iconos/circuloGirador";


function VentanaIntroducirCodigo (props) {

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

    const [modalResultado, setModalResultado] = useState(false);
    const [resultado, setResultado] = useState('');

    const [codigo, setCodigo] = useState('');

    const handleSubmit = () => {

        if (codigo !== '' && codigo.length === 6) {

            
            const datos = {
                metodo: "PUT",
                peticion: props.peticion,
                idElemento: 'boton-recuperar',
                datosReq: {
                    id: datosUsuario.id,
                    rol: datosUsuario.rol,
                    correo: props.correo,
                    codigo: codigo,

                }
            };

            fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true))
        }else{
            setResultado({
                success: false,
                message: 'Código Invalido'
            });
            setModalResultado(true);
        }
    }

    const acciones = () => {
        setModalResultado(false);
        setResultado('');
    }


    return(
        props.isOpen ? (
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Correo Electrónico</h5>
                        </div>

                        
                        
                        <div className="modal-body">
                            {props.resultado === '' ? <CirculoGirador/> : null}
                            {props.resultado.success === false ? <IconoError tamano={100}/> : null}
                            <label htmlFor="exampleFormControlInput1" className="form-label" >{props.resultado.message}</label>
                            {props.resultado.success === true ? <input type="numeric" className="form-control" id="exampleFormControlInput1" placeholder="123456" name='codigo' value={codigo} onChange={(event) => setCodigo(event.target.value)} /> : null}
                        </div>
                        <div className="modal-footer">
                                   
                            {props.resultado.success === true ? <button type="button" className="btn btn-primary boton-info-liberar" id='boton-recuperar' onClick={() => handleSubmit()}>Enviar</button> : null}

                            <button type="button" className="btn btn-secondary boton-info-cerrar" onClick={props.onClose}>Cancelar</button>

                            <VentanaResultado isOpen={modalResultado} onClose={() => acciones()} cerrarVentanaConfirmacion={() => props.cerrarVentanaConfirmacion()} data={resultado}/>
                            
                        </div>
                    </div>
                </div>
        </div>
        
        ) : null
    );


}

export default VentanaIntroducirCodigo;