import '../estilos/tabla.css';
import { formatearFecha, formatoDatosTablaAtencion, formatoDatosTablaPagos, handleSubmit } from '../funcionesFront';
import IconoCheck from '../iconos/iconoCheck';
import CirculoGirador from '../iconos/circuloGirador';
import VentanaDetalles from './administradores/ventanaDetalles';
import { useState } from 'react';
import Cookies from 'js-cookie';



function Tabla (props) {

    //para el modal de detalles
    const [isModalOpen, setModalOpen] = useState(false);
    const [detalles, setDetalles] = useState('');


    const procesarPago = async (numeroDePago, verificado) => {
        const datos = {
            metodo: "PUT",
            peticion: `admin/operador/procesarPago`,
            idElemento: 'boton-estado',
            datosReq: {
                numeroDePago: numeroDePago,
                verificado: verificado,
                responsable: props.datosUsuario.id
            }
        }
        await handleSubmit(datos);
        props.admin.setData(numeroDePago);  //para actualizar el estado
    }

    const resolverRequerimiento = async (numeroRequerimiento, rol) => {
        const datos = {
            metodo: "PUT",
            peticion: `admin/operador/resolverRequerimiento`,
            idElemento: 'boton-estado',
            datosReq: {
                numeroRequerimiento: numeroRequerimiento,
                responsable: props.datosUsuario.id,
                rol: rol
            }
        }
        await handleSubmit(datos);
        //props.admin.setRefrescar('');
        props.admin.setData(numeroRequerimiento);  //para actualizar el estado
        
    }

    const requerimientos = (arreglo) => {
            return(
                arreglo.map((info, indice) => 
                    <tr id={indice} key={indice}>
                        {Object.values(info).map((element, index) => <td key={index}>{index == 5 && props.admin.admin ? <span><button className='btn btn-success' id='boton-estado' onClick={() => resolverRequerimiento(Object.values(info)[1], Object.values(info)[2])}>Confirmar Resolución</button></span> : formatoDatosTablaAtencion(element, index, setModalOpen, setDetalles)}</td>)}
                    </tr>
                )
                
            );
    }

    

    return(
        props.data ? 
            <table>
                <thead>
                    <tr>
                        {props.cabezas.map(cabeza => <th key={cabeza} style={{textAlign: 'center'}}>{cabeza}</th>)}
                        
                    </tr>
                </thead>
                <tbody>
                    {props.tipo === 'Pagos' ? [...props.data].reverse().slice(0,5).map((info, indice) => 
                        <tr id={indice} key={indice}>
                            {Object.values(info).map((element, index) => <td key={index} style={{textAlign: 'center'}}>{index === 4 && props.admin.admin ? <span><button className='btn btn-success' id='boton-estado' onClick={() => procesarPago(Object.values(info)[5], 1)}>Confirmar</button><button className='btn btn-danger' id='boton-estado' onClick={() => procesarPago(Object.values(info)[5], 2)}>Rechazar</button></span> : formatoDatosTablaPagos(element, index, indice, props.admin)}</td>)}
                        </tr>
                    ) 
                    : 
                    (requerimientos(props.data.medicos.concat(props.data.pacientes)))
                    }
                </tbody>
                <VentanaDetalles isOpen={isModalOpen} onClose={() => setModalOpen(false)} detalles={detalles}/>
            </table>
        :
        props.data === '' ? <CirculoGirador /> : <h4 style={{color: 'black', textAlign: 'center'}}>No existen datos</h4>
    );
}

export default Tabla;