import React, {useState, useContext, useEffect} from "react";
import "../estilos/ventanaConfirmacion.css"
import { useParams} from "react-router-dom";
import VentanaAvisoUnloged from './ventanaAvisoUnloged';
import VentanaResultado from "./ventanaResultado";
import { fetchData } from "../funcionesFront";
import moment from "moment";
import Cookies from "js-cookie";

function Modal (props) {

    const {idMedico} = useParams();

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));
    const medicoTitular = datosUsuario.rol === 'medicos' && datosUsuario.id == idMedico;
    const usuarioAutorizado = datosUsuario.rol === 'pacientes' || datosUsuario.rol === '' || medicoTitular;

    const [modalAvisoIsOpen, setModalAvisoIsOpen] = useState(false); //unloged

    const [resultado, setResultado] = useState('');

    const [modalResultado, setModalResultado] = useState(false);

    const [motivo, setMotivo] = useState('');

    const handleChange = (event) => {

        const {value} = event.target;
        setMotivo(value);
    }

    //si es un 0 el id paciente, es por que es un medico el que esta retirando la cita
    var paciente;
    medicoTitular ? paciente = 0 : paciente = datosUsuario.id;
    const datosConfirmacion = {   //para mandar al server para hacer efectiva la reserva


        idMedico: idMedico,
        ubicacion: props.ubicacion.id,
        idPaciente: paciente,
        fecha: props.datosReserva.fecha,
        hora: props.datosReserva.cita,
        motivo: motivo //solo se pide cuando es el medico que solicita la visita

    };

    const reservar = (data) => {
        
        const datos = {
            metodo: "POST",
            peticion: 'paginaReservacion/reservar/confirmacion',
            idElemento: 'boton-reservar-cita',
            datosReq: data
        };
        fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
        setResultado('');
    }

    return(
        props.isOpen && usuarioAutorizado ?
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title">{medicoTitular ? "Retiro de disponibilidad de cita" : "Confirmación de Cita"}</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
                        </div>
                        
                        <div className="modal-body">
                        {medicoTitular ? 
                        (
                            <div className="retiro-disponibilidad-cita">
                            <h4>Va a retirar la disponibilidad de una cita, desea especificar un motivo?</h4>
                            </div>
                        )
                        : 
                        (
                            <div className="datos-reserva">
                            <h3> {`${props.data.sexo === 'Masculino' ? "Dr. " : "Dra. "}` + props.data.nombres + ' ' + props.data.apellidos}</h3>
                            <h3>Fecha: {moment(props.datosReserva.fecha).format("dddd, D \\d\\e MMMM \\d\\e YYYY").replace(/^\w/, (c) => c.toUpperCase())}</h3>
                            <h3>Hora: {props.datosReserva.cita}</h3>
                            <h3>¿Desea especificar un motivo para la consulta? Es opcional</h3>
                            </div>
                        )
                        }
                        <input type="text" className="form-control" placeholder="Motivo..."  name="motivo" value={motivo} onChange={handleChange}/>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-success" id='boton-reservar-cita' onClick={datosUsuario.id ? () => reservar(datosConfirmacion) : () => setModalAvisoIsOpen(true)}>Confirmar</button>
                            <button className="btn btn-danger" onClick={props.onClose}>Cancelar</button>
                        </div>
                    </div>
                        <VentanaAvisoUnloged isOpen={modalAvisoIsOpen} onClose={() => setModalAvisoIsOpen(false)} data={datosConfirmacion}/>
                        <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => props.onClose()} data={resultado}/>
                </div>
            </div> 
        : (null)
    );

}

  export default Modal;