import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import AuthContext from './authContext';
import IconoLupa from '../iconos/iconoLupa';
import logo from '../imagenes/CFT.png';
import apiUrl from '../url.js';
import '../estilos/barraNavegacion.css';
import VentanaOpcionRegistro from './ventanaOpcionRegistro.js';

function BarraNavegacion () {    
    const {setDatosUsuario} = useContext(AuthContext); 
    var datosUsuario = JSON.parse(Cookies.get('datosUsuario') || '{}');
    const [busqueda, setBusqueda] = useState('');
    const navigate = useNavigate();
    const [shrinkNavbar, setShrinkNavbar] = useState(false);
    const [ventanaOpcionRegistroIsOpen, setVentanaOpcionRegistroIsOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setShrinkNavbar(window.scrollY > 50);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleExit = () => {
        fetch(apiUrl + '/api/login/cerrarSesion', {
            method: "DELETE",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(datosUsuario)
        });
        const datos = {id: '', rol: ''};
        Cookies.set('datosUsuario', JSON.stringify(datos));
        setDatosUsuario(datos);
    }

    const handleChange = (event) => {
        setBusqueda(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(busqueda) {
            navigate(`/search/${busqueda}`);
        }
    }

    return (
        <nav className={`navbar navbar-expand-lg my-navbar ${shrinkNavbar ? 'shrink' : ''}`}>
            <div className="container-fluid">
                <Link to="/">
                    <img src={logo} alt="Logo de la página" className="logo-img" />
                </Link>
                <div className="navbar-collapse">
                    {datosUsuario.id ? (
                        <>
                            <Link to={datosUsuario.rol === 'medicos' ? `/reservar/${datosUsuario.id}` : '/proximascitas'} className="nav-link">Citas</Link>
                            <Link to="/cambiarcontrasena" className="nav-link">Cambiar Contraseña</Link>
                            <Link to={`/configuracion/${datosUsuario.rol}`} className="nav-link">Configuración</Link>
                            {datosUsuario.rol === 'medicos' && <Link to="/admdecuenta" className="nav-link">Adm. de Cuenta</Link>}
                            <Link to="/atencionalcliente" className="nav-link">Atención al Cliente</Link>
                            <button className="nav-link registrarse" onClick={handleExit}>Cerrar Sesión</button>
                        </>
                    ) : (
                        <>
                            <Link to="/nosotros" className="nav-link">Nosotros</Link>
                            <Link to="/contacto" className="nav-link">Contacto</Link>
                            <Link to="/login/medicos" className="nav-link iniciar-sesion">Médicos</Link>
                            <Link to="/login/pacientes/0" className="nav-link iniciar-sesion">Pacientes</Link>
                            <button className="nav-link registrarse" onClick={() => setVentanaOpcionRegistroIsOpen(true)}>Registrarse</button>
                        </>
                    )}
                </div>
                {datosUsuario.rol !== 'medicos' && (
                    <form className="d-flex search-form" role="search">
                        <div className='contenedor-busqueda' style={{textAlign: 'center'}}>
                        <input className="form-control me-2 input-busqueda" type="search" onChange={handleChange} placeholder="Buscar..." aria-label="Search"></input>
                        <button className="btn btn-outline-success boton-buscar" type="submit" onClick={handleSubmit}>
                            <IconoLupa />{"  Buscar"}
                        </button>
                        </div>
                    </form>
                )}
            </div>
            <VentanaOpcionRegistro isOpen={ventanaOpcionRegistroIsOpen} onClose={() => setVentanaOpcionRegistroIsOpen(false)}/>
        </nav>
    );
};

export default BarraNavegacion;
