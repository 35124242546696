import { useEffect, useState } from "react";
import { solicitarDatos } from "../../funcionesFront";
import CirculoGirador from '../../iconos/circuloGirador';
import Cookies from "js-cookie";
import apiUrl from "../../url";

function OpcionInicio (props) {

    const [data, setData] = useState('');

    //useEffect( () => solicitarDatos('/api/admin/operador/datosGenerales', "GET", {}, setData), []);

    useEffect(() => {
        fetch(apiUrl + `/api/admin/operador/datosGenerales`, {
          method: "GET", 
          headers: {
            'Content-Type': 'application/json',
            'auth': Cookies.get('authToken'),
            'datosUsuario': Cookies.get('datosUsuario')
              
          },
              
       })
        .then((res) => res.json())
        .then((data) => setData(data));
        
      }, []);

    return(
        props.opcionSeleccionada === "Inicio" ?
            data !== '' ? (
                <div className="contenedor-inicio">
                    <div className="datos-generales">
                        <h1>Datos Generales</h1>
                        <h3>Medicos Registrados: {data.numeroDeMedicos}</h3>
                        <h3>Medicos Activos: {data.numeroDeMedicosActivos} </h3>
                        <h3>Pacientes Registrados: {data.numeroDePacientes}</h3>
                    </div>
                </div>
            )

            : 
            
            <div>
                
                <CirculoGirador />
            </div>
        :

        null
    );
}

export default OpcionInicio;