import { useState } from "react";
import { fetchData } from "../funcionesFront";
import VentanaResultado from "./ventanaResultado";
import '../estilos/ventanaAvisoUnloged.css'


function VentanaRecuperarContrasena (props) {

    const [modalResultado, setModalResultado] = useState(false);
    const [resultado, setResultado] = useState('');

    const [mensajeLoginInvalido, setMensajeLoginInvalido] = useState('');

    const handleSubmit = () => {

        if (props.checkEmail) {

            setMensajeLoginInvalido('');

            var rol ='';
            if(props.rol!='Pacientes'){  //esto se hace para evitar problemas con las mayusculas y los acentos
                rol = 'medicos';
            }else {
                rol = 'pacientes'
            }
            
            const datos = {
                metodo: "POST",
                peticion: `restablecimientoContrasena/solicitar-restablecimiento/${rol}`,
                idElemento: 'boton-recuperar',
                datosReq: {correo: props.email.email}
            };
            fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true))
        }else{
            setMensajeLoginInvalido('Email inválido');
        }
    }


    return(
        props.isOpen ? (
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Recuperación de Contraseña</h5>
                        </div>
                        
                        <div className="modal-body">
                            <label htmlFor="exampleFormControlInput1" className="form-label" >Correo Electrónico</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={"juan@ejemplo.com"} name='email' value={props.email.email} onChange={props.handleChange} style={null}/>
                            <p style={{color: 'red'}}>{mensajeLoginInvalido}</p>
                        </div>
                        <div className="modal-footer">
                                   
                            <button type="button" className="btn btn-primary boton-info-liberar" id='boton-recuperar' onClick={() => handleSubmit()}>Recuperar Contraseña</button>

                            <button type="button" className="btn btn-secondary boton-info-cerrar" onClick={props.onClose}>Cerrar</button>

                            <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => props.onClose()} data={resultado}/>
                            
                        </div>
                    </div>
                </div>
        </div>
        
        ) : null
    );


}

export default VentanaRecuperarContrasena;