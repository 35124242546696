import React, { useContext } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../estilos/paginaLogin.css';
import { cambiarTitulo, validarEmail } from '../funcionesFront';
import BotonVisibilidadContrasena from './botonVisibilidadContrasena';
import VentanaRecuperarContrasena from './ventanaRecuperarContrasena';
import Cookies from 'js-cookie';
import IconoEmail from '../iconos/iconoEmail';
import IconoLlave from '../iconos/iconoLlave';
import VentanaResultado from './ventanaResultado';
import apiUrl from '../url';


function PaginaLogin (props) {


    cambiarTitulo(document, "Iniciar Sesión");

    const {idMedicoReservar} = useParams(); //para determinar la redireccion, si al inicio o la ventana de reservacion
    var redirection = '/';
    if(props.rol == 'Pacientes'){
        idMedicoReservar == 0 ? (redirection = '/') : (redirection = `/reservar/${idMedicoReservar}`);
    }
    const navigate = useNavigate();

    var datosUsuario;
    try {
        datosUsuario = JSON.parse(Cookies.get('datosUsuario'));
    } catch (error) {
        datosUsuario = {
            id: '',
            rol: ''
        };
    }

    const [checkEmail, setCheckEmail] = useState(false)
    const [formData, setFormData] = useState({  //estado de los valores del login 
        email: '',
        password: ''
    });

    const [resultado, setResultado] =useState('');
    const [modalResultado, setModalResultado] = useState(false);

    //para indicar que los valores no coinciden
    const [mensajeLoginInvalido, setMensajeLoginInvalido] = useState(''); 
    //para indicar que los valores no coinciden
    const [colorLogin, setColorLogin] = useState('white'); 

    const [passwordIsVisible, setPasswordIsVisible] = useState(false);

    const [ventanaRecuperarContrasenaIsOpen, setVentanaRecuperarContrasenaIsOpen] = useState(false);

    //maneja el cambio en el login para actualizar el estado
    const handleChange = (event) => {     
        const {name, value} = event.target;
        setFormData({...formData, [name]: value, });
        name === 'email' ? validarEmail(event, setCheckEmail) : null;
    };

    const handleSubmit = (event) => {
    
        event.preventDefault();
        const botonSubmit = document.getElementById('boton-iniciar-sesion');
        botonSubmit.setAttribute('disabled', 'disabled'); //desabilitacion temporal del boton para que no le den 2 veces

        var rol ='';
        if(props.rol!='Pacientes'){  //esto se hace para evitar problemas con las mayusculas y los acentos
            rol = 'medicos';
        }else {
            rol = 'pacientes';
        }
        
        
        const url = apiUrl + `/api/login/${rol}`;
        fetch(url, {
            method: "POST", 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData),
        })

        .then((res) => res.json())
        .then((data) => {


            if(data.success==true){

                
                setMensajeLoginInvalido('');
                setColorLogin('white');
                Cookies.set('datosUsuario', JSON.stringify(data));
                navigate(redirection);
                
            }else{

                setResultado({success: false, message: data.message});
                setModalResultado(true);
                //setColorLogin('red');
            }
        })

        .catch((error) => {
            console.log(error);
            console.error("Error al enviar los datos");
        })

        
        botonSubmit.removeAttribute('disabled');

    }

    const handleEnter = (event) => {
        event.key === 'Enter' ? handleSubmit : null;
    }



    return (

        datosUsuario.id == false ?
            <div className='contenedor-login'>
                <form onSubmit={handleSubmit}>
                <br/>
                <br/>
                <div className="mb-3">
                    <h1 className='letra-h1'>Inicio de Sesión para {props.rol}</h1>
                    <br/>
                    <label htmlFor="exampleFormControlInput1" className="form-label">Correo Electrónico</label>
                    <div className='row'>
                    <IconoEmail />
                    <input type="text" className="input-login" id="exampleFormControlInput1" placeholder="nombre@ejemplo.com" style={{border: `1px ${colorLogin} solid`}} name="email" value={formData.email} onChange={handleChange} autoComplete='email'/>
                    </div>
                    <br/>
                    <br/>
                    <label htmlFor="inputPassword5" className="form-label">Contraseña</label>
                    <div className='row'>
                        <IconoLlave />
                        <input type={passwordIsVisible ? "text" : "password"} id="inputPassword5" className="input-login" aria-describedby="passwordHelpBlock" placeholder='Password' style={{border: `1px ${colorLogin} solid`}} name="password" value={formData.password} onChange={handleChange} />
                        <div className='row'>
                        <BotonVisibilidadContrasena passwordIsVisible={passwordIsVisible} setPasswordIsVisible={setPasswordIsVisible}/>
                        </div>
                    </div>
                    <button id='boton-iniciar-sesion' className='btn btn-outline-success' type="submit" onClick={handleSubmit} >Iniciar Sesión</button>
                    <br/>
                    <br/>
                    <a onClick={() => setVentanaRecuperarContrasenaIsOpen(true)} style={{color: 'black', fontSize: '3.5vh', cursor: 'pointer'}}>¿Olvidó su Contraseña?</a>
                    <p></p>
                    <a onClick={() => navigate(`/registro/${props.rol!='Pacientes' ? 'medicos' : 'pacientes'}`)} style={{color: 'black', fontSize: '3.5vh', cursor: 'pointer'}}>¿No posee una cuenta? Registrate Aquí</a>
                    {mensajeLoginInvalido && <h2 style={{color: 'red'}}>{mensajeLoginInvalido}</h2>}
                </div>
                <VentanaRecuperarContrasena isOpen={ventanaRecuperarContrasenaIsOpen} onClose={() => setVentanaRecuperarContrasenaIsOpen(false)} email={formData} handleChange={handleChange} checkEmail={checkEmail} rol={props.rol}/>
                <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => setModalResultado(false)} data={resultado}/>
                </form>
            </div>
        :

        navigate('/')
    );
}

export default PaginaLogin;