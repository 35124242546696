import Cookies from "js-cookie";
import '../estilos/paginaAdmDeCuenta.css';
import {cambiarTitulo, convertirNumero, formatearFecha, haPasadoLaFecha, solicitarDatos } from "../funcionesFront";
import Tabla from "./tabla";
import { useEffect, useState } from "react";
import CirculoGirador from '../iconos/circuloGirador';
import SimboloMas from '../iconos/simboloMas';
import { useNavigate } from "react-router-dom";
import apiUrl from "../url";

function PaginaAdmDeCuenta () {

    cambiarTitulo(document, 'Administración de Cuenta');

    const navigate = useNavigate();

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

    const [pagos, setPagos] = useState('');

    const [tasaBCV, setTasaBCV] = useState(0);

    const [estadoCuenta, setEstadoCuenta] = useState('');

    //useEffect(() => solicitarDatos(`https://citasfacil.com:8443/api/config/obtenerTasa`, 'GET', {}, setTasaBCV), []);
    
    useEffect(() => {
        fetch(apiUrl + `/api/config/obtenerTasa`, {
          method: "GET", 
          headers: {
            'Content-Type': 'application/json',
            'auth': Cookies.get('authToken'),
            'datosUsuario': Cookies.get('datosUsuario')
              
          },
              
       })
        .then((res) => res.json())
        .then((data) => setTasaBCV(data));
        
      }, []);

    //useEffect(() => solicitarDatos(`https://citasfacil.com:8443/api/config/obtenerPagos/${datosUsuario.id}`, 'GET', {}, setPagos), []);
    
    useEffect(() => {
        fetch(apiUrl + `/api/config/obtenerPagos/${datosUsuario.id}`, {
          method: "GET", 
          headers: {
            'Content-Type': 'application/json',
            'auth': Cookies.get('authToken'),
            'datosUsuario': Cookies.get('datosUsuario')
              
          },
              
       })
        .then((res) => res.json())
        .then((data) => setPagos(data));
        
      }, []);

    //useEffect(() => solicitarDatos(`https://citasfacil.com:8443/api/config/obtenerEstadoCuenta/${datosUsuario.id}`, 'GET', {}, setEstadoCuenta), []);
    
    useEffect(() => {
        fetch(apiUrl + `/api/config/obtenerEstadoCuenta/${datosUsuario.id}`, {
          method: "GET", 
          headers: {
            'Content-Type': 'application/json',
            'auth': Cookies.get('authToken'),
            'datosUsuario': Cookies.get('datosUsuario')
              
          },
              
       })
        .then((res) => res.json())
        .then((data) => setEstadoCuenta(data));
        
      }, []);

    const [servicios, setServicios] = useState(''); //servicios disponibles en el sistema, no son los contratados por el cliente

    useEffect(() => solicitarDatos(apiUrl + `/api/datosPaginaReservacion/obtenerServicios/mes`, 'GET', {}, setServicios), []);

    const fechaPago = formatearFecha(estadoCuenta.fechaPago);
    
    const cabezasEstadoCuenta = ['Fecha de Pago', 'Status de Cuenta', 'Saldo en cuenta', 'Deuda'];
    const cabezasServiciosActivos = ['Servicio', 'Descripción', 'Costo']; 
    

    var info;
    estadoCuenta !== '' ? info = [fechaPago, estadoCuenta.usuarioActivo ? "Activa" : "Inactiva", '$' + convertirNumero(estadoCuenta.saldo), '$' + convertirNumero(estadoCuenta.deuda) + ' | Bs. ' + convertirNumero(estadoCuenta.deuda*tasaBCV)] : null;
    
      

    return (
        datosUsuario.id !== '' ?
            estadoCuenta !== ''  && servicios !== '' ?  
                <div className="contenedor-pagina-adm-cuenta">

                    <table>
                        <thead>
                            <tr>
                                {cabezasEstadoCuenta.map(cabeza => <th className="centrar" key={cabeza}>{cabeza}</th>)}
                                
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr>
                                {Object.values(info).map((element) => <td className="centrar" key={element} >{element}</td>)}
                            </tr>
                            
                        </tbody>
                        
                    </table>

                    <div className="contenedor-titulo-boton-pagos">
                        <h2 className="titulo-ultimos-pagos">Servicios contratados</h2>
                        {/* <button className="btn btn-success boton-registrar-pago" onClick={() => navigate('/admdecuenta/agregarservicio')}><SimboloMas tamano={32}/>{' Agregar Servicio'}</button> */}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                {cabezasServiciosActivos.map(cabeza => <th className="centrar" key={cabeza}>{cabeza}</th>)}
                                
                            </tr>
                        </thead>
                        <tbody>
                            
                            
                                {servicios.filter(element => estadoCuenta.servicios.includes(element.id))
                                .map((element) => (

									<tr>
                                        <td className="centrar" key={element.id}>{element.nombre}</td>
                                        <td className="centrar" key={element.id}>{element.descripcion}</td>
										<td className="centrar" key={element.id}>{element.costo}</td>
									</tr>
                                  
                                ))}
                            
                            
                        </tbody>
                        
                    </table>

                    <div className="contenedor-ultimos-pagos">
                        <div className="contenedor-titulo-boton-pagos">
                            <h2 className="titulo-ultimos-pagos">Últimos pagos registrados</h2>
                            <button className="btn btn-success boton-registrar-pago" onClick={() => navigate('/admdecuenta/registrarpago')}><SimboloMas tamano={32}/>{' Registrar pago'}</button>
                        </div>
                        <br />
                        <div>
                            {datosUsuario.id !== '' ?
                        
                            <Tabla cabezas={['Fecha', 'Monto', 'Número de referencia', 'Método de Pago', 'Estado']} data={pagos} admin={{admin: false}} tipo='Pagos' datosUsuario={datosUsuario}/> 
                            :
                            null
                            }
                        </div>
                    </div>
                </div>

            :

            <div className="contenedor-circulo-girador">

                <CirculoGirador />

            </div>

        :

        navigate('/')

    );
}

export default PaginaAdmDeCuenta;