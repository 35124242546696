import { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { obtenerImagen, cambiarFoto } from "../funcionesFront";
import { useNavigate } from "react-router-dom";
import VentanaResultado from "./ventanaResultado";
import OpcionCambiarCorreo from "./opcionCambiarCorreo";
import OpcionCambiarTelefono from "./opcionCambiarTelefono";
import '../estilos/registroMedico.css'
import apiUrl from "../url";

function PaginaConfiguracionPacientes () {

    const navigate = useNavigate();

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

    const [modalResultado, setModalResultado] = useState(false);
    const [resultado, setResultado] = useState('');

    const [data, setData] = useState('');
    const [img, setImg] = useState('');
    const [imgAEnviar, setImgAEnviar] = useState('');

    const inputFileRef = useRef(null);

    const [configSeleccionada, setConfigSeleccionada] = useState('');

    const inputs = {
        'Nombres': 'nombres',
        "Apellidos" : "apellidos",
        "Cédula de Identidad" : "cedula",
        "Fecha de nacimiento" : "fechaNacimiento",
        
    }

    useEffect(() => {
        fetch(apiUrl + `/api/config/datosPaciente/${datosUsuario.id}`, {
            method: "GET", 
            headers: {
                'Content-Type': 'application/json',
                'auth': Cookies.get('authToken'),
                'datosUsuario': Cookies.get('datosUsuario')
            },
                
         })
         .then((res) => res.json())
         .then((data) => {
            setData(data);
            obtenerImagen('pacientes', data.id, (img) => setImg(img));
        });
    }, []);

    const handleFileInputChange = () => {
        // Simula hacer clic en el input de tipo "file" cuando se hace clic en el botón
        inputFileRef.current.click();
      };
    
      const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          // Aquí puedes realizar acciones con el archivo seleccionado, como subirlo a un servidor o procesarlo.
          setImg(URL.createObjectURL(file));
          setImgAEnviar(file);
          
        }
      };

      const guardarCambios = () => {

        if(imgAEnviar !== ''){
            
            cambiarFoto('config','pacientes', data.id, imgAEnviar);
            setResultado({
                success: true,
                message: "La información ha sido actualizada exitosamente"
            });
            setModalResultado(true);
            
        }
      };

      const handleOpcionChange = (opcion) => {

        setConfigSeleccionada(opcion);

    };

      const opciones={
    
        "Cambiar Correo Electrónico" : <OpcionCambiarCorreo cancelar={() => setConfigSeleccionada('')} />,
        //"Cambiar Número Telefónico" : <OpcionCambiarTelefono cancelar={() => setConfigSeleccionada('')} />
    };

    return (
        datosUsuario.rol === 'pacientes' ? 
            <div className="contenedor-pagina-config">
                <div className="contenedor-config-personal row">

                    <div className="informacion-personal">

                        {Object.keys(inputs).map(dato => (
                            <div>
                                <label htmlFor="exampleFormControlInput1" className="form-label" >{dato}</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={data[inputs[dato]]} disabled/>
                            </div>
                        ))}

                        <div className="contenedor-info-foto">
                            <p className="texto-foto-perfil">Foto de perfil</p>

                            <div className="foto-container">
                                <button className="boton-foto" onClick={handleFileInputChange}>
                                    <picture>
                                        <img className="foto-paciente" src={img} alt="Foto del Paciente" />
                                    </picture>
                                </button>
                                <input
                                type="file"
                                accept="image/*"
                                ref={inputFileRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                />
                            </div>

                            <button className="btn btn-success" onClick={handleFileInputChange}>Cambiar foto de perfil</button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <button className="btn btn-success boton-guardar-cambios" id='boton-guardar' onClick={() => guardarCambios()}>Guardar Cambios</button>
                    <button className="btn btn-danger boton-cancelar" onClick={() => navigate('/')}>Cancelar</button>
                </div>

                <div className="btn-group contenedor-botones-seleccion-config" role="group" aria-label="Basic radio toggle button group">
                    {Object.keys(opciones).map((opcion) => (
                        <span className="boton-seleccion-config">
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off"  checked={opcion===configSeleccionada} />
                            <label class="btn btn-outline-primary botones-seleccionar-config" for="btnradio1" onClick={() => handleOpcionChange(opcion)}>{opcion}</label>
                        </span>
                    ))}

                </div>
                
                { opciones[configSeleccionada] }

                <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={null} data={resultado}/>
            </div>
        :
        null
            
    );
}

export default PaginaConfiguracionPacientes;