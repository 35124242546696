function CirculoGirador (){
    return (
        
        <div style={{paddingTop: '3vh', textAlign: 'center'}}>
            <div className="spinner-border text-primary circulo-girador" role="status" style={{minWidth: '50px', minHeight: '50px'}}>
            <span className="visually-hidden">Loading...</span>
            </div>
            <p>Cargando...</p>
        </div>
    );
}

export default CirculoGirador;