import { useEffect, useState } from "react";
import { solicitarDatos } from "../../funcionesFront";
import CirculoGirador from '../../iconos/circuloGirador';
import Tabla from '../tabla';
import Cookies from "js-cookie";
import apiUrl from "../../url";

function OpcionPagos (props) {

    const datosAdmin = JSON.parse(Cookies.get('datosUsuario'));

    const [data, setData] = useState('');
    const [refrescar, setRefrescar] = useState('');

    //useEffect( () => solicitarDatos('/api/admin/operador/obtenerPagosPendientes', "GET", {}, setData), [refrescar]);
    useEffect(() => {
        fetch(apiUrl + `/api/admin/operador/obtenerPagosPendientes`, {
          method: "GET", 
          headers: {
            'Content-Type': 'application/json',
            'auth': Cookies.get('authToken'),
            'datosUsuario': Cookies.get('datosUsuario')
              
          },
              
       })
        .then((res) => res.json())
        .then((data) => setData(data));
        
      }, [refrescar]);

    
    return(
        props.opcionSeleccionada === "Pagos" ?
            data !== '' ? (
                <div className="contenedor-inicio">
                    <div className="pagos-pendientes">
                        <h1>Pagos Pendientes</h1>
                        <br/>
                        <Tabla cabezas={['Fecha', 'Monto', 'Número de referencia', 'Método de Pago', 'Confirmar', '']} data={data} admin={{admin: true, setData: setRefrescar}} tipo={props.opcionSeleccionada} datosUsuario={datosAdmin}/> 
                    </div>
                </div>
            )

            : 
            
            <div>
                
                <CirculoGirador />
            </div>
        :

        null
    );
}

export default OpcionPagos;