import '../estilos/paginaAccesoDenegado.css'

function PaginaAccesoDenegado (){
    return (
        <div className="contenedor-acceso-denegado">
            <h1 >Acceso Denegado</h1>
            <h3>Usted no tiene acceso a este sitio</h3>
        </div>
    );
}

export default PaginaAccesoDenegado;