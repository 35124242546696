import { useEffect, useRef, useState} from "react";
import { fetchData, sortWeekdays, convertirTiempo, buscarCiudadAcento, buscarEstadoAcento, formatearFecha } from "../funcionesFront";
import VentanaResultado from "./ventanaResultado";
import { useForm } from "react-hook-form";
import TimePicker from "./timePicker";
import NumberInput from "./numberInput";
import { estados } from "../venezuela";
import VentanaCambioHorario from "./ventanaCambioHorario";
import { format } from "date-fns";



function ConfigHorarios (props) {

    //HUBO ALGUNOS HOOKS CUYO VALOR INICIAL SE PUSO UNO RANDOM, ES POR EL TEMA DE CUANDO SE DA EN EL BOTON DE AGREGAR Y LUEGO A DESHACER PARA QUE SE VUELVA A SELECCIONAR LA PRIMERA UBICACION
    //HUBO ALGUNOS HOOKS CUYO VALOR INICIAL SE PUSO UNO RANDOM, ES POR EL TEMA DE CUANDO SE DA EN EL BOTON DE AGREGAR Y LUEGO A DESHACER PARA QUE SE VUELVA A SELECCIONAR LA PRIMERA UBICACION

    const [agregarNuevaUbicacion, setAgregarNuevaUbicacion] = useState(false);

    const [ubicacion, setUbicacion] = useState(props.data.ubicaciones[0]);

    const [resultado, setResultado] = useState('');
    const [modalResultado, setModalResultado] = useState(false);
    const [ventanaCambioHorarioIsOpen, setVentanaCambioHorarioIsOpen] = useState(false);

    const [fechaCambioHorario, setFechaCambioHorario] = useState(null);

    const [direccion, setDireccion] = useState(ubicacion.direccion);

    const [estadoSeleccionado, setEstadoSeleccionado] = useState('');
    const [ciudadSeleccionada, setCiudadSeleccionada] = useState('');

    const [diasDeTrabajoChecked, setDiasDeTrabajoChecked] = useState([]);
    
    const [horaInicio, setHoraInicio] = useState('00:00');
    const [horaFin, setHoraFin] = useState('00:00');

    const [duracionCita, setDuracionCita] = useState(convertirTiempo(ubicacion.duracionCita));

    const [descansoDiario, setDescansoDiario] = useState(ubicacion.descansoDiario != '00:00-00:00');
    const  [horaInicioDescanso, setHoraInicioDescanso] = useState('00:00');
    const  [horaFinDescanso, setHoraFinDescanso] = useState('00:00');

    const [proyeccionCitas, setProyeccionCitas] = useState(ubicacion.proyeccionCitas);

    const [pacienteMismoDia, setPacienteMismoDia] = useState(ubicacion.pacienteMismoDia != 0);
    const [reservaMismoDia, setReservaMismoDia] = useState(ubicacion.reservaMismoDia);

    const [datosAlmacenados, setDatosAlmacendados] = useState('');  //esto es para detectar si hay que hacer un cambio de horario que afecte las citas programadas

    useEffect(() => {
        setDireccion('');
        setEstadoSeleccionado('');
        setCiudadSeleccionada('');
        setDiasDeTrabajoChecked([]);
        setHoraInicio('09:00');
        setHoraFin('15:00');
        setDuracionCita(30);
        setDescansoDiario(false);
        setHoraInicioDescanso('12:00');
        setHoraFinDescanso('14:00');
        setProyeccionCitas(7);
        setPacienteMismoDia(false);
        setReservaMismoDia(true);
        agregarNuevaUbicacion ? setUbicacion('') : setUbicacion(props.data.ubicaciones[0]);
    }, [agregarNuevaUbicacion]);

    useEffect(() => {
        console.log(ubicacion);
        if(ubicacion !== ''){  //dejo el if por la indentancion jaja
            setDireccion(ubicacion.direccion);
            setEstadoSeleccionado(buscarEstadoAcento(ubicacion.estado));
            setCiudadSeleccionada(buscarCiudadAcento(ubicacion.estado,ubicacion.ciudad));
            setDiasDeTrabajoChecked(ubicacion.diasDeTrabajo === '' ? [] : ubicacion.diasDeTrabajo.split(','));
            setHoraInicio(ubicacion.horarioNormal.split('-')[0]);
            setHoraFin(ubicacion.horarioNormal.split('-')[1]);
            setDuracionCita(convertirTiempo(ubicacion.duracionCita));
            setDescansoDiario(ubicacion.descansoDiario != '00:00-00:00');
            setHoraInicioDescanso(ubicacion.descansoDiario.split('-')[0]);
            setHoraFinDescanso(ubicacion.descansoDiario.split('-')[1]);
            setProyeccionCitas(ubicacion.proyeccionCitas);
            setPacienteMismoDia(ubicacion.pacienteMismoDia != 0);
            setReservaMismoDia(ubicacion.reservaMismoDia);
            setDatosAlmacendados({
                diasDeTrabajo: ubicacion.diasDeTrabajo,
                horarioNormal: ubicacion.horarioNormal,
                duracionCita: ubicacion.duracionCita,
                descansoDiario: ubicacion.descansoDiario,
                proyeccionCitas: ubicacion.proyeccionCitas

            });
        }
    }, [ubicacion]);


    const handleCambioEstadoChange = (estado) => {
        setEstadoSeleccionado(estado);
        setCiudadSeleccionada("Seleccionar Ciudad");
    }
     
    const handleDiasDeTrabajoChange = (event) => {

        
        const dia = event.target.value;
        const diaACambiar = dia.substring(0,2).toLowerCase();
        if(diasDeTrabajoChecked.includes(diaACambiar)){
            setDiasDeTrabajoChecked(diasDeTrabajoChecked.filter(diasT => diasT !== diaACambiar))
        }else{
            const indice = diasDeLaSemana.indexOf(dia);
            setDiasDeTrabajoChecked((prevDias) => {
                const nuevosDias = [...prevDias];
                nuevosDias.splice(indice, 0, diaACambiar);
                return nuevosDias;
            });
            
            
        }
    }

		const guardarCambios = () => {

            const horarioNormal = horaInicio + '-' + horaFin;

            if(diasDeTrabajoChecked.length>0 && horarioNormal !== '00:00-00:00'){

                const valoresIniciales = Object.values(datosAlmacenados);
                var cambioHorario = true;
                console.log(valoresIniciales)
                const checkDiasT = valoresIniciales[0].split(',').every(a => diasDeTrabajoChecked.includes(a));
                console.log(checkDiasT)
                if(checkDiasT && valoresIniciales[1] == horarioNormal && valoresIniciales[2] == convertirTiempo(duracionCita) && valoresIniciales[3] == horaInicioDescanso + '-' + horaFinDescanso || agregarNuevaUbicacion){

                    const datos = {
                        metodo:  "PUT",
                        peticion: `config/medicos/horarios/normal`,
                        idElemento: 'boton-guardar-cambios',
                        datosReq: {
                                agregarNuevaUbicacion: agregarNuevaUbicacion,
                                idMedico: props.data.id,
                                ubicacion: ubicacion.id,
                                direccion: direccion,
                                estado: estadoSeleccionado,
                                ciudad: ciudadSeleccionada,
                                diasDeTrabajo: sortWeekdays(diasDeTrabajoChecked),
                                horarioNormal: horarioNormal,
                                duracionCita: convertirTiempo(duracionCita),
                                descansoDiario: descansoDiario ? horaInicioDescanso + "-" + horaFinDescanso : "00:00-00:00",
                                proyeccionCitas: proyeccionCitas,
                                pacienteMismoDia: pacienteMismoDia ? "1" : "0",
                                reservaMismoDia: reservaMismoDia
                                
                        }
                    }

                    fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
                }else{
                    setVentanaCambioHorarioIsOpen(true);
                }
            }else{
                setResultado({success: false, message: 'Llene los datos solicitados'});
                setModalResultado(true);
            }
		
		}

        const guardarCambiosProgramado = () => {

            const horarioNormal = horaInicio + '-' + horaFin;

            if(diasDeTrabajoChecked.length>0 && horarioNormal !== '00:00-00:00'){

                const datos = {
                    metodo:  "PUT",
                    peticion: `config/medicos/horarios/programado`,
                    idElemento: 'boton-guardar-cambios',
                    datosReq: {
                            idMedico: props.data.id,
                            fechaCambioHorario: format(fechaCambioHorario, 'yyyy-MM-dd'),
                            ubicacion: ubicacion.id,
                            direccion: direccion,
                            diasDeTrabajo: sortWeekdays(diasDeTrabajoChecked),
                            horarioNormal: horarioNormal,
                            duracionCita: convertirTiempo(duracionCita),
                            descansoDiario: descansoDiario ? horaInicioDescanso + "-" + horaFinDescanso : "00:00-00:00",
                            proyeccionCitas: proyeccionCitas,
                            
                    }
                }

                fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
                
            }else{
                setResultado({success: false, message: 'Llene los datos solicitados'});
                setModalResultado(true);
            }
		
		}

        const eliminarCambiosProgramado = () => {

        

            const datos = {
                metodo:  "DELETE",
                peticion: `config/medicos/horarios/eliminar-programado`,
                idElemento: 'boton-guardar-cambios',
                datosReq: {
                        idMedico: props.data.id,
                        ubicacion: ubicacion.id,
                        
                }
            }

            fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
                
		}

        const diasDeLaSemana = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
        
        console.log(props.data);
       
    return(
        ubicacion !== '' || agregarNuevaUbicacion ?
        <div className="contenedor-pagina-config">
            <div className="centrar">
                <p>Si desea agregar una nueva ubicación (otra sede por donde realice consultas), presione el botón</p>
                <button className={`btn ${agregarNuevaUbicacion ? 'btn-danger' : 'btn-success'} centrar`} onClick={() => setAgregarNuevaUbicacion(!agregarNuevaUbicacion)}>{agregarNuevaUbicacion ? 'Deshacer' : 'Agregar ubicación'}</button>
            </div>
            <br/>

            { agregarNuevaUbicacion === false ?
                <div className="dropdown centrar">
                    <h4 className="centrar">Ubicaciones</h4>
                    <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {`${ubicacion.direccion}, ${buscarCiudadAcento(ubicacion.estado, ubicacion.ciudad)} - Edo. ${buscarEstadoAcento(ubicacion.estado)}`}
                    </button>
                    <ul className="dropdown-menu menu-fecha">
                        {props.data.ubicaciones.map((ubicacion, index) => 
                        
                        <li key={ubicacion.direccion}><a className="dropdown-item dias-no-laborables" onClick={() => setUbicacion(props.data.ubicaciones[index])}>{ `${ubicacion.direccion}, ${buscarCiudadAcento(ubicacion.estado, ubicacion.ciudad)} - Edo. ${buscarEstadoAcento(ubicacion.estado)}` }</a></li>
                        
                        )}
                    </ul>
                </div>
                :
                null
            }
            <div className="contenedor-agregar-especialidad">
                <p className="subtitulos">Dirección</p>
                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Dirección..." onChange={(event) => setDireccion(event.target.value)} value={direccion}/>                          
            </div>
            <br/>
            <div className="contenedor-config-personal row">
            <label htmlFor="exampleFormControlInput1" className="subtitulos" >Estado</label>
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle menu-seleccion-estado-ciudad" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {estadoSeleccionado}
                    </button>
                        <ul className="dropdown-menu valores-seleccion-estado-ciudad">
                            {Object.keys(estados).map((estado) => 
                            <li key={estado}><a className="dropdown-item" onClick={() => handleCambioEstadoChange(estado)}>{estado}</a></li>
                            )}
                        </ul>
                </div>
                

                <label htmlFor="exampleFormControlInput1" className="subtitulos" >Ciudad</label>
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle menu-seleccion-estado-ciudad" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {ciudadSeleccionada}
                    </button>
                        <ul className="dropdown-menu valores-seleccion-estado-ciudad">
                            {(estadoSeleccionado !== '' ? estados[estadoSeleccionado] : []).map((ciudad) => 
                            <li key={ciudad}><a className="dropdown-item" onClick={() => setCiudadSeleccionada(ciudad)}>{ciudad}</a></li>
                            )}
                        </ul>
                </div>

                {/*agregandio*/}
                    <p></p>
                    <p className="subtitulos centrar">Dias de Trabajo</p>
                    <div className="dias-de-trabajo" role="group" aria-label="Basic checkbox toggle button group" onChange={handleDiasDeTrabajoChange}>
                        
                        {diasDeLaSemana.map((dia) => (

                            <div className="contenedor-boton-seleccion" style={{minWidth: '200px'}} key={dia}>
                                <input type="checkbox" className="btn-check" id={dia} value={dia} autoComplete="off"  checked={diasDeTrabajoChecked.includes(dia.substring(0,2).toLowerCase())} />
                                <label className="btn btn-outline-primary boton-seleccion" htmlFor={dia}>{dia}</label>
                            </div>
                        ))}
                    </div>
                

                <div className="contenedor-hora-trabajo">
                    <p className="subtitulos">Horario de Trabajo</p>
                    
                    <TimePicker hora={horaInicio} titulo={"Hora Inicio"} handleChange={(event) => setHoraInicio(event.target.value)}/>
                    <TimePicker hora={horaFin} titulo={"Hora Fin"} handleChange={(event) => setHoraFin(event.target.value)}/>

                </div>

                <div className="contenedor-duracion-cita">
                    <p className="subtitulos">Duración de Citas (en minutos)</p>
                    
                    <NumberInput proyeccionCitas={duracionCita} titulo={""} handleChange={setDuracionCita}/>

                </div>

                <div className="contenedor-descanso-diario">
                    <p className="subtitulos">Descanso Diario</p>

                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={descansoDiario} onChange={() => setDescansoDiario(!descansoDiario)}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">¿Desea establecer un descanso en su jornada?</label>
                        

                    {descansoDiario ? (
                        <div className="contenedor-tp-descanso-diario">
                            <TimePicker hora={horaInicioDescanso} titulo={"Hora Inicio"} handleChange={(event) => setHoraInicioDescanso(event.target.value)}/>
                            <TimePicker hora={horaFinDescanso} titulo={"Hora Fin"} handleChange={(event) => setHoraFinDescanso(event.target.value)}/>
                        </div>
                    )
                    :
                    null
                    }
                    </div>
                </div>

                <p className="subtitulos">Proyección de la disponibilidad de las citas</p>
                <div className="contenedor-proyeccion-citas">
                    
                    <p>Este es el número de días a partir de la fecha actual en la que se mostrará la disponibilidad de las citas</p>

                    
                    <NumberInput proyeccionCitas={proyeccionCitas} titulo={""} handleChange={setProyeccionCitas}/>

                </div>

                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={pacienteMismoDia} onChange={() => setPacienteMismoDia(!pacienteMismoDia)}/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Permitir que un paciente agende más de una cita en el mismo día</label>
                </div>

                <br/>
                <br/>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={reservaMismoDia} onChange={() => setReservaMismoDia(!reservaMismoDia)}/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Permitir que un paciente agende una cita para el mismo dia en que hace la reserva</label>
                </div>

                {ubicacion.cambioHorarios ?
                    <div className="contenedor-cambio-horario">
                        <div className="col">
                            <h2>Cambio programado de horario</h2>
                            <h3>Fecha a aplicar: {formatearFecha(ubicacion.cambioHorarios.split(';')[0])}</h3>
                            <h3>Dias de trabajo: {ubicacion.cambioHorarios.split(';')[1]}</h3>
                            <h3>Horario: {ubicacion.cambioHorarios.split(';')[2]}</h3>
                            <h3>Duración de cita: {ubicacion.cambioHorarios.split(';')[3]}</h3>
                            <h3>Descanso diario: {ubicacion.cambioHorarios.split(';')[4]}</h3>
                            <h3>Proyección de citas: {ubicacion.cambioHorarios.split(';')[5]} día(s)</h3>
                        </div>

                        {/*<div style={{textAlign: 'center'}}>
                            <button className="btn btn-danger">Eliminar cambio programado de horario</button>
                            <p style={{textAlign: 'center'}}>Si elimina el Cambio de horario, se cancelarán todas las citas programadas para ese horario</p>
                        </div>*/}
                    </div> 
                    :
                    null
                }
            
                
                <div className="row">
                    <button className="btn btn-success boton-guardar-cambios" id='boton-guardar-cambios' onClick={() => guardarCambios()}>Guardar Cambios</button>
                    <button className="btn btn-danger boton-cancelar" onClick={props.cancelar}>Cancelar</button>
                </div>
                    
                <VentanaCambioHorario isOpen={ventanaCambioHorarioIsOpen} onClose={() => setVentanaCambioHorarioIsOpen(false)} proyeccionCitas={datosAlmacenados.proyeccionCitas} guardarCambiosProgramado={() => guardarCambiosProgramado()} fechaCambioHorario={fechaCambioHorario} setFechaCambioHorario={(date) => setFechaCambioHorario(date)}/>
                <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={props.cancelar} data={resultado}/>
            </div>
        </div>
        :
        null
    );
}

export default ConfigHorarios;