import { useState } from "react";
import '../estilos/ventanaAvisoUnloged.css';
import { fetchData } from "../funcionesFront";
import VentanaResultado from "./ventanaResultado";
import moment from "moment";

function VentanaModificarDia (props){

    const [resultadoModificarDia, setResultadoModificarDia] = useState('');

    const [modalResultadoModificarDia, setModalResultadoModificarDia] = useState(false);
 
    const ModificarDia = (fecha, modificacion) => {

        const datos = {
          metodo: modificacion === 'retirardia' ? "POST" : "DELETE",
          peticion: `paginaReservacion/${modificacion}`,
          idElemento: 'boton-confirmar',
          datosReq: {
              ubicacion: props.ubicacion.id,
              idMedico: props.ubicacion.idMedico,
              fechas: [fecha]
          }
      }

      fetchData(datos, (resultado) => setResultadoModificarDia(resultado), () => setModalResultadoModificarDia(true));
      
      }

    return(
        props.isOpen ? 
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title">Modificar día de trabajo</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
                        </div>
                        
                        <div className="modal-body">
                         
                            <div className="cancelar-dia">
                            <h4>{`Va a ${props.dia.modificacion === "retirardia" ? "cancelar" : "reincorporar"} el día ${moment(props.dia.fecha).format("dddd, D \\d\\e MMMM \\d\\e YYYY").replace(/^\w/, (c) => c.toUpperCase())}, ¿está seguro? Esta acción cancelará todas las citas pautadas para esta fecha y es totalmente irreversible. En caso de proseguir, a los pacientes les llegará una notificación vía correo electrónico sobre la cancelación de las mismas.`}</h4>
                            </div>
                        
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-success" id="boton-confirmar"  onClick={() => ModificarDia(props.dia.fecha, props.dia.modificacion)}>Confirmar</button>
                            <button className="btn btn-danger" onClick={props.onClose}>Cancelar</button>
                        </div>
                    </div>
                        <VentanaResultado isOpen={modalResultadoModificarDia} onClose={() => setModalResultadoModificarDia(false)} cerrarVentanaConfirmacion={() => props.onClose()} data={resultadoModificarDia}/>
                </div>
            </div> 
        : (null)
    );
}

export default VentanaModificarDia;