import { useRef, useState, useEffect} from "react";
import { estados } from "../venezuela";
import IconoX from "../iconos/iconoX";
import { fetchData, cambiarFoto, cambiarTitulo, validarEmail, solicitarDatos, sortWeekdays, convertirTiempo, capitalizarPalabras, verificarCedula } from "../funcionesFront";
import VentanaResultado from "./ventanaResultado";
import { useForm } from "react-hook-form";
import sinFoto from '../imagenes/sinfoto.jpg'
import TimePicker from "./timePicker";
import NumberInput from "./numberInput";
import CrearContrasena from "./crearContrasena";
import moment from "moment";
import TotalDelServicio from "./totalServicio";
import '../estilos/registroMedico.css'
import apiUrl from "../url";
import VentanaIntroducirCodigo from "./ventanaIntroducirCodigo";
import VentanaConfirmarCorreo from "./ventanaConfirmarCorreo";
import { useNavigate } from 'react-router-dom';


function RegistroMedicos (props) {

    cambiarTitulo(document, "Registro | Médicos");

    const [resultado, setResultado] = useState('');
    const [modalResultado, setModalResultado] = useState(false);

    const [modalIntroducirCodigo, setModalIntroducirCodigo] = useState(false);

    const navigate = useNavigate();

    const confirmarCorreo = async (event) => {

        if(checkContrasenaNueva && checkEmail){

            setResultado(''); //para evitar que salga un resultados de intentos anteriores

            event.preventDefault();

            const datos = {
                metodo:  "POST",
                peticion: `registro/registrarCorreo`,
                idElemento: 'boton-registrarse',
                datosReq: {
                    nombres: datosBasicos.Nombres,
                    apellidos: datosBasicos.Apellidos,
                    correo: datosBasicos['Correo Electrónico']
                }
            };


            await fetchData(datos, (resultado) => setResultado(resultado), () => setModalIntroducirCodigo(true));

        }else {
            setColorInput('red');
            setMensajeInputContrasena("El correo electrónico y/o la contraseña no cumple con los requisitos");
        }
        
    }

    const accionesModalConfirmarCorreo = () => {
        setModalIntroducirCodigo(false);
        setResultado('');
    }

    const [datosBasicos, setDatosBasicos] = useState({
        'Nombres': "",
        "Apellidos" : "",
        "Cédula de Identidad" : "",
        "Correo Electrónico" : "",
        "Teléfono" : "",
        "M.P.P.S": "",
        "Número de colegio": "" 
    });

    const [checkEmail, setCheckEmail] = useState(false);

    const [fechaNacimiento, setFechaNacimiento] = useState('')
    const [sexo, setSexo] = useState("")

    const [estadoSeleccionado, setEstadoSeleccionado] = useState('');
    const [ciudadSeleccionada, setCiudadSeleccionada] = useState('');
	const [especialidades, setEspecialidades] = useState([]);
	const [direccion, setDireccion] = useState('');
    const [img, setImg] = useState(sinFoto);
    const [imgAEnviar, setImgAEnviar] = useState('');
    const formData = useForm();
     
	const [valorInputEspecialidad, setValorInputEspecialidad] = useState('');
    const inputFileRef = useRef(null);
	const inputRef = useRef(null);

    const [enviarCorreo, setEnviarCorreo] = useState(true);
    const [reservaMismoDia, setReservaMismoDia] = useState(true);

    const [diasDeTrabajoChecked, setDiasDeTrabajoChecked] = useState([]);

    const [horaInicio, setHoraInicio] = useState('09:00');
    const [horaFin, setHoraFin] = useState('15:00');

    const [duracionCita, setDuracionCita] = useState(30);

    const [descansoDiario, setDescansoDiario] = useState(false);
    const  [horaInicioDescanso, setHoraInicioDescanso] = useState('12:00');
    const  [horaFinDescanso, setHoraFinDescanso] = useState('14:00');

    const [proyeccionCitas, setProyeccionCitas] = useState(7);

    const [pacienteMismoDia, setPacienteMismoDia] = useState(false);

    const [newPassword, setNewPassword] = useState('');
    const [checkContrasenaNueva, setCheckContrasenaNueva] = useState(false);
    const [colorInput, setColorInput] = useState('#999');
    const [mensajeInputContrasena, setMensajeInputContrasena] = useState('');

    const [servicios, setServicios] = useState([]);
    const [serviciosChecked, setServiciosChecked] = useState([]);
    useEffect(() => solicitarDatos(apiUrl + '/api/datosPaginaReservacion/obtenerServicios/mes', 'GET', {}, setServicios), []);

    const inputs = {
        'Nombres': 'Nombres',
        "Apellidos" : "Apellidos",
        "Cédula de Identidad" : "V-123456789 ó E-123456789",
        "Correo Electrónico" : "juan@example.com",
        "Teléfono": '04125471475',
        "M.P.P.S": "MPPS...",
        "Número de colegio (opcional)": "Colegio de médicos u odontológos" 
    };



    const handleCambioDatoBasicoChange = (event) => {
        const {name, value} = event.target;

        setDatosBasicos(prevState => ({
            ...prevState, 
            [name]: name === 'Nombres' || name === 'Apellidos' ? capitalizarPalabras(value) : name === "Cédula de Identidad" ? verificarCedula(event, prevState["Cédula de Identidad"]) : value,
        }));
    }

    const handleFileInputChange = () => {
        // Simula hacer clic en el input de tipo "file" cuando se hace clic en el botón
        inputFileRef.current.click();
      };
    
      const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          // Aquí puedes realizar acciones con el archivo seleccionado, como subirlo a un servidor o procesarlo.
          setImg(URL.createObjectURL(file));
          setImgAEnviar(file);
          
        }
      };

    const handleCambioEstadoChange = (estado) => {
        setEstadoSeleccionado(estado);
        setCiudadSeleccionada("");
    }

		const agregarEspecialidad = () => {
				const modificarEspecialidad = especialidades
				modificarEspecialidad.push(capitalizarPalabras(valorInputEspecialidad));
				setEspecialidades(modificarEspecialidad);
				setValorInputEspecialidad('');
				inputRef.current.value = '';
		}
		
		const eliminarEspecialidad = (especialidad) => {
			const modificarEspecialidad = especialidades.filter(a => a !== especialidad);
			setEspecialidades(modificarEspecialidad);

		}

		const handleInputEspecialidadChange = (event) => {
			setValorInputEspecialidad(event.target.value);
		}

        const handleDiasDeTrabajoChange = (event) => {

            const dia = event.target.value;
            const diaACambiar = dia.substring(0,2).toLowerCase();
            if(diasDeTrabajoChecked.includes(diaACambiar)){
                setDiasDeTrabajoChecked(diasDeTrabajoChecked.filter(diasT => diasT !== diaACambiar))
            }else{
                const indice = diasDeLaSemana.indexOf(dia);
                setDiasDeTrabajoChecked((prevDias) => {
                    const nuevosDias = [...prevDias];
                    nuevosDias.splice(indice, 0, diaACambiar);
                    return nuevosDias;
                });
                
                
            }
        }

        const handleServiciosChange = (event) => {
            const dia = Number(event.target.value); // Asegúrate de que 'dia' sea un número
            const nuevosDias = [...serviciosChecked]; // Copia del estado actual
            if (nuevosDias.includes(dia)) {
                // Si el día ya está incluido, lo quitamos
                setServiciosChecked(nuevosDias.filter(d => d !== dia));
            } else {
                // Si el día no está incluido, lo agregamos
                // Pero antes, aseguramos que el 1 y el 2 no estén al mismo tiempo
                if (dia === 1 && nuevosDias.includes(2)) {
                    // Si agregamos el 1, quitamos el 2
                    setServiciosChecked(nuevosDias.filter(d => d !== 2).concat(dia));
                } else if (dia === 2 && nuevosDias.includes(1)) {
                    // Si agregamos el 2, quitamos el 1
                    setServiciosChecked(nuevosDias.filter(d => d !== 1).concat(dia));
                } else {
                    // Si ninguna condición se aplica, solo agregamos el nuevo día
                    setServiciosChecked(nuevosDias.concat(dia));
                }
            }
        };
        

        const handleMessage = (message) => {  //recibe la contrasena nueva


            if(message!==''){
    
                if(newPassword !== message.newPassword){
                    
                    setNewPassword(message.newPassword);
    
                }
    
                setCheckContrasenaNueva(true);   //para determinar si la contrasena nueva cumple y esta lista para procesarla
            
            } else {
                setCheckContrasenaNueva(false);
            }
    
        } 

		const registrarse = async () => {

            

                setResultado('');

                const datos = {
                    metodo:  "POST",
                    peticion: `registro/medicos`,
                    idElemento: 'boton-registrarse',
                    datosReq: {
                            datosBasicos: datosBasicos,
                            fechaNacimiento: fechaNacimiento,
                            sexo: sexo,
                            estado: estadoSeleccionado,
                            ciudad: ciudadSeleccionada,
                            especialidad: especialidades,
                            enviarCorreo: enviarCorreo,
                            reservaMismoDia: reservaMismoDia,
                            direccion: direccion,
                            diasDeTrabajo: diasDeTrabajoChecked.length > 0 ? sortWeekdays(diasDeTrabajoChecked) : ['lu','mi', 'vi'],
                            horarioNormal: horaInicio + "-" + horaFin,
                            duracionCita: convertirTiempo(duracionCita),
                            descansoDiario: descansoDiario ? horaInicioDescanso + "-" + horaFinDescanso : "00:00-00:00",
                            proyeccionCitas: proyeccionCitas,
                            pacienteMismoDia: pacienteMismoDia ? "1" : "0",
                            servicios: serviciosChecked,
                            newPassword: newPassword,
                            reservaMismoDia: reservaMismoDia
                            
                    }
                }


                await fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));

                try{

                    if(imgAEnviar !== ''){
                        cambiarFoto('registro','medicos', datosBasicos['Cédula de Identidad'], imgAEnviar);
                    }

                }catch{
                    console.log('No se pudo enviar la imagen')
                }
                
                

        }

        const diasDeLaSemana = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

        const checkearDatos = (event) => {
            const otrosDatos = {
                "sexo": sexo,
                "fecha-nacimiento": fechaNacimiento,
                "estado": estadoSeleccionado,
                "ciudad": ciudadSeleccionada,
            }
            var validate = true;
            var datosInputs = {...datosBasicos, ...otrosDatos};
            delete datosInputs["Número de colegio"]; //lo estoy poniendo opcional
            Object.keys(datosInputs).forEach(element => {
                const a = datosInputs[element];
                const doc = document.getElementById(element);
                console.log(doc)
                if(a === '') {
                    doc.classList.add('input-vacio');
                    validate = false; 
                }else{
                    doc.classList.remove('input-vacio'); 
                }
            });

            if(horaInicio == '00:00' && horaFin == '00:00'){
                validate = false;
            }

            if(serviciosChecked.length == 0){
                validate = false;
                document.getElementById('servicios').classList.add('input-vacio');
            }else{
                document.getElementById('servicios').classList.remove('input-vacio');
            }

            if(validate){
                setColorInput('#999');
                setMensajeInputContrasena(''); 
                confirmarCorreo(event);
            }else{
                setResultado({
                    success: false,
                    message: 'Debe proporcionar todos los datos solicitados'
                });
                setModalResultado(true);
            }
        }


    return(
        <div>
            <div className="contenedor-pagina-config">
                <div className="titulo-registro">
                    <h1>Registro de Médicos</h1>
                </div>
            <div className="contenedor-config-personal row">

                <div className="informacion-personal">

                    <h1 style={{width: '100%', textAlign: 'center'}}>Datos Básicos</h1>

                    {Object.keys(inputs).map(dato => (
                        <div key={dato} onChange={handleCambioDatoBasicoChange}>
                            <label htmlFor="exampleFormControlInput1" className="form-label" >{dato}</label>
                            <input type="text" className="form-control" id={dato} placeholder={inputs[dato]} name={dato} value={datosBasicos[dato]} onChange={dato !== "Correo Electrónico" ? null : (event) => validarEmail(event, setCheckEmail)} style={{borderColor: dato !== "Correo Electrónico" ? "#999" : colorInput}}/>
                            
                        </div>
                    ))}
                    
                    <label htmlFor="exampleFormControlInput1" className="form-label" >Sexo</label>
                    <div className="dropdown">
                        
                        <button className="btn btn-primary dropdown-toggle menu-seleccion-estado-ciudad" id="sexo" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {sexo}
                        </button>
                            <ul className="dropdown-menu valores-seleccion-estado-ciudad">
                                {["Masculino", "Femenino"].map((sexo) => 
                                <li key={sexo}><a className="dropdown-item" onClick={() => setSexo(sexo)}>{sexo}</a></li>
                                )}
                            </ul>
                    </div>

                    <label htmlFor="exampleFormControlInput1" className="form-label" >Fecha de Nacimiento</label>
                    <input type="date" className="form-control" id="fecha-nacimiento" value={fechaNacimiento} onChange={(event) => setFechaNacimiento(event.target.value)} max={moment().format('YYYY-MM-DD')}/>

                    <div className="contenedor-info-foto">
                        <p className="texto-foto-perfil">Foto de perfil</p>

                        <div className="foto-container">
                            <button className="boton-foto" onClick={handleFileInputChange}>
                                <picture>
                                    <img className="foto-paciente" src={img} alt="Foto del Paciente" />
                                </picture>
                            </button>
                            <input
                            type="file"
                            accept="image/*"
                            ref={inputFileRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            />
                        </div>

                        <button className="btn btn-primary" onClick={handleFileInputChange}>Cambiar foto de perfil</button>

                    </div>

                    <label htmlFor="exampleFormControlInput1" className="form-label subtitulos" >Especialidades</label>
                    <p>Para agregar sus especializaciones, agregue cada una por separado, escribiendo a través del input y presionando el botón "Agregar Especialidad"</p>
                    <div className="contenedor-especialidad">

                        {especialidades.map(especialidad => (
                            <div className="especialidad" key={especialidad}>
                                <p className="palabra-especialidad">
                                {especialidad + " |"}
                                <button type="button" className="boton-eliminar" onClick={() => eliminarEspecialidad(especialidad)}><IconoX/></button>
                                </p>
                            </div>
                        ))}

                        <div className="contenedor-agregar-especialidad">
                            <input type="text" className="form-control" id="agregar-especialidad" placeholder="Agregar especialidad..." onChange={handleInputEspecialidadChange} ref={inputRef}/>
                            <button type="button" className="btn btn-success" onClick={() => agregarEspecialidad()}>Agregar Especialidad</button>
                        </div>
                        

                    </div>

                    </div>

                    <h1>Datos Laborales</h1>

                    <p>En CitasFácil, ¡puedes tener más de una ubicación en donde realices consultas! Cada una con horarios personalizables e independientes. Puedes agregar más ubicaciones en la sección de Configuración luego de crear satisfactoriamente la cuenta.</p>
                
                    <label htmlFor="exampleFormControlInput1" className="form-label subtitulos" >Estado</label>
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle menu-seleccion-estado-ciudad" id="estado" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {estadoSeleccionado}
                        </button>
                            <ul className="dropdown-menu valores-seleccion-estado-ciudad">
                                {Object.keys(estados).map((estado) => 
                                <li key={estado}><a className="dropdown-item" onClick={() => handleCambioEstadoChange(estado)}>{estado}</a></li>
                                )}
                            </ul>
                    </div>
                    

                    <label htmlFor="exampleFormControlInput1" className="form-label subtitulos" >Ciudad</label>
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle menu-seleccion-estado-ciudad" id="ciudad" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {ciudadSeleccionada}
                        </button>
                            <ul className="dropdown-menu valores-seleccion-estado-ciudad">
                                {(estadoSeleccionado !== '' ? estados[estadoSeleccionado] : []).map((ciudad) => 
                                <li key={ciudad}><a className="dropdown-item" onClick={() => setCiudadSeleccionada(ciudad)}>{ciudad}</a></li>
                                )}
                            </ul>
                    </div>

                    <div className="contenedor-agregar-especialidad">
                        <p className="subtitulos">Dirección</p>
                        <input type="text" className="form-control" id="dirreccion" placeholder="Dirección..." onChange={(event) => setDireccion(event.target.value)} value={direccion}/>
                                            
                    </div>

                    <br/>
                    <br/>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={enviarCorreo} onChange={() => setEnviarCorreo(!enviarCorreo)}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Notificar vía correo electrónico cuando un paciente agende una cita</label>
                    </div>
                    
            </div>
            </div>

            <div className="contenedor-pagina-config">
            <div className="contenedor-config-personal row">

                    
                    
                    <p className="subtitulos">Dias de Trabajo</p>
                    <div className="dias-de-trabajo" role="group" aria-label="Basic checkbox toggle button group" onChange={handleDiasDeTrabajoChange}>
                        
                        {diasDeLaSemana.map((dia) => (

                            <div className="contenedor-boton-seleccion" key={dia}>
                                <input type="checkbox" className="btn-check" id={dia} value={dia} autoComplete="off"  checked={diasDeTrabajoChecked.includes(dia.substring(0,2).toLowerCase())} />
                                <label className="btn btn-outline-primary boton-seleccion" htmlFor={dia}>{dia}</label>
                            </div>
                        ))}
                    </div>
                

                <div className="contenedor-hora-trabajo">
                    <p className="texto-foto-perfil subtitulos">Horario de Trabajo</p>
                    <p>(Formato 24hrs.)</p>
                    
                    <TimePicker hora={horaInicio} titulo={"Hora Inicio"} handleChange={(event) => setHoraInicio(event.target.value)}/>
                    <TimePicker hora={horaFin} titulo={"Hora Fin"} handleChange={(event) => setHoraFin(event.target.value)}/>

                </div>

                <div className="contenedor-duracion-cita">
                    <p className="subtitulos">Duración de Citas (en minutos)</p>
                    
                    <NumberInput proyeccionCitas={duracionCita} titulo={""} handleChange={setDuracionCita}/>

                </div>

                <div className="contenedor-descanso-diario">
                    <p className="subtitulos">Descanso Diario</p>

                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={descansoDiario} onChange={() => setDescansoDiario(!descansoDiario)}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">¿Desea establecer un descanso en su jornada?</label>
                    </div>

                    {descansoDiario ? (
                        <span>
                            <TimePicker hora={horaInicioDescanso} titulo={"Hora Inicio"} handleChange={(event) => setHoraInicioDescanso(event.target.value)}/>
                            <TimePicker hora={horaFinDescanso} titulo={"Hora Fin"} handleChange={(event) => setHoraFinDescanso(event.target.value)}/>
                        </span>
                    )
                    :
                    null
                    }
                </div>

                <div className="contenedor-proyeccion-citas">
                    <p className="subtitulos">Proyección de la disponibilidad de las citas</p>
                    <p>Este es el número de días a partir de la fecha actual en la que se mostrará la disponibilidad de las citas</p>

                    
                    <NumberInput proyeccionCitas={proyeccionCitas} titulo={""} handleChange={setProyeccionCitas}/>

                </div>

                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={pacienteMismoDia} onChange={() => setPacienteMismoDia(!pacienteMismoDia)}/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Permitir que un paciente agende más de una cita en el mismo día</label>
                </div>

                
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={reservaMismoDia} onChange={() => setReservaMismoDia(!reservaMismoDia)}/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Permitir que un paciente agende una cita para el mismo dia en que hace la reserva</label>
                </div>

                <div className="servicios" role="group" aria-label="Basic checkbox toggle button group" onChange={handleServiciosChange}>
                        <p className="subtitulos" id="servicios">Servicios disponibles en CitasFacil</p>
                        {servicios.map((servicio) => (

                            <span key={servicio.nombre}>
                                <div className="contenedor-boton-seleccion-servicios" key={servicio.nombre}>
                                    <input type="checkbox" className="btn-check" id={servicio.nombre} value={servicio.id} autoComplete="off"  checked={serviciosChecked.includes(servicio.id)} />
                                    <label className="btn btn-outline-primary boton-seleccion" htmlFor={servicio.nombre}>{servicio.nombre}</label>
                                </div>
                                <p>{servicio.descripcion}</p>
                            </span>
                        ))}
                        <span className="contenedor-total-servicio">
                        <TotalDelServicio servicios={servicios} serviciosChecked={serviciosChecked}/>
                        </span>
                </div>
        
                </div>
                    <h2 >Crear Contraseña</h2>
                    <CrearContrasena onMessage={handleMessage} colorLogin={colorInput}/>
                    <p style={{color: 'red'}}>{mensajeInputContrasena}</p>
            </div>

            <div className="row contenedor-boton-guardar-cambios">
                <button className="btn btn-success boton-guardar-cambios" id='boton-registrarse' onClick={(event) => checkearDatos(event)}>Registrarse</button>
            </div>

            <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => navigate('/')} data={resultado}/>
            <VentanaConfirmarCorreo isOpen={modalIntroducirCodigo} onClose={() => accionesModalConfirmarCorreo()} cerrarVentanaConfirmacion={() => navigate('/')} resultado={resultado} registrarse={() => registrarse()}/>
        </div>
    );
}

export default RegistroMedicos;