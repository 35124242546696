import '../estilos/opcionSeleccionarHorarios.css'

function OpcionSeleccionarHorarios (props) {

    const handleCheckboxChange = (event) => {
        const checkboxValue = event.target.value; // Obtenemos el valor del checkbox que cambió
      
        if (props.selectedCheckboxes.includes(checkboxValue)) {
          // Si el valor ya está en el array de opciones seleccionadas, lo eliminamos
          props.setSelectedCheckboxes(props.selectedCheckboxes.filter(value => value !== checkboxValue));
        } else {
          // Si el valor no está en el array, lo añadimos
          props.setSelectedCheckboxes([...props.selectedCheckboxes, checkboxValue]);
        }
      };
      

    return(

        props.isOpen === "Seleccionar Horarios" ? (

            <div className="contenedor-seleccion-horarios" role="group" aria-label="Basic checkbox toggle button group" onChange={handleCheckboxChange}>
                {props.citas.map((cita) => (

                    <div className="contenedor-boton-seleccion">
                        <input type="checkbox" class="btn-check" id={cita} value={cita} autocomplete="off" />
                        <label class="btn btn-outline-primary boton-seleccion" for={cita}>{cita}</label>
                    </div>
                ))}
            </div>
        
        )
        :
        null
    
    );

}

export default OpcionSeleccionarHorarios;