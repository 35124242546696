import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../estilos/paginaInicio.css';
import Cookies from 'js-cookie';
import { cambiarTitulo } from '../../funcionesFront';
import OpcionInicio from './opcionInicio';
import OpcionPagos from './opcionPagos';
import OpcionAtencion from './opcionAtencion';
import OpcionTasa from './opcionTasa';
import OpcionMetodosDePago from './opcionMetodosDePago';
import apiUrl from '../../url';



function PaginaInicioAdm () {

    const navigate = useNavigate();

    cambiarTitulo(document, "Pagina de Administradores");

    var datosAdmin;
    
    datosAdmin = JSON.parse(Cookies.get('datosUsuario'));
    

    const opciones = ['Inicio', 'Pagos', 'Tasa', 'Métodos de pago' , 'Atención al Cliente'];
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(opciones[0]);

    const handleExit = () => {
        fetch(apiUrl + '/api/admin/login/cerrarSesion', {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(datosAdmin)
        });


        Cookies.set('datosUsuario', JSON.stringify({
            id: '',
            rol: ''
        }));
        Cookies.set('authToken', '');

        navigate('/');
    }

    
  return (
    <div className="home-page-adm" >
        <main className="main-adm">
            <section className="hero-section-adm" style={{paddingBottom: '10vh'}}>
                <div className="hero-content">
                    <br/>

                    <button className='btn btn-danger' onClick={() => handleExit()}>Cerrar Sesión</button>

                    <br />
                    
                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                        {opciones.map((opcion) => (
                            <span key={opcion}>
                                <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off"  checked={opcion===opcionSeleccionada} />
                                <label className="btn btn-outline-primary" htmlFor="btnradio1" onClick={() => setOpcionSeleccionada(opcion)}>{opcion}</label>
                            </span>
                        ))}

                        <br />

                        

                    </div>
                    <br/>

                    <OpcionInicio opcionSeleccionada={opcionSeleccionada} />
                    <OpcionPagos opcionSeleccionada={opcionSeleccionada} />
                    <OpcionAtencion opcionSeleccionada={opcionSeleccionada} />
                    <OpcionTasa opcionSeleccionada={opcionSeleccionada} />
                    <OpcionMetodosDePago opcionSeleccionada={opcionSeleccionada} />

                </div>
            </section>

        {/* Agrega aquí más secciones de contenido */}
            
        </main>
        
    </div>
    
  );
};

export default PaginaInicioAdm;
