import RegistroMedicos from "./registroMedicos";
import '../estilos/paginaRegistroMedico.css'


function PaginaRegistroMedico () {
    return(
        <div className="contenedor-pagina-registro">
            <RegistroMedicos />
            
        </div>
    );
}

export default PaginaRegistroMedico;