import { useState } from "react";
import '../estilos/ventanaAvisoUnloged.css';
import { fetchData } from "../funcionesFront";
import VentanaResultado from "./ventanaResultado";
import moment from "moment";
import { addDays } from "date-fns";
import DatePicker from 'react-datepicker';

function VentanaCambioHorario (props){

    const [resultadoModificarDia, setResultadoModificarDia] = useState('');

    const [modalResultadoModificarDia, setModalResultadoModificarDia] = useState(false);

 
    const ModificarDia = (fecha, modificacion) => {

        const datos = {
          metodo: modificacion === 'retirardia' ? "POST" : "DELETE",
          peticion: `paginaReservacion/${modificacion}`,
          idElemento: 'boton-confirmar',
          datosReq: {
              ubicacion: props.ubicacion.id,
              idMedico: props.ubicacion.idMedico,
              fechas: [fecha]
          }
      }

      fetchData(datos, (resultado) => setResultadoModificarDia(resultado), () => setModalResultadoModificarDia(true));
      
      }

      

    return(
        props.isOpen ? 
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title">Cambio de Horario</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
                        </div>
                        
                        <div className="modal-body">
                         
                            <div className="cancelar-dia">
                                <p>Los cambios realizados a los horarios de la presente ubicación surtiran efecto en la fecha que usted escoja abajo, con el fin de preservar y mantener el orden de las citas programadas en el horario actual. Por favor, seleccione una fecha de las cuales se muestran a continuación: </p>
                            </div>

                            <DatePicker
                                selected={props.fechaCambioHorario}
                                onChange={(date) => props.setFechaCambioHorario(date)}
                                minDate={addDays(new Date(), props.proyeccionCitas + 1)}
                                placeholderText={`Seleccione una fecha`}
                                dateFormat={'dd/MM/yyyy'}
                                
                            />
                        
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-success" id="boton-confirmar"  onClick={() => props.guardarCambiosProgramado()}>Confirmar</button>
                            <button className="btn btn-danger" onClick={props.onClose}>Cancelar</button>
                        </div>
                    </div>
                        <VentanaResultado isOpen={modalResultadoModificarDia} onClose={() => setModalResultadoModificarDia(false)} cerrarVentanaConfirmacion={() => props.onClose()} data={resultadoModificarDia}/>
                </div>
            </div> 
        : (null)
    );
}

export default VentanaCambioHorario;