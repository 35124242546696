import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../estilos/calendario.css'
import { addDays, isBefore, getDay } from 'date-fns';
import moment from 'moment';
import es from 'date-fns/locale/es';

const CustomDatePicker = (props) => {
  const today = new Date();
  
    const actualizarFechasSeleccionadas = (date) => {
      var auxSelectedDates = props.selectedDates;
      auxSelectedDates = auxSelectedDates.map(date => date.toString()); //esto se hace para poder comparar las fechas, de lo contrario no se puede
      if (auxSelectedDates.includes(String(date))) {
        props.setSelectedDates(prevSelectedDates => prevSelectedDates.filter(d => String(d) !== String(date))); //elimina la fecha marcada, cuando se hace clic en una fecha ya marcada
      } else {
        props.setSelectedDates(prevSelectedDates => [...prevSelectedDates, date]);
      }
    

    }

    const handleChangeLiberarDia = (date) => {
      
      if(!isDayDisabled(date)){
        actualizarFechasSeleccionadas(date);
      }
    }
    

const isDayDisabled = (date) => {

  const formattedDate = moment(date).format('YYYY-MM-DD');
  if(props.diasCancelados.includes(formattedDate)){
    return false;
  }else {
    return props.isDayDisabled.includes(getDay(date));
  
  }
};


  return (
    <div>
      <h2>{props.mode === 'retirardia' ? 'Seleccionar fechas' : 'Calendario de fechas'}</h2>
      {props.mode === 'retirardia' ? (
      <DatePicker
        selected={null}
        onChange={(date) => actualizarFechasSeleccionadas(date)}
        inline
        minDate={today}
        filterDate={isDayDisabled}
        highlightDates={props.selectedDates}
        showMonthDropdown={true}
        locale={es}
      />
      )
      :
      (
      <DatePicker
        selected={null}
        inline
        minDate={today}
        filterDate={date => props.isDayDisabled.includes(getDay(date))}
        dayClassName={(date) => {
          if (!isDayDisabled(date) && props.isDayDisabled.includes(getDay(date))) {
            return "custom-datepicker__day--non-working"; // Clase para días no laborables
          }
        }}
        onChange={(date) => handleChangeLiberarDia(date)}
        highlightDates={props.selectedDates}
        className="custom-datepicker"
        showMonthDropdown={true}
        locale={es}
      />
        
      )
    }
    </div>
  );
};

export default CustomDatePicker;
