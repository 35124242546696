import { useState } from "react";
import { determinarDias } from "../funcionesFront";
import VentanaResultado from "./ventanaResultado";
import OpcionSeleccionarHorarios from "./OpcionSeleccionarHorarios";
import { fetchData} from "../funcionesFront";
import moment from "moment";


function VentanaAgregarDia (props) {


    const [modalResultado, setModalResultado] = useState(false);
    const [resultado, setResultado] = useState('');

    const [fechaSeleccionada, setFechaSeleccionada] = useState("Dias Disponibles");

    const opciones=["Horario Normal", "Seleccionar Horarios"];
    const [horarioSeleccionado, setHorarioSeleccionado] = useState('');
    
    //se le mandan como prop a opcionseleccionarhorario para que tengan el estado actualizado tanto padre como hijo
    const [selectedCheckboxes, setSelectedCheckboxes] =useState([]);

    const [checkEnviar, setCheckEnviar] = useState(false);

    const obtenerDiasNoTrabajados = () => {
       
        const proyeccionDiasdeTrabajo = determinarDias(props.ubicacion.proyeccionCitas, props.datos.diasCancelados);
        
        var diasNoLaborables = [];

        diasNoLaborables = proyeccionDiasdeTrabajo.filter((dia) => !props.ubicacion.diasDeTrabajo.includes(moment(dia).format("dddd, D \\d\\e MMMM \\d\\e YYYY").substring(0,2)) && !props.diasEspeciales.includes(dia));
        return(diasNoLaborables);
        
    }

    const handleOpcionChange = (opcion) => {

        setHorarioSeleccionado(opcion);
        opcion === "Horario Normal" ? setSelectedCheckboxes(props.citasNormales) : setSelectedCheckboxes([]);

    }

    const enviar = () => {

        if (fechaSeleccionada !== "Dias Disponibles" && selectedCheckboxes.length>0){
            const datos = {
                metodo: "POST",
                peticion: `paginaReservacion/agregardia/${props.datos.id}`,
                idElemento: 'boton-submit-agregar-dia',
                datosReq: {
                    ubicacion: props.ubicacion.id,
                    idMedico: props.idMedico,
                    fecha: fechaSeleccionada,
                    horas: selectedCheckboxes
                }
            }
            fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
            setFechaSeleccionada("Dias Disponibles");
            setSelectedCheckboxes([]);
        }else {
            setResultado({
                success: false,
                message: "Debe suministrar todos los datos"
            });
            setModalResultado(true);
        }
        
    }
    
    const diasNoLaborables = obtenerDiasNoTrabajados();
      

    return(
        props.isOpen ? (
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Agregar día de trabajo</h5>
                        </div>
                        
                        <div className="modal-body">
                            {diasNoLaborables.length > 0 ? (

                                <span className="contenedor-datos">
                                <div className="foto-container">
                                    
                                </div>
                                <div className="contenedor-fecha-hora">
                                    Fecha: 

                                    <div className="dropdown">
                                        <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {fechaSeleccionada !== 'Dias Disponibles' ? moment(fechaSeleccionada).format("dddd, D \\d\\e MMMM \\d\\e YYYY").replace(/^\w/, (c) => c.toUpperCase()) : 'Dias Disponibles'}
                                        </button>
                                        <ul className="dropdown-menu menu-fecha">
                                            {diasNoLaborables.map((dia) => 
                                            <li key={dia}><a className="dropdown-item dias-no-laborables" onClick={() => setFechaSeleccionada(dia)}>{moment(dia).format("dddd, D \\d\\e MMMM \\d\\e YYYY").replace(/^\w/, (c) => c.toUpperCase())}</a></li>
                                            )}
                                        </ul>
                                    </div>

                                    
                                    Horas de las citas: 

                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                        {opciones.map((opcion) => (
                                            <span key={opcion}>
                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off"  checked={opcion===horarioSeleccionado} />
                                                <label className="btn btn-outline-primary" htmlFor="btnradio1" onClick={() => handleOpcionChange(opcion)}>{opcion}</label>
                                            </span>
                                        ))}

                                    </div>

                                    <div className="contenedor-ventana-seleccion-horarios">

                                        <OpcionSeleccionarHorarios isOpen={horarioSeleccionado} citas={props.citasNormales} selectedCheckboxes={selectedCheckboxes} setSelectedCheckboxes={(checkboxs) => setSelectedCheckboxes(checkboxs)}/>
                                    </div>


                                                

                                    
                                </div>
                                </span>


                                ) :
                                (
                                <span>
                                    <p>No existen más días laborables dentro de la proyección proporcionada</p>
                                </span>)

                                }
                        </div>
                        <div className="modal-footer">
                                
                                
                                {diasNoLaborables.length > 0 ? 
                                    <button type="button" id="boton-submit-agregar-dia" className="btn btn-primary boton-info-liberar" onClick={() => enviar()}>Agregar Día</button>
                                :
                                null
                                }
                                <button type="button" className="btn btn-secondary boton-info-cerrar" onClick={props.onClose}>Cancelar</button>

                                <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => props.onClose()} data={resultado}/>
                            
                        </div>
                    </div>
                </div>
        </div>
        
        ) : null
    );


}

export default VentanaAgregarDia;