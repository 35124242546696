import React, { useState } from 'react';
import '../estilos/timePicker.css'

const TimePicker = (props) => {
  

  const handleDepartureTimeChange = (event) => {
    setDepartureTime(event.target.value);
  };

  const handleArrivalTimeChange = (event) => {
    setArrivalTime(event.target.value);
  };

  return (
    <div >
      <label style={{fontSize: '3vh'}}>{props.titulo}</label>
      <input
        className='timePicker'
        type="time"
        value={props.hora}
        onChange={props.handleChange}
        
      />
        <p></p>
      
      
    </div>
  );
};

export default TimePicker;
