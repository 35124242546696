import { useEffect, useState } from "react";
import IconosContrasena from "../iconos/iconosContrasena";
import '../estilos/crearContrasena.css';
import BotonVisibilidadContrasena from "./botonVisibilidadContrasena";

function CrearContrasena (props) {

    const [formData, setFormData] = useState({  //estado de los valores de la contra 
        newPassword: '',
        confirmNewPassword: '',
    });

    const [checkLongitud, setCheckLongitud] = useState(false);
    const [checkMayus, setCheckMayus] = useState(false);
    const [checkCaracterEspecial, setCheckCaracterEspecial] = useState(false);
    const [checkCoincidencia, setCheckCoincidencia] = useState(false);

    const [passwordIsVisible, setPasswordIsVisible] = useState(false);


    const handleChange = (event) => {     
        const {name, value} = event.target;

        setFormData(prevState => ({
            ...prevState, 
            [name]: value,
        }));


        if(name === 'newPassword') {

             value.length>=8 ? setCheckLongitud(true) : setCheckLongitud(false);

            /[A-Z]/.test(value) ? setCheckMayus(true) : setCheckMayus(false);

            /[0-9]/.test(value) && /[^a-zA-Z0-9]/.test(value) ? setCheckCaracterEspecial(true) : setCheckCaracterEspecial(false);

            value === formData.confirmNewPassword && value!='' ? setCheckCoincidencia(true) : setCheckCoincidencia(false);
        }else {
            formData.newPassword === value && value !='' ? setCheckCoincidencia(true) : setCheckCoincidencia(false);
        }
    };

    useEffect(() => {
    
        if(checkLongitud && checkMayus && checkCaracterEspecial && checkCoincidencia){
            
            props.onMessage(formData);
            
        }else {
            props.onMessage('');
            
        }
    }, [formData]);
    

    return (
        <div className="contenedor-crear-contrasena">
            <form>
                <br/>
                <br/>
                <label htmlFor="inputPassword5" className="form-label">Contraseña nueva</label>
                <input  type={passwordIsVisible ? "text" : "password"} id="new-password" className="form-control" aria-describedby="passwordHelpBlock" placeholder='Password' style={{border: `1px ${props.colorLogin} solid`}} name="newPassword" value={formData.newPassword} onChange={handleChange} autoComplete="new-password"/>
                <br/>
                <br/>
                <label htmlFor="inputPassword5" className="form-label">Confirmar contraseña nueva</label>
                <input type={passwordIsVisible ? "text" : "password"} id="confirm-new-password" className="form-control" aria-describedby="passwordHelpBlock" placeholder='Password' style={{border: `1px ${props.colorLogin} solid`}} name="confirmNewPassword" value={formData.confirmNewPassword} onChange={handleChange} autoComplete="new-password"/>
                <BotonVisibilidadContrasena passwordIsVisible={passwordIsVisible} setPasswordIsVisible={setPasswordIsVisible}/>
                <br/>
                <br/>
                <p style={{fontWeight: 'bold'}}>Requisitos de la nueva contraseña</p>
                <span className='requisitos-contrasena'>
                    
                    <p>
                        <IconosContrasena check={checkLongitud}/>
                        {" Longitud mayor o igual a 8 caracteres"}

                    </p>

                    <p>
                        <IconosContrasena check={checkMayus}/>
                        {" Al menos 1 letra mayúscula"}

                    </p>

                    <p>
                        <IconosContrasena check={checkCaracterEspecial}/>
                        {" Al menos 1 número y 1 carácter especial"}

                    </p>

                    <p>
                        <IconosContrasena check={checkCoincidencia}/>
                        {" Las contraseñas coinciden"}

                    </p>

                </span>
            </form>
        </div>
    );

};

export default CrearContrasena;

