import '../estilos/paginaGM.css';
import DatosMedico from './datosMedico';
import { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import {determinarCitas, determinarDias, obtenerImagen, obtenerDatosUsuario} from '../funcionesFront.js';
import { useParams } from 'react-router-dom';
import { cambiarTitulo } from '../funcionesFront.js';
import Cookies from 'js-cookie';
import CirculoGirador from '../iconos/circuloGirador';
import MedicoNoDisponible from './medicoNoDisponible';
import AvisoPago from './avisoPago';
import { da } from 'date-fns/locale';
import ItemGM from './itemGM.js';
import BuscadorMedico from './buscadorPaginaGM.js';
import apiUrl from '../url.js';


function PaginaGM() {

  const navigate = useNavigate();
  
  const {idMedico} = useParams();

  var datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

  //data o respuesta que se recibe del servidor para actualizar el estado y renderizar de nuevo
  const [data, setData] = useState('');
  const [info, setInfo] = useState('');

  cambiarTitulo(document, 'Guía Médica');  
  
  useEffect(() => {
    fetch(apiUrl + `/api/datosPaginaReservacion/paginaGM/datos`, {
      method: "GET", 
      headers: {
          'Content-Type': 'application/json',
      },
          
   })
    .then((res) => res.json())
    .then((data) => setData(data));
    
  }, []);

  useEffect(() => {
    setInfo(data);
  }, [data]);

  

return (

    <div className='contenedor-guia-medica'>

        <h1>Guía Médica</h1>

        <h3>Búsqueda Inteligente</h3>
        <BuscadorMedico />
        
        <div className="grid-container">

            {info ? (
            <>

            {data.map(medico => <span key={medico.id}><ItemGM data={medico}/></span>)}
                

            </>
            ) : (
            data === '' ? <CirculoGirador /> : datosUsuario.rol !== 'medicos' ? <MedicoNoDisponible /> : <AvisoPago />
            )}
            
        </div>

    </div>
  
);
}

export default PaginaGM;
