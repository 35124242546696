import { useEffect, useState } from "react";
import '../estilos/paginaRegistrarPago.css'
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";
import {convertirNumero, fetchData, revertirFormatoNumero, solicitarDatos} from '../funcionesFront'
import VentanaResultado from "./ventanaResultado";
import apiUrl from "../url";

function PaginaRegistrarPago (props) {

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

    const navigate = useNavigate();

    const metodoInicial = 'Seleccionar Método de Pago';

    const [resultado, setResultado] = useState('');
    const [modalResultado, setModalResultado] = useState(false);

    const [metodosDePago, setMetodosDePagos] = useState([]);

    const [metodoSeleccionado, setMetodoSeleccionado] = useState({metodo: metodoInicial});
    const [datosMetodoSeleccionado, setDatosMetodoSeleccionado] = useState([]);

    const [fecha, setFecha] = useState('');
    const [monto, setMonto] = useState('');
    const [referencia, setReferencia] = useState('');

    //useEffect(() => solicitarDatos(`https://citasfacil.com:8443/api/config/obtenerMetodosDePago`, 'GET', {}, setMetodosDePagos), []);

    useEffect(() => {
        fetch(apiUrl + `/api/config/obtenerMetodosDePago`, {
          method: "GET", 
          headers: {
            'Content-Type': 'application/json',
            'auth': Cookies.get('authToken'),
            'datosUsuario': Cookies.get('datosUsuario')
              
          },
              
       })
        .then((res) => res.json())
        .then((data) => setMetodosDePagos(data));
        
      }, []);


      const enviar = async () => {
        if(metodoSeleccionado.metodo !== metodoInicial && fecha !== '' && monto !== "" && referencia !== ''){
            const datos = {
                metodo:  "POST",
                peticion: `config/registrarPago/${datosUsuario.id}`,
                idElemento: 'boton-registrar-pago',
                datosReq: {
                        metodoDePago: metodoSeleccionado.metodo,
                        fecha: fecha,
                        monto: revertirFormatoNumero(monto),
                        referencia, referencia
                }
        }

        await fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
        
        }else {
            setResultado({
                success: false,
                message: 'Debe llenar todos los campos'
            });

            setModalResultado(true);
        }
    }

    const acciones = () => {
        setModalResultado(false);
        setResultado('');
    }


    return(

        datosUsuario.id !== '' ?
        <div className="contenedor-pagina-registrar-pago">

            <div className="contenedor-titulo-registro" >
                <h1>Registro de Pagos</h1>
            </div>

            <div className="contenedor-metodos-de-pago">
                <div>
                    <h3>Método de Pago</h3>
                    <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {metodoSeleccionado.metodo}
                    </button>
                    <ul className="dropdown-menu menu-metodos-pago">
                        {metodosDePago.map((metodo, index) => 
                            <li key={metodo}><a className="dropdown-item metodo-pago" onClick={() => setMetodoSeleccionado({metodo: metodo.tipo, index: index})}>{metodo.tipo}</a></li>
                        )}
                    </ul>
                </div>
                
                <div className={`contenedor-datos-${metodoSeleccionado.metodo !== metodoInicial ? "true" : 'false'}`}>
                    {metodoSeleccionado.metodo !== metodoInicial ?
                        
                        metodosDePago[metodoSeleccionado.index].datos.split(',').map(dato => <p key={dato}>{dato}</p>) 
                        :
                        null
                    }
                </div>

            </div>

            <div className="datos-del-pago">
                
                <label htmlFor="exampleFormControlInput1" className="form-label" >Fecha</label>
                <input type="date" className="form-control" id="exampleFormControlInput1" value={fecha} onChange={(event) => setFecha(event.target.value)} max={moment().format('YYYY-MM-DD')}/>

                <label htmlFor="exampleFormControlInput1" className="form-label" >Monto</label>
                <input type="numeric" className="form-control" id="exampleFormControlInput1" placeholder='1052.24' name={'monto'} value={monto} onChange={(event) => setMonto((event.target.value).replace(/[^0-9.]/g, ''))} onFocus={monto !== '' ? () => setMonto(revertirFormatoNumero(monto)) : null} onBlur={monto !== '' ? () => setMonto(convertirNumero(monto)) : null}/>

                <label htmlFor="exampleFormControlInput1" className="form-label" >Número de Referencia</label>
                <input type="numeric" className="form-control" id="exampleFormControlInput1" placeholder='123456789' name={'monto'} value={referencia} onChange={(event) => setReferencia((event.target.value).replace(/[^0-9]/g, ''))} />



            </div>

            <button className="btn btn-success boton-guardar-cambios" id='boton-registrar-pago' onClick={() => enviar()}>Registrar Pago</button>
            <button className="btn btn-danger" onClick={() => navigate('/admdecuenta')}>Cancelar</button>
            <VentanaResultado isOpen={modalResultado} onClose={() => acciones()} cerrarVentanaConfirmacion={() => navigate('/admdecuenta')} data={resultado}/>
        </div>

        : 

        navigate('/')
    );
}

export default PaginaRegistrarPago;