import React from 'react';
import '../estilos/numberInput.css'

const NumberInput = ({proyeccionCitas: number, handleChange: setNumber}) => {
  

  const handleIncrement = () => {
    
    if (number < 120) {
      setNumber(number + 1);
    }
    
  };

  const handleDecrement = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  const handleChange = (event) => {
    const {value} = event.target;
    console.log(value)
    setNumber(prevState => Number(value > 0 && value <=120 ? value : prevState));
  }

  return (
    <div>
      <button className='btn btn-danger botones-mas-y-menos' onClick={handleDecrement}>-</button>
      <input
        className='input-proyeccion-citas'
        type="number"
        value={number}
        min={1}
        max={120}
        
        onChange={(event) => handleChange(event)}
      />
      <button className='btn btn-success botones-mas-y-menos' onClick={handleIncrement}>+</button>
    </div>
  );
};

export default NumberInput;
