import '../estilos/paginaAccesoDenegado.css'

function PaginaNoEncontrada (){
    return (
        <div className="contenedor-acceso-denegado">
            <h1 >Error 404: Not Found</h1>
            <h3>La pagina que solicitó no existe o no está disponible</h3>
        </div>
    );
}

export default PaginaNoEncontrada;