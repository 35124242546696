import { useState} from "react";
import { fetchData, validarEmail } from "../funcionesFront";
import VentanaResultado from "./ventanaResultado";
import Cookies from "js-cookie";
import VentanaIntroducirCodigo from "./ventanaIntroducirCodigo";


function OpcionCambiarCorreo (props) {

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

    const [resultado, setResultado] = useState('');
    const [modalIntroducirCodigo, setModalIntroducirCodigo] = useState(false);

    const [modalResultado, setModalResultado] = useState(false);

    const [data, setData] = useState('');
    const [confirmarCorreo, setConfirmarCorreo] = useState(''); //para verificar que los correos coinciden
    const [checkEmail, setCheckEmail] = useState(true);
    const [colorInput, setColorInput] = useState('#999');
    const [mensajeCorreoInvalido, setMensajeCorreoInvalido] = useState('');

    const handleEmailChange = (event) => {
        setData(event.target.value);
        validarEmail(event, setCheckEmail);
    };
    
	const guardarCambios = () => {

        if(checkEmail){

            if(data === confirmarCorreo){

                setColorInput('#999');
                setMensajeCorreoInvalido('');

                const datos = {
                    metodo:  "POST",
                    peticion: `config/solicitarCambioCorreo`,
                    idElemento: 'boton-guardar-cambios',
                    datosReq: {

                            id: datosUsuario.id,
                            rol: datosUsuario.rol,
                            correo: data       
                    }
                }

                fetchData(datos, (resultado) => setResultado(resultado), () => setModalIntroducirCodigo(true));
            }else{
                setColorInput('red');
                setMensajeCorreoInvalido('Los Correos Electrónicos no coinciden');
            }

            
	    }else{
            setColorInput('red');
            setMensajeCorreoInvalido('Correo Electrónico no válido');
        }
    }

    const estilos = {display: 'flex', flexDirection: 'column', alignItems: 'center'};

    
    return(
        <div className="contenedor-pagina-config">
        <div className="contenedor-cambio-email row" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <p style={{fontWeight: 'bold'}}>Correo Electrónico nuevo</p>
                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="juan@ejemplo.com" value={data} onChange={(event) => handleEmailChange(event)} style={{borderColor: colorInput, maxWidth: '20rem', textAlign: 'center'}}/>
                <br/>
                <p style={{fontWeight: 'bold'}}>Confirmar Correo Electrónico</p>
                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="juan@ejemplo.com" value={confirmarCorreo} onChange={(event) => setConfirmarCorreo(event.target.value)} style={{borderColor: colorInput, maxWidth: '20rem', textAlign: 'center'}}/>
                <br/>
                <p style={{color: 'red'}}>{mensajeCorreoInvalido}</p>
            </div>

        </div>

            <div className="row" style={estilos}>
                <button className="btn btn-success boton-guardar-cambios" id='boton-guardar-cambios' onClick={() => guardarCambios()}>Guardar Cambios</button>
                <button className="btn btn-danger boton-cancelar" onClick={props.cancelar}>Cancelar</button>
            </div>
    
            <VentanaIntroducirCodigo isOpen={modalIntroducirCodigo} onClose={() => setModalIntroducirCodigo(false)} cerrarVentanaConfirmacion={props.cancelar} resultado={resultado} correo={data} peticion={'config/cambiarCorreo'}/>
            
        
        </div>
    );
}

export default OpcionCambiarCorreo;