import IconoOjoNormal from '../iconos/iconoOjoNormal';
import IconoOjoSlash from '../iconos/iconoOjoSlash';

function BotonVisibilidadContrasena (props) {

    const handleCambioVisibilidad = (event) => {
        event.preventDefault();
        props.setPasswordIsVisible(!props.passwordIsVisible);

    }
    return (
        <button type='button' onClick={handleCambioVisibilidad} style={{backgroundColor: "transparent", borderColor: 'transparent', color: "black"}}>{props.passwordIsVisible ? <IconoOjoSlash/> : <IconoOjoNormal/>}</button>
    );
}

export default BotonVisibilidadContrasena;