import React, { useState, useEffect, useRef } from 'react';
import '../estilos/seccionFAQS.css'; // Verifique que este archivo CSS está presente en su proyecto

const SeccionFAQS = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  useEffect(() => {
    contentRefs.current = contentRefs.current.slice(0, questionsAnswers.length);
  }, []);

  const questionsAnswers = [
    { question: '¿Qué servicios ofrecemos?', answer: 'Ofrecemos una herramienta que servirá tanto a médicos como a pacientes para poder reservar cómodamente las citas médicas mediante un sistema de administración, de forma que los pacientes no tengan que esperar largos ratos y momentos incómodos y los profesionales de la salud tengan el control total sobre el manejo de citas. CitasFácil te hace mucha más comoda tu experiencia médica. Además, la app posee su sección de directorio médico para elevar el alcance los profesionales que hagan uso de la misma.' },
    { question: '¿Cómo nace CitasFácil? ', answer: 'CitasFácil nace de la necesidad de los pacientes de poder agendar citas médicas sin contratiempos y de forma sencilla, optimizándolas hasta tal punto que solo bastan unos pocos segundos para obtener una cita en un tiempo y lugar específicos, y evitarles cualquier angustia que pueda surgir al momento de ello. Al mismo tiempo, nace también de la necesidad de los médicos de poder organizar la forma en que administran y establecen las citas a sus pacientes.'},
    { question: '¿Cual es la proyección de CitasFácil en el futuro?', answer: 'Consolidarse en el mercado como la herramienta en línea líder para la administración de citas médicas y proporcionar nuevas herramientas, de modo que faciliten el proceso tanto a médicos como pacientes.'},
    { question: '¿Es necesario tener una cuenta para usar usar la app?', answer: 'Si, tanto médicos como pacientes deberán poseer una cuenta en la app para poder usarla. Esto se hace con el fin de tener un mejor control y uso de la misma. Sin embargo, ¡no hay de que preocuparse! El registro en la app es sumamente sencillo' },
    { question: '¿Cómo puedo registrarme en el sitio?', answer: 'Puedes registrarte haciendo clic en el botón "Registrarse" ubicado en la parte superior de la página, ó directamente haciendo clic <a href="/registro/medicos">si eres médico</a> o <a href="/registro/pacientes">si eres paciente</a>.' },
    { question: '¿A quién esta dirigida la app?', answer: 'La app esta dirigida a todos aquellos médicos, odontólogos, centros de diagnóstico y todo aquellos servicos involucrados con la salud que requieran tener una mejor organización con el manejo de las citas a sus pacientes, mejorarando la experiencia de ellos y la de los profesionales de la salud que ofrecen sus servicios. Además de ello, la app tambien servirá como guía o directorio médico para elevar el alcance y poder llegar a más pacientes' },
    { question: '¿El uso de la app tiene algún costo?', answer: 'Los profesionales de la salud podrán adquirir los servicios de administración de citas y/o publicitación a través de la guía ó directorio médico mediante una suscripción mensual, cuyos precios se muestran en la pagina de <a href="/registro/medicos">registro</a>. Por parte de los pacientes, el uso de la app es totalmente gratuito'},
    { question: 'Prueba Gratuita', answer: 'Los profesionales de la salud que se registren en la app, disfrutarán de un mes de prueba totalmente gratuito'},
    { question: '¿Cómo reservar una cita si soy un paciente?', answer: 'Si ya estas registrado en la app (puedes <a href="/registro/pacientes">registrarte aquí</a>), simplemente busca el profesional de la salud con el que quiera reservar una cita, lo cual puede hacer a través del buscador o del directorio médico. Cabe destacar que existe la posibilidad que no todos los profesionales registrados en la app tengan contratado el servicio de administración de citas, por lo que en este caso deberá comunicarse directamente con el para gestionar una cita' },
    // Añade más preguntas y respuestas según sea necesario
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="faq-section">
      <h2 className="titulo-faqs">Preguntas Frecuentes</h2>
      <div className="faq-container">
        {questionsAnswers.map((qa, index) => (
          <div className={`faq ${index === activeIndex ? 'active' : ''}`} key={index}>
            <h3 className="faq-question" onClick={() => toggleAccordion(index)}>
              {qa.question}
              <span className="arrow">{index === activeIndex ? '▲' : '▼'}</span>
            </h3>
            <div className="faq-answer"
                  ref={el => contentRefs.current[index] = el}
                  style={{ maxHeight: index === activeIndex ? `${contentRefs.current[index]?.scrollHeight}px` : '0px' }}
            >
              <p dangerouslySetInnerHTML={{ __html: qa.answer }}></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeccionFAQS;
