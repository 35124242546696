import { cambiarTitulo, obtenerImagen } from "../funcionesFront";
import { useEffect, useState } from "react";
import { fetchData} from "../funcionesFront";
import '../estilos/registroMedico.css'
//import '../estilos/paginaConfiguracion.css'
import ConfigPersonal from "./configPersonal";
import ConfigHorarios from "./configHorarios";
import Cookies from "js-cookie";
import AvisoPago from "./avisoPago";
import CirculoGirador from "../iconos/circuloGirador";
import OpcionCambiarCorreo from "./opcionCambiarCorreo";
import apiUrl from "../url";
//import OpcionCambiarTelefono from "./opcionCambiarTelefono";

function PaginaConfiguracionMedicos (props) {

    cambiarTitulo(document, "Configuración");

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

    const [configSeleccionada, setConfigSeleccionada] = useState('');

    const [data, setData] = useState('');

    const [img, setImg] = useState();

    const [fechaSeleccionada, setFechaSeleccionada] = useState("Dias Disponibles");

    const opciones={
        "Config. Personal": <ConfigPersonal data={data} cancelar={() => setConfigSeleccionada('')} img={img}/>,
        "Config. Horarios": <ConfigHorarios data={data} cancelar={() => setConfigSeleccionada('')} img={img}/>,
        "Cambiar Correo Electrónico" : <OpcionCambiarCorreo cancelar={() => setConfigSeleccionada('')} />,
        //"Cambiar Número Telefónico" : <OpcionCambiarTelefono cancelar={() => setConfigSeleccionada('')} />
    };

    
    //se le mandan como prop a opcionseleccionarhorario para que tengan el estado actualizado tanto padre como hijo
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const handleOpcionChange = (opcion) => {

        setConfigSeleccionada(opcion);

    }

    const enviar = () => {

        if (fechaSeleccionada !== "Dias Disponibles" && selectedCheckboxes.length>0){
            const datos = {
                metodo: "POST",
                peticion: `paginaReservacion/agregardia/${props.datos.id}`,
                idElemento: 'boton-submit-agregar-dia',
                datosReq: {
                    idMedico: props.idMedico,
                    fecha: fechaSeleccionada,
                    horas: selectedCheckboxes
                }
            }
            fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
            setFechaSeleccionada("Dias Disponibles");
            setSelectedCheckboxes([]);
        }else {
            setResultado({
                success: false,
                message: "Debe suministrar todos los datos"
            });
            setModalResultado(true);
        }
        
    }

    useEffect(() => {
        fetch(apiUrl + `/api/datosPaginaReservacion/infoConfigMedico/${datosUsuario.id}`, {
            method: "GET", 
            headers: {
                'Content-Type': 'application/json',
                datos: {
                  tabla: datosUsuario.rol,
                  id: datosUsuario.id
                },
            },
                
         })
         .then((res) => res.json())
         .then((data) => {
            setData(data)
            obtenerImagen(datosUsuario.rol, data.id, (img) => setImg(img));
        });
    }, [configSeleccionada])

    return(

        datosUsuario.medicoActivo.medicoActivo && datosUsuario.rol === 'medicos' ?

        data !== '' ?
        (<div className="contenedor-configuracion" >

            <div className="contenedor-titulo">
                <h1 className="config-title">Configuración</h1>
            </div>

            

            <div className="btn-group contenedor-botones-seleccion-config" role="group" aria-label="Basic radio toggle button group">
                {Object.keys(opciones).map((opcion) => (
                    <span className="boton-seleccion-config" key={opcion}>
                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off"  checked={opcion===configSeleccionada} />
                        <label className="btn btn-outline-primary botones-seleccionar-config" htmlFor="btnradio1" onClick={() => handleOpcionChange(opcion)}>{opcion}</label>
                    </span>
                ))}

            </div>

            <div>
            
            { opciones[configSeleccionada] }

            </div>
        </div>) 

        :
        <div className="contenedor-circulo-girador">

            <CirculoGirador />

        </div>
        
        :

        <AvisoPago />
        
    );


}

export default PaginaConfiguracionMedicos;