import '../estilos/ventanaAvisoUnloged.css'
import { useNavigate, useParams } from 'react-router-dom';
import SimboloExclamacion from '../iconos/simboloExclamacion';
import { useState } from 'react';
import VentanaReservarInvitado from './ventanaReservarInvitado';

function VentanaAvisoUnloged (props) {

    const {idMedico} = useParams();

    const [modalReservarInvitadoIsOpen , setModalReservarInvitadoIsOpen] = useState(false);

    const navigate = useNavigate();

    return(
        props.isOpen ? (
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Unloged</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
                        </div>
                        
                        <div className="modal-body">
                            <SimboloExclamacion />
                            <br/>
                            <br/>
                            <p>Para realizar la reservación de la cita, debe iniciar sesión en su cuenta. Si no posee una cuenta, puede crearla fácilmente sin ningún costo ó simplemente puede reservar como invitado haciendo click el botón de abajo.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary boton-aviso-iniciar-sesion" onClick={() => navigate(`/login/pacientes/${idMedico}`)}>Iniciar Sesión</button>
                            <button type='button' className='btn btn-secondary' onClick={() => setModalReservarInvitadoIsOpen(true)}>Reservar como invitado</button>
                            <button type="button" className="btn btn-danger boton-aviso-cerrar" data-bs-dismiss="modal" onClick={props.onClose}>Cerrar</button>  
                        </div>
                        <VentanaReservarInvitado isOpen={modalReservarInvitadoIsOpen} onClose={() => setModalReservarInvitadoIsOpen(false)} data={props.data}/>
                    </div>
                </div>
        </div>
        
        ) : null
    );
}

export default VentanaAvisoUnloged;