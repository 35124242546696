import ProductCard from "./card";


function PaginaServicios () {

    const products = [
        {
          name: 'Administración de Citas',
          description: "Servicio de Administración de Citas de forma online",
          price: "15,00",
          features: ['Administración de Citas, de forma online, donde sea y cuando sea.', 'Altamente personalizable, en función de los dias laborales, horas de trabajo y descansos diarios.', "Notificaciones vía Correo Electrónico", 'Guía Médica', "Atención al cliente"],
        },
        {
          name: 'Guía Médica',
          description: "Servicio de publicitación a través de la Guía Médica (Directorio Médico)",
          price: "10,00",
          features: ['Guía Médica', "Atención al cliente"],
        },
        // Añade más productos según sea necesario
      ];

    return(
        <div>
            <h1 style={{marginBottom: '0vh'}}>Servicios Disponibles en CitasFácil</h1>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minHeight: '100vh' }}>
                
                {products.map((product, index) => (
                    <ProductCard
                    key={index}
                    name={product.name}
                    description={product.description}
                    price={product.price}
                    features={product.features}
                    />
                ))}
            </div>
        </div>
    )
}

export default PaginaServicios;

