import '../estilos/paginaGM_infoMedico.css';
import '../estilos/tabla.css';
import DatosMedico from './datosMedico';
import { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { cambiarTitulo } from '../funcionesFront.js';
import Cookies from 'js-cookie';
import CirculoGirador from '../iconos/circuloGirador';
import MedicoNoDisponible from './medicoNoDisponible';
import AvisoPago from './avisoPago';
import apiUrl from '../url.js';



function PaginaGM_infoMedico() {

  const navigate = useNavigate();
  
  const {idMedico} = useParams();

  var datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

  //data o respuesta que se recibe del servidor para actualizar el estado y renderizar de nuevo
  const [data, setData] = useState('');
  const [info, setInfo] = useState('');
  var tituloPagina = datosUsuario.rol === "medicos" ? "Administración de citas" : `Reservación | ${data.sexo === 'Masculino' ? "Dr. " : "Dra. "} ${data.apellidos}`;
  cambiarTitulo(document, tituloPagina);  
  
  useEffect(() => {
    fetch(apiUrl + `/api/datosPaginaReservacion/infoMedico/${idMedico}`, {
      method: "GET", 
      headers: {
          'Content-Type': 'application/json',
      },
          
   })
    .then((res) => res.json())
    .then((data) => setData(data));
    
  }, []);

  useEffect(() => {
    setInfo(data);
  }, [data]);

  const cabezas = ["Dirección", "Ciudad", "Estado", "Dias de trabajo", "Horario", "Descanso Diario"];

  const estilos = {
    tabla: {
      width: '100%',
      borderCollapse: 'collapse',
      backgroundColor: '#f8f9fa',
    },
    fila: {
      borderBottom: '1px solid #dee2e6',
    },
    celda: {
      padding: '20px',
      textAlign: 'left',
      fontSize: '16px',
      color: '#212529',
    },
    primeraCelda: {
      width: '20%',
      padding: '20px',
      textAlign: 'left',
      fontSize: '16px',
      color: '#212529',
      borderRight: '2px solid #dee2e6', // Añadiendo un borde derecho para separar
    },
  };

  const posicionesEnObjeto = {
    "0": "direccion",
    "1": "ciudad",
    "2": "estado",
    "3": "diasDeTrabajo",
    "4": 'horarioNormal',
    "5": 'descansoDiario',

  };

 
return (
  
  <div className="App">
    
    {info ? (
      <>
        <DatosMedico data={data}/>

        <h2>Datos Generales</h2>

        {info.ubicaciones.map((ubicacion, index) => (

            
            <table style={estilos.tabla}>
            <h3 style={{textAlign: 'center', fontSize: '2rem'}}>{info.ubicaciones.length > 1 ? `Ubicación #${index+1}` : null}</h3>
            <tbody>
                {cabezas.map((cabeza, index) => (
                <tr key={index} style={estilos.fila}>
                    <td style={estilos.primeraCelda}>
                    {cabeza}
                    </td>
                    <td style={estilos.celda}>
                    {ubicacion[posicionesEnObjeto[index]]}
                    </td>
                </tr>
                ))}
            </tbody>
        </table>
        
       
        
        ))}    

        

        {info.servicios.split(',')[0] == '1' ? 
        <span>
            <p></p>
            <p>{`¡Buenas Noticias! Puedes agendar una cita con ${info.sexo == 'Masculino' ? 'el Dr. ' : 'la Dra. '} ${info.apellidos} a través de CitasFacil`}</p>
            <button onClick={() => navigate(`/reservar/${idMedico}`)}>Agendar Cita</button>
            <p></p>
        </span>
        : 
        null
        }
        
        
      </>
    ) : (
      data === '' ? <CirculoGirador /> : datosUsuario.rol !== 'medicos' ? <MedicoNoDisponible /> : <AvisoPago />
    )}
    
  </div>
  
  
);
}

export default PaginaGM_infoMedico;
