import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  flex: 1;
  padding: 10px;
  cursor: pointer;
  background: ${props => (props.active ? '#007BFF' : '#FFF')};
  color: ${props => (props.active ? '#FFF' : '#000')};
  border: 1px solid #007BFF;
  border-radius: 4px;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background: ${props => (props.active ? '#0056b3' : '#e9ecef')};
  }
`;

const Content = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
`;

const TermsAndConditions = () => (
  <Content>
    <h2>Términos y Condiciones</h2>
    <p>Última actualización: 03/06/2024</p>
    <p>Bienvenido a CitasFácil. Estos Términos y Condiciones (en adelante, "Términos") rigen el uso del Sitio y los servicios ofrecidos a través del mismo (en adelante, "Servicios"). Al acceder o utilizar el Sitio y los Servicios, usted (en adelante, "el Usuario") acepta cumplir y estar sujeto a estos Términos. Si no está de acuerdo con estos Términos, no debe utilizar el Sitio ni los Servicios.</p>
    
    <h3>1. Introducción</h3>
    <p>CitasFácil ofrece servicios de administración de citas médicas y publicitación, permitiendo a los médicos gestionar sus citas, horarios y comunicación con los pacientes. Los médicos pueden suscribirse a diferentes planes de servicios y pagar las tarifas correspondientes para utilizar el Sitio y los Servicios.</p>

    <h3>2. Registro y Cuenta del Usuario</h3>
    <ul>
      <li><strong>Elegibilidad:</strong> Para utilizar el Sitio y los Servicios, en el caso de ser médico, debe ser un profesional médico autorizado y mayor de edad según las leyes.</li>
      <li><strong>Registro:</strong> Debe registrarse en el Sitio proporcionando información veraz y completa sobre usted y su práctica médica. Usted es responsable de mantener la confidencialidad de su cuenta y contraseña.</li>
      <li><strong>Verificación:</strong> Nos reservamos el derecho de verificar la información proporcionada y, en caso de incumplimiento, suspender o cancelar su cuenta.</li>
    </ul>

    <h3>3. Suscripción y Tarifas</h3>
    <ul>
      <li><strong>Planes de Suscripción:</strong> Ofrecemos varios planes de suscripción con diferentes características y tarifas. Puede consultar los detalles de cada plan en este <a href={'/servicios'}>link</a>.</li>
      <li><strong>Pago:</strong> Las tarifas de suscripción se cobran de manera periódica según el plan seleccionado. Usted autoriza a [Nombre del Sitio Web] a cargar automáticamente las tarifas.</li>
      <li><strong>Reembolsos:</strong> Las tarifas pagadas no son reembolsables.</li>
    </ul>

    <h3>4. Uso del Sitio y los Servicios</h3>
    <ul>
      <li><strong>Acceso y Uso:</strong> Puede utilizar el Sitio y los Servicios únicamente para fines legítimos y en conformidad con estos Términos y cualquier ley aplicable.</li>
      <li><strong>Conducta Prohibida:</strong> No debe utilizar el Sitio para actividades ilegales, perjudiciales, o que infrinjan los derechos de terceros. Está prohibido realizar cualquier acto que pueda dañar, deshabilitar o sobrecargar el Sitio.</li>
      <li><strong>Contenido del Usuario:</strong> Usted es responsable del contenido que cargue o transmita a través del Sitio. Nos reservamos el derecho de eliminar contenido que consideremos inapropiado o que infrinja estos Términos.</li>
    </ul>

    <h3>5. Propiedad Intelectual</h3>
    <p>Todos los derechos de propiedad intelectual sobre el Sitio y los Servicios, incluyendo software, texto, gráficos, logos y otros materiales, son propiedad de CitasFácil. Usted no tiene ningún derecho sobre dichos contenidos, excepto el derecho limitado de uso según estos Términos.</p>

    <h3>6. Privacidad</h3>
    <p>Su privacidad es importante para nosotros. Consulte nuestra Política de Privacidad para obtener información sobre cómo recopilamos, utilizamos y protegemos su información personal.</p>

    <h3>7. Limitación de Responsabilidad</h3>
    <p>CitasFácil no será responsable de ningún daño directo, indirecto, incidental, especial, consecuente o punitivo que surja del uso o la imposibilidad de uso del Sitio o los Servicios.</p>
    <p>CitasFácil no se hace responsable por incumplimiento en cuanto a la asistencia a la cita, tanto de parte del médico con el paciente y viceversa.</p>

    <h3>8. Indemnización</h3>
    <p>Usted acepta indemnizar y eximir de responsabilidad a CitasFácil, sus afiliados, empleados y agentes de cualquier reclamo, demanda, pérdida o daño, incluyendo honorarios razonables de abogados, que surjan de su uso del Sitio o los Servicios, su incumplimiento de estos Términos, o su violación de cualquier ley o derechos de terceros.</p>

    <h3>9. Modificaciones a los Términos</h3>
    <p>Nos reservamos el derecho de modificar estos Términos en cualquier momento. Le notificaremos sobre cualquier cambio publicando los nuevos Términos en el Sitio. El uso continuado del Sitio y los Servicios después de la publicación de los cambios constituye su aceptación de dichos cambios.</p>

    <h3>10. Terminación</h3>
    <p>Podemos suspender o cancelar su cuenta y acceso al Sitio y los Servicios en cualquier momento, sin previo aviso, si creemos que ha violado estos Términos o cualquier ley aplicable. Usted puede cancelar su cuenta en cualquier momento contactándonos a través de administracion@citasfacil.com</p>

    <h3>11. Ley Aplicable y Jurisdicción</h3>
    <p>Estos Términos se regirán e interpretarán de acuerdo con las leyes de la República Bolivariana de Venezuela. Usted acepta someterse a la jurisdicción exclusiva de los tribunales para resolver cualquier disputa que surja de estos Términos o del uso del Sitio o los Servicios.</p>

    <h3>12. Contacto</h3>
    <p>Si tiene alguna pregunta sobre estos Términos, puede contactarnos en:</p>
    <p>
      CitasFácil <br />
      administracion@citasfacil.com <br />
      0412-7443710
    </p>
  </Content>
);

const PrivacyPolicy = () => (
  <Content>
    <h2>Política de Privacidad</h2>
    <p>Última actualización: 03/06/2024</p>
    <p>Nos comprometemos a proteger su privacidad y garantizar que su información personal esté segura. Esta política de privacidad describe cómo recopilamos, usamos y compartimos su información personal cuando visita nuestro sitio web CitasFácil (en adelante, "el Sitio") y utiliza nuestros servicios.</p>
    
    <h3>1. Información que Recopilamos</h3>
    <ul>
      <li><strong>Información de Contacto:</strong> Como nombre, dirección de correo electrónico, número de teléfono y direcciones.</li>
      <li><strong>Información de Comunicación:</strong> Cualquier mensaje, consulta, comentario u otra comunicación que nos envíe.</li>
    </ul>

    <h3>2. Uso de la Información</h3>
    <ul>
      <li><strong>Proveer y Mejorar Nuestros Servicios:</strong> Utilizamos su información para operar, mantener y mejorar nuestros servicios.</li>
      <li><strong>Comunicación:</strong> Para enviarle información administrativa, actualizaciones sobre nuestros servicios, y responder a sus consultas.</li>
      <li><strong>Seguridad:</strong> Para proteger contra, identificar y prevenir el fraude y otras actividades ilegales.</li>
      <li><strong>Cumplimiento Legal:</strong> Para cumplir con nuestras obligaciones legales y reglamentarias.</li>
    </ul>

    
    <h3>3. Seguridad de la Información</h3>
    <p>Utilizamos medidas de seguridad razonables para proteger la información personal bajo nuestro control de pérdida, uso indebido y acceso no autorizado, divulgación, alteración y destrucción.</p>

    <h3>4. Derechos del Usuario</h3>
    <p>Dependiendo de su jurisdicción, puede tener ciertos derechos sobre su información personal, tales como el derecho a acceder, corregir o eliminar su información personal.</p>

    <h3>5. Cambios a esta Política de Privacidad</h3>
    <p>Podemos actualizar esta política de privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva política de privacidad en nuestro sitio y actualizando la fecha de "Última actualización" en la parte superior de esta política.</p>

    <h3>6. Contacto</h3>
    <p>Si tiene alguna pregunta sobre esta política de privacidad o sobre nuestras prácticas de privacidad, puede contactarnos en:</p>
    <p>
      CitasFácil <br />
      administracion@citasfacil.com <br />
      0412-7443710
    </p>
  </Content>
);

const LegalDocuments = () => {
  const [activeTab, setActiveTab] = useState('terms');

  return (
    <Container>
      <TabContainer>
        <Tab active={activeTab === 'terms'} onClick={() => setActiveTab('terms')}>
          Términos y Condiciones
        </Tab>
        <Tab active={activeTab === 'privacy'} onClick={() => setActiveTab('privacy')}>
          Política de Privacidad
        </Tab>
      </TabContainer>
      {activeTab === 'terms' ? <TermsAndConditions /> : <PrivacyPolicy />}
    </Container>
  );
};

export default LegalDocuments;
