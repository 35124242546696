import '../../estilos/ventanaAvisoUnloged.css'


function VentanaDetalles (props) {

    return(
        props.isOpen ? (
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Detalles</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
                        </div>
                        
                        <div className="modal-body">
                            <p>{props.detalles}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary boton-aviso-cerrar" data-bs-dismiss="modal" onClick={props.onClose}>Cerrar</button>  
                        </div>
                    </div>
                </div>
        </div>
        
        ) : null
    );
}

export default VentanaDetalles;