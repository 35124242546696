import { useParams } from 'react-router-dom';
import '../estilos/paginaBusqueda.css'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { cambiarTitulo, obtenerImagen, buscarEstadoAcento, buscarCiudadAcento } from '../funcionesFront';
import CirculoGirador from '../iconos/circuloGirador';
import apiUrl from '../url';
import IconoList from '../iconos/iconoList';
import SimboloExclamacion from '../iconos/simboloExclamacion.js';

function PaginaBusqueda (){

    const navigate = useNavigate();

    //obtencion de parametros URL
    const {busqueda} = useParams();
    
    cambiarTitulo(document, `Búsqueda: ${busqueda}`);

    //resultados finales de la busqueda para renderizar
    const [resultados, setResultados] = useState('');
    const [imagenes, setImagenes] = useState({});
    

    //codigo de respuesta de la solicitud http
    const [codigoRespuesta, setCodigoRespuesta] = useState(''); 

    //data o respuesta que se recibe del servidor para actualizar el estado y renderizar de nuevo
    const [data, setData] = useState(''); 

    
    useEffect(() => {
        fetch(apiUrl + `/api/search/${busqueda}`, {
            method: "POST", 
            headers: {
                'Content-Type': 'application/json'
            },
            
        })

        .then((res) => {
            setCodigoRespuesta(res.status);
            return res.json()
        })
        .then((data) => {
            
            
            for(let i=0;i<data.length;i++){
                obtenerImagen('medicos', data[i].id, (img) => setImagenes(prevState => ({
                    ...prevState,
                    [data[i].id]: img,
                })));

            }

            setData(data);
        })
        
    }, [busqueda]);

    useEffect(() => {
        if(data && codigoRespuesta==200){
            setResultados(data);
            
        }else{
            setResultados('');
        }
    }, [data]);

    const handleClickReservar = (id) => {
        navigate(`/reservar/${id}`);
    }

    const handleClickGuiaMedica = (id) => {
        navigate(`/guia-medica/infoMedico/${id}`);
    }

    return(

        
        <div className='contenedor-resultados'>
            

            {resultados!='' ? (
                <>
            
                {/* Aquí muestra los detalles de la persona */}
                
                {resultados.filter(i => i.servicios.split(',')[0] == 1).map((resultado, index) => (
                    <span>
                        {index == 0 ? (
                            <span>
                            <h2 style={{color: '#085ec5'}}>Guia Médica con Adm. de citas</h2>
                            <p></p>
                            </span>
                            ) :
                            null
                        }
                        <span className='contenedores-resultados' key={resultado.id}>

                            <div  className="resultado-item-adm-citas" onClick={() => handleClickReservar(resultado.id)} style={{textAlign: 'center'}}>
                                <div className='row'>
                                    <div  className='col foto'>
                                        <picture>
                                            <img className='foto-busqueda' src={imagenes[resultado.id]}></img>
                                        </picture>
                                    </div>
                                    <div className='col datos' >
                                        <p className='info-medico' style={{fontWeight: 'bold'}}>{`${resultado.sexo === 'Masculino' ? "Dr. " : "Dra. "}` + resultado.nombres} {resultado.apellidos}</p>
                                        <p className='info-medico'>{resultado.especialidad}</p>
                                        {resultado.ciudades.split(',').map((ciudad, index) => (
                                            <p className='info-medico'>{`${buscarCiudadAcento(resultado.estados.split(',')[index], ciudad)} - Edo. ${buscarEstadoAcento(resultado.estados.split(',')[index])}`}</p>
                                        ))}
                                        
                                        
                                    </div>
                                </div>
                            </div>
                            <br/>
                        </span>
                    </span>
                ))}

                {resultados.filter(i => i.servicios.split(',')[0] == 2).map((resultado, index) => (
                    <span>
                        {index == 0 ? (
                            <span>
                            <h2 style={{color: '#42ab49'}}>Guia Médica</h2>
                            <p></p>
                            </span>
                            ) :
                            null
                        }
                        <span className='contenedores-resultados' key={resultado.id}>

                            <div  className="resultado-item-guia-medica" onClick={() => handleClickGuiaMedica(resultado.id)}>
                                <div className='row'>
                                    <div  className='col foto'>
                                        <picture>
                                            <img className='foto-busqueda' src={imagenes[resultado.id]}></img>
                                        </picture>
                                    </div>
                                    <div className='col datos' >
                                        <p className='info-medico' style={{fontWeight: 'bold'}}>{`${resultado.sexo === 'Masculino' ? "Dr. " : "Dra. "}` + resultado.nombres} {resultado.apellidos}</p>
                                        <p className='info-medico'>{resultado.especialidad}</p>
                                        {resultado.ciudades.split(',').map((ciudad, index) => (
                                            <p className='info-medico'>{`${buscarCiudadAcento(resultado.estados.split(',')[index], ciudad)} - Edo. ${buscarEstadoAcento(resultado.estados.split(',')[index])}`}</p>
                                        ))}
                                        
                                    </div>
                                </div>
                            </div>
                            <br/>
                        </span>
                    </span>
                ))}

                
                </>
            ) : (

                data === '' ? 
                <CirculoGirador /> 
                : 
                (
                    <span style={{textAlign: 'center', marginTop: '10vh'}}>
                        <SimboloExclamacion />
                        <h3 style={{marginTop: '2vh', fontSize: '2rem'}}>No existen resultados para</h3> <h3 style={{color: 'green', fontFamily: 'sans-serif', fontSize: '2rem'}}>"{busqueda}"</h3>
                        <h3 style={{fontSize: '2rem'}}>Por favor, intente de nuevo.</h3>
                    </span>
                )


                
            )
        }
        </div>
        
    ); 

}

export default PaginaBusqueda;