import '../estilos/avisoPago.css'
import Cookies from 'js-cookie';
import moment from 'moment';
import SimboloExclamacion from '../iconos/simboloExclamacion';
import { formatearFecha } from '../funcionesFront';

function AvisoPago (){

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

    const fechaPago = formatearFecha(datosUsuario.medicoActivo.fechaPago);

    return (
        
        <div className="contenedor-aviso-pago">
            <SimboloExclamacion />
            <h1 >Su pago se encuentra vencido</h1>
            <br/>
            <h3>Le recordamos que su fecha de pago corresponde al</h3> <h3 className='fecha-pago'>{fechaPago}</h3>
            <h3>Por favor registre el pago del mes para poder seguir disfrutando del servicio</h3>
            <br/>
            <h3>Saludos!</h3>
        </div>
        
    );
}

export default AvisoPago;