import React, { useEffect, useState } from 'react';
import '../estilos/nosotros.css'
import { cambiarTitulo, solicitarDatos } from '../funcionesFront';
import IconoCorreo from '../iconos/email';
import IconoTelefono from '../iconos/iconoTelefono';
import IconoWS from '../iconos/iconoWS';
import IconoIG from '../iconos/iconoIG';
import IconoTwitterX from '../iconos/iconoTwitterX';

const Contacto = () => {

  

  const [data, setData] = useState('');

  //useEffect(() => solicitarDatos('/api/datosPaginaReservacion/obtenerMensualidad/mes', 'GET', {}, setData), []);

  const nombre = 'Tu Doctor Web';

  return (
    <div className="contacto">
      
      <h2>Contáctanos</h2>
      <p>
        Si tienes alguna pregunta o consulta, no dudes en ponerte en contacto con nosotros. Estamos aquí para ayudarte en cualquier cosa que necesites.
        
      </p>

      <div className='correo'>
          <IconoCorreo /> <h2 style={{display: 'inline', marginLeft: '3vw'}}>administracion@citasfacil.com</h2>
          <br />
          <br />
          <IconoWS /> <h2 style={{display: 'inline', marginLeft: '3vw'}}>0412-7443710</h2>
      </div>

      <div className='redes-sociales'>
	  	  <br />
          <h2>Síguenos en nuestras Redes Sociales</h2>
		  <br />
		  <br />
		  <IconoIG /> <h2 style={{display: 'inline', marginLeft: '3vw'}}>@Citasfacilve</h2>
		  <br />
		  <br />
		  {/*<IconoTwitterX /> <h2 style={{display: 'inline', marginLeft: '3vw'}}>@</h2>*/}
		  
      </div>
    </div>
  );
}

export default Contacto;
