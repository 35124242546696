import '../estilos/paginaLogin.css';
import { useEffect, useState } from 'react';
import CrearContrasena from './crearContrasena';
import { fetchData } from '../funcionesFront';
import VentanaResultado from './ventanaResultado';
import { cambiarTitulo, solicitarDatos } from '../funcionesFront';
import { useNavigate, useParams } from 'react-router-dom';
import CirculoGirador from '../iconos/circuloGirador';
import apiUrl from '../url';


function PaginaRestablecerContrasena () {

    cambiarTitulo(document, "Cambio de contraseña");

    const navigate = useNavigate();

    const {token} = useParams();

    const [data, setData] = useState('');

    useEffect(() => solicitarDatos(apiUrl + `/api/restablecimientoContrasena/solicitar-pagina-restablecimiento/${token}`, 'GET', {}, setData), []);
        
    const [checkContrasenaNueva, setCheckContrasenaNueva] = useState(false);
    //para indicar que los valores no coinciden
    const [mensajeLoginInvalido, setMensajeLoginInvalido] = useState(''); 
    const [colorLogin, setColorLogin] = useState('white'); 

    const [formData, setFormData] = useState({
        newPassword: ''
    });

    const [checkEnviar, setCheckEnviar] = useState(false);

    const [modalAvisoIsOpen, setModalAvisoIsOpen] = useState(false);

    const [resultados, setResultados] = useState('');

    const handleOldPasswordChange = (event) => {
        const {value} = event.target;

        setFormData(prevState => ({
            ...prevState,
            oldPassword: value
        }));
    };

    const handleMessage = (message) => {  //recibe la contrasena nueva


        if(message!==''){

            if(formData.newPassword !== message.newPassword){
                
                setFormData(prevState => ({
                    ...prevState, 
                    newPassword: message.newPassword
                }));

            }

            setCheckContrasenaNueva(true);   //para determinar si la contrasena nueva cumple y esta lista para procesarla
        
        } else {
            setCheckContrasenaNueva(false);
        }

    } 

    const enviar = (event) => {

        event.preventDefault();
        if (checkContrasenaNueva) {
            const datos = {
              metodo: "POST",
              idElemento: 'boton-cambiar-contrasena',
              peticion: `restablecimientoContrasena/restablecer-contrasena/${token}`,
              datosReq: {
                newPassword: formData.newPassword,
              }
            };
            fetchData(datos, (resultado) => setResultados(resultado), () => setModalAvisoIsOpen(true));
          }
    }


    return (

        data !== '' && data !== false ?

            <div className='contenedor-cambiar-contrasena' >
                <form>
                <br/>
                <br/>
                <div className="mb-3">
                    <h1 className='letra-h1'>Restablecer Contraseña</h1>
                    <p>{"Estimado, " + data.nombres + " " + data.apellidos + ", en esta pagina puede restablecer la contraseña."} </p>
                    
                    <CrearContrasena onMessage={handleMessage} colorLogin={colorLogin}/>
                    <button id='boton-cambiar-contrasena' className='btn btn-outline-success' type='submit' onClick={enviar} >Cambiar Contraseña</button>
                    <br/>
                    {mensajeLoginInvalido && <h2 style={{color: 'red'}}>{mensajeLoginInvalido}</h2>}
                    <VentanaResultado isOpen={modalAvisoIsOpen} onClose={() => setModalAvisoIsOpen(false)} cerrarVentanaConfirmacion={() => navigate('/')} data={resultados}/>
                </div>
                </form>
            </div>

        : data === false ? 
            <div className='link-expirado'>
                <h1 style={{color: '#00BFFF'}}>Link Expirado o No Existe</h1>
                <h3>Por su seguridad, inicie de nuevo el proceso de restablecimiento de la contraseña</h3> 
            </div>
        : <CirculoGirador />
    );

}

export default PaginaRestablecerContrasena;