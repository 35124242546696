import { useEffect, useState } from "react";
import VentanaResultado from "./ventanaResultado";
import CustomDatePicker from "./calendario";
import { fetchData } from "../funcionesFront";
import moment from "moment";

function VentanaProgramarDiaLibre (props) {


    const [modalResultado, setModalResultado] = useState(false);
    const [resultado, setResultado] = useState('');

    //se le mandan como prop a customdatepicker para que tengan el estado actualizado tanto padre como hijo
    const [selectedDates, setSelectedDates] =useState([]);

    const [checkEnviar, setCheckEnviar] = useState(false);

    const enviar = () => {
        const fechasFormateadas = selectedDates.map(dates => moment(dates).format('YYYY-MM-DD'));
        const datos = {
            metodo: props.mode === 'retirardia' ? "POST" : "DELETE",
            peticion: `paginaReservacion/${props.mode}`,
            idElemento: 'boton-confirmar',
            datosReq: {
                ubicacion: props.ubicacion.id,
                idMedico: props.ubicacion.idMedico,
                fechas: fechasFormateadas
            }
        }
  
        fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));  
        
    }

    var diasNoLaborables = [];
    const diasDeLaSemana = {
        "domingo": 0,
        "lunes": 1,
        "martes": 2,
        "miercoles": 3,
        "jueves": 4,
        "viernes": 5,
        "sábado": 6
    }
    
    Object.keys(diasDeLaSemana).forEach(element => {
        
        !props.ubicacion.diasDeTrabajo.split(',').includes(element.substring(0,2)) ? null : diasNoLaborables.push(diasDeLaSemana[element]);
    });

    const cerrar = () => {
        setSelectedDates([]);
        props.onClose();
    }

    return(
        props.isOpen ? (
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{props.mode === 'retirardia' ? 'Programación de día(s) libre(s) de trabajo' : 'Fechas'}</h5>
                        </div>
                        
                        <div className="modal-body">
                            {props.mode === 'retirardia' ? 
                                <p style={{fontSize: '1rem'}}>Seleccione las fechas que desee cancelar. Esta acción cancelará todas las citas pautadas para esta fecha y es totalmente irreversible. En caso de proseguir, a los pacientes les llegará una notificación vía correo electrónico sobre la cancelación de las mismas.</p>
                            :
                            <p style={{fontSize: '1rem'}}>Esta ventana le sirve como calendario, donde las fechas rojas pertenecen a aquellas dentro de su horario, pero que fueron canceladas por usted, las cuales a través de esta ventana puede reincoporarlas nuevamente. </p>}
                            
                            <CustomDatePicker mode={props.mode} isDayDisabled={diasNoLaborables} selectedDates={selectedDates} setSelectedDates={(dates) => setSelectedDates(dates)} diasCancelados={props.diasCancelados}/>
                        </div>
                        <div className="modal-footer">
                            
                            {selectedDates.length > 0 ? (
                                <button type="button" id="boton-confirmar" className="btn btn-primary boton-info-liberar" onClick={() => enviar()}>{props.mode === 'retirardia' ? "Cancelar día(s)" : "Reincorporar día"}</button>
                            ) : 
                            null
                            }
                            <button type="button" className="btn btn-secondary boton-info-cerrar" onClick={() => cerrar()}>Cerrar</button>

                            <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => cerrar()} data={resultado}/>
                            
                        </div>
                    </div>
                </div>
        </div>
        
        ) : null
    );


}

export default VentanaProgramarDiaLibre;