import React from 'react';
import '../estilos/especialidades.css'; // Importa los estilos CSS
import cardio from '../imagenes/servicios/cardiologia.png';
import dermatologia from '../imagenes/servicios/dermatologia.png';
import ginecologia from '../imagenes/servicios/ginecologia.png';
import medicinaGeneral from '../imagenes/servicios/medicina_general.png';
import neumonologia from '../imagenes/servicios/neumonologia.png';
import nutricionista from '../imagenes/servicios/nutricionista.png';
import pediatria from '../imagenes/servicios/pediatria.png';
import psicologia from '../imagenes/servicios/psicologia.png';
import urologia from '../imagenes/servicios/urologia.png';


function Especialidades() {
    const images = [
        cardio,
        dermatologia,
        ginecologia,
        medicinaGeneral,
        neumonologia,
        nutricionista,
        pediatria,
        psicologia,
        urologia
    ];

    return (
        <div className="image-gallery">
            {images.map((image, index) => (
                <span>
                    <img key={index} src={image} alt={`Imagen ${index + 1}`} className="gallery-image" />
                    
                </span>
            ))}
        </div>
    );
}

export default Especialidades;
