import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../estilos/paginaInicio.css';
import Cookies from 'js-cookie';
import { cambiarTitulo, fetchData, ocultarElemento, solicitarDatos } from '../funcionesFront';
import VentanaOpcionRegistro from './ventanaOpcionRegistro';
import AvisoPago from './avisoPago';
import logo from '../imagenes/CFT.png';
import BuscadorMedico from './buscadorPaginaGM';
import SeccionFAQS from './seccionFAQS';
import Carousel from './Carousel.jsx';
import ProfesionalPanel from './professionalPanel.js';
import BenefitsSection from './beneficios.js';
import Especialidades from './especialidades.js';

function PaginaInicio () {

    cambiarTitulo(document, "Inicio");

    const navigate = useNavigate();

    const [ventanaOpcionRegistroIsOpen, setVentanaOpcionRegistroIsOpen] = useState(false);

    const [textoBotonConfirmarCorreo, setTextoBotonConfirmarCorreo] = useState('Confirmar Correo');

    var datosUsuario;
    try {
        datosUsuario = JSON.parse(Cookies.get('datosUsuario'));
    } catch (error) {
        datosUsuario = {
            id: '',
            rol: ''
        };
    }

    

  return (
    
    <div className="home-page">
        <main className="main">
            <section className="hero-section">
                <div className="hero-content">
                    <br/>
                    {datosUsuario.id !== '' ? (<>
                    
                    <h1>¡Bienvenido, {datosUsuario.nombres}!</h1>


                    {datosUsuario.rol === 'pacientes' ?
                    <>
                        <h4 className='letras-h3'>Búsqueda Inteligente</h4>
                        <BuscadorMedico />
                        <button className="cta-button" onClick={() => navigate('/guia-medica')}>Guía Médica (Directorio)</button>
                    </> 
                        
                    : null
                    }

                    {datosUsuario.medicoActivo.medicoActivo === false ? 
                    <AvisoPago />
                    
                    :
                    (null) 
                    }
                    <p></p>
                    <p></p>
                    <Carousel />
                    <p></p>
                    </>
                    ) : (
                        <>


                        <Carousel />
                        <h1 className='letras-h1'>Bienvenido a CitasFácil</h1>
                        <div>
                            <picture>
                                <img className="foto-logo" src={logo} alt="Logotipo" />
                            </picture>
                        </div>
                        <h3 className='letras-h3'>¡Tu guía médica para encontrar y manejar tus citas con profesionales de la salud!</h3>
                        <p></p>
                        <h4 className='letras-h3'>Búsqueda Inteligente</h4>
                        <BuscadorMedico />
                        <Especialidades/>

                        <button className="cta-button" onClick={() => navigate('/guia-medica')}>Guía Médica (Directorio)</button>
                        
                        <br />
                        <br />
                        <div className='contenedor-login-y-registro'>
                            <h3 className='letras-h3'>Iniciar Sesión</h3>
                            <Link to='/login/pacientes/0'>
                                <button className="cta-button">Pacientes</button>
                            </Link>
                            <Link to='/login/medicos'>
                                <button className="cta-button">Médicos</button>
                            </Link>

                            
                            <br/>
                            <h3 className='letras-h3'>¿Aún no estas registrado?</h3>
                            <h3 className='letras-h3'>¡Regístrate Ahora!</h3>
                            <button className="cta-button" onClick={() => setVentanaOpcionRegistroIsOpen(true)}>Registrarse</button>
                            <VentanaOpcionRegistro isOpen={ventanaOpcionRegistroIsOpen} onClose={() => setVentanaOpcionRegistroIsOpen(false)}/>
                        </div>

                        
                        
                        <br />

                        
                        <br />
                        
                        <BenefitsSection/>
                        <ProfesionalPanel/>
                        <SeccionFAQS />                        

                        </>
                    )}
                </div>
            </section>

        {/* Agrega aquí más secciones de contenido */}
            
        </main>
        
    </div>
    
  );
};

export default PaginaInicio;
