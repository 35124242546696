import React from "react";
import "../estilos/botonCita.css"
import Cookies from "js-cookie";

const BotonCita = (props) => {  //props.reservado es true cuando ya esta reservado y false cuando esta libre

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

    var reservado;

    if(props.reservado!='') {

        if(props.reservado.idPaciente === 0 && datosUsuario.rol === 'medicos'){

            reservado = 'medico';

        }else{

            reservado = 'true';

        }
    }else{

        reservado = false;
    }

    

    return (
        <div className='container-fluid contenedor-boton-cita'>
            <button type="button" className={`btn btn-outline-primary boton-cita-${reservado}`} onClick={props.reservado!='' && datosUsuario.rol === 'medicos' ? () => props.mostrarDatosCita(props.reservado.idReserva) : () => props.reservar(reservado, props.datos)}>{props.children}</button>
        </div>
    );
};

export default BotonCita;