import '../estilos/ventanaAvisoUnloged.css' //usa el mismo css que el de unloged
import { useNavigate } from 'react-router-dom';
import IconoCheck from '../iconos/iconoCheck';
import IconoError from '../iconos/iconoError';
import Cookies from 'js-cookie';
import CirculoGirador from '../iconos/circuloGirador';


function VentanaResultado (props) {

    const navigate = useNavigate();


    var datosUsuario;
    try {
        datosUsuario = JSON.parse(Cookies.get('datosUsuario'));
    } catch (error) {
        datosUsuario = {
            id: '',
            rol: ''
        };
    }

    const finalizar = (accion)  => {

        accion.onClose();
        
        datosUsuario.rol === 'pacientes' ?  navigate('/') : props.cerrarVentanaConfirmacion();
        
    }

    return (
        props.isOpen ? (
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Resultado</h5>
                        </div>
    
                        {props.data !== '' ? (
                            <div>
                                <div className="modal-body">
                                    {props.data.success ? (
                                        <IconoCheck tamano={100}/>
                                    ) : (
                                        <IconoError tamano={100}/>
                                    )}
                                    <br/>
                                    <br/>
                                    <p>{props.data.message}</p>
                                </div>
    
                                <div className="modal-footer">
                                    {props.data.success ? (
                                        <button type="button" className="btn btn-secondary boton-resultado-ir-a-inicio" data-bs-dismiss="modal" onClick={() => finalizar({onClose: props.onClose})}>Finalizar</button>
                                    ) : (
                                        <button type="button" className="btn btn-primary boton-resultado-cerrar" onClick={props.onClose}>Cerrar</button>
                                    )}
                                </div>
                            </div>
                        ) : <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}><CirculoGirador/></div>}
                    </div>
                </div>
            </div>
        ) : null
    );
    
    
    
    
}

export default VentanaResultado;