import {useRef, useState} from "react";
import {fetchData, cambiarFoto, cambiarTitulo, validarEmail, capitalizarPalabras, verificarCedula } from "../funcionesFront";
import VentanaResultado from "./ventanaResultado";
import sinFoto from '../imagenes/sinfoto.jpg'
import CrearContrasena from "./crearContrasena";
import Cookies from "js-cookie";
import '../estilos/registroMedico.css';
import moment from "moment";
import VentanaConfirmarCorreo from "./ventanaConfirmarCorreo";
import { useNavigate } from "react-router-dom";

function PaginaRegistroPacientes (props) {

    cambiarTitulo(document, "Registro | Pacientes");

    const [resultado, setResultado] = useState('');
    const [modalResultado, setModalResultado] = useState(false);

    const [modalIntroducirCodigo, setModalIntroducirCodigo] = useState(false);

    const navigate = useNavigate();

    const confirmarCorreo = async (event) => {

        if(checkContrasenaNueva && checkEmail){

            setResultado(''); //para evitar que salga un resultados de intentos anteriores

            setColorInput('#999');
            setMensajeInputContrasena("");

            event.preventDefault();

            const datos = {
                metodo:  "POST",
                peticion: `registro/registrarCorreo`,
                idElemento: 'boton-registrarse',
                datosReq: {
                    nombres: datosBasicos.Nombres,
                    apellidos: datosBasicos.Apellidos,
                    correo: datosBasicos['Correo Electrónico']
                }
            };


            await fetchData(datos, (resultado) => setResultado(resultado), () => setModalIntroducirCodigo(true));

        }else {
            setColorInput('red');
            setMensajeInputContrasena("El correo electrónico y/o la contraseña no cumple con los requisitos");
        }
        
    }

    const accionesModalConfirmarCorreo = () => {
        setModalIntroducirCodigo(false);
        setResultado('');
    }

    const [datosBasicos, setDatosBasicos] = useState({
        'Nombres': "",
        "Apellidos" : "",
        "Cédula de Identidad" : "",
        "Correo Electrónico" : "",
        "Teléfono": "",
    });

    const [checkEmail, setCheckEmail] = useState(false);

    const [fechaNacimiento, setFechaNacimiento] = useState('');
    const [sexo, setSexo] = useState("");

    const [img, setImg] = useState(sinFoto);
    const [imgAEnviar, setImgAEnviar] = useState('');
    
    const inputFileRef = useRef(null);

    const [newPassword, setNewPassword] = useState('');
    const [checkContrasenaNueva, setCheckContrasenaNueva] = useState(false);
    const [colorInput, setColorInput] = useState('#999');
    const [mensajeInputContrasena, setMensajeInputContrasena] = useState('');

    const inputs = {
        'Nombres': 'Nombres',
        "Apellidos" : "Apellidos",
        "Cédula de Identidad" : "V-123456789 ó E-123456789",
        "Correo Electrónico" : "juan@example.com",
        "Teléfono": '04125471475',
    }

    const handleCambioDatoBasicoChange = (event) => {
        const {name, value} = event.target;

        setDatosBasicos(prevState => ({
            ...prevState, 
            [name]: name === 'Nombres' || name === 'Apellidos' ? capitalizarPalabras(value) : name === "Cédula de Identidad" ? verificarCedula(event, prevState["Cédula de Identidad"]) : value,
        }));
    }


    const handleFileInputChange = () => {
        // Simula hacer clic en el input de tipo "file" cuando se hace clic en el botón
        inputFileRef.current.click();
      };
    
      const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          // Aquí puedes realizar acciones con el archivo seleccionado, como subirlo a un servidor o procesarlo.
          setImg(URL.createObjectURL(file));
          setImgAEnviar(file);
          
        }
      };

        const handleMessage = (message) => {  //recibe la contrasena nueva


            if(message!==''){
    
                if(newPassword !== message.newPassword){
                    
                    setNewPassword(message.newPassword);
    
                }
    
                setCheckContrasenaNueva(true);   //para determinar si la contrasena nueva cumple y esta lista para procesarla
            
            } else {
                setCheckContrasenaNueva(false);
            }
    
        } 

		const registrarse = async () => {

            

                setColorInput('white');
                setMensajeInputContrasena('');

                const datos = {
                    metodo:  "POST",
                    peticion: `registro/pacientes`,
                    idElemento: 'boton-registrarse',
                    datosReq: {
                            datosBasicos: datosBasicos,
                            fechaNacimiento: fechaNacimiento,
                            sexo: sexo,
                            newPassword: newPassword,
                            
                    }
                }

                await fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));

                try{
                    if(imgAEnviar !== ''){
                        cambiarFoto('registro','pacientes', datosBasicos['Cédula de Identidad'], imgAEnviar);
                    }
                    

                }catch{
                    console.log('No se pudo enviar la imagen')
                }
            
            
            

        }

        const checkearDatos = (event) => {
            const otrosDatos = {
                "sexo": sexo,
                "fecha-nacimiento": fechaNacimiento,
            }
            var validate = true;
            const datosInputs = {...datosBasicos, ...otrosDatos};
            Object.keys(datosInputs).forEach(element => {
                const a = datosInputs[element];
                const doc = document.getElementById(element);
                console.log(doc)
                if(a === '') {
                    doc.classList.add('input-vacio');
                    validate = false; 
                }else{
                    doc.classList.remove('input-vacio'); 
                }
            });

        
            if(validate){ 
                confirmarCorreo(event);
            }else{
                setResultado({
                    success: false,
                    message: 'Debe proporcionar todos los datos solicitados'
                });
                setModalResultado(true);
            }
        }

        
    return(
        <div className="contenedor-configuracion">
            <div className="contenedor-pagina-config">
                <div className="titulo-registro">
                    <h1>Registro de Pacientes</h1>
                </div>
                <div className="contenedor-config-personal row">

                    <div className="informacion-personal">

                        <h2>Datos Básicos</h2>

                        {Object.keys(inputs).map(dato => (
                            <div key={dato} onChange={handleCambioDatoBasicoChange}>
                                <label htmlFor="exampleFormControlInput1" className="form-label" >{dato}</label>
                                <input type='text' className="form-control" id={dato} placeholder={inputs[dato]} name={dato} value={datosBasicos[dato]} onChange={dato !== "Correo Electrónico" ? null : (event) => validarEmail(event, setCheckEmail) } style={{borderColor: dato !== "Correo Electrónico" ? "#999" : colorInput}}/>
                            </div>
                        ))}

                        <label htmlFor="exampleFormControlInput1" className="form-label">Fecha de Nacimiento</label>
                        <input type="date" className="form-control" id="fecha-nacimiento" value={fechaNacimiento} onChange={(event) => setFechaNacimiento(event.target.value)} max={moment().format('YYYY-MM-DD')}/>

                        <label htmlFor="exampleFormControlInput1" className="form-label" >Sexo</label>
                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle menu-seleccion-estado-ciudad" id="sexo" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {sexo}
                            </button>
                                <ul className="dropdown-menu valores-seleccion-estado-ciudad">
                                    {["Masculino", "Femenino"].map((sexo) => 
                                    <li key={sexo}><a className="dropdown-item" onClick={() => setSexo(sexo)}>{sexo}</a></li>
                                    )}
                                </ul>
                        </div>

                        <div className="contenedor-info-foto">
                            <p className="texto-foto-perfil">Foto de perfil</p>

                            <div className="foto-container">
                                <button className="boton-foto" onClick={handleFileInputChange}>
                                    <picture>
                                        <img className="foto-paciente" src={img} alt="Foto del Paciente" />
                                    </picture>
                                </button>
                                <input
                                type="file"
                                accept="image/*"
                                ref={inputFileRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                />
                            </div>

                            <button className="btn btn-primary" onClick={handleFileInputChange}>Cambiar foto de perfil</button>

                        </div>

                    </div>
                
                </div>
                <div style={{marginLeft: '2vw'}}>
                    <h2>Crear Contraseña</h2>
                    <CrearContrasena onMessage={handleMessage} colorLogin={colorInput}/>
                    <p style={{color: 'red'}}>{mensajeInputContrasena}</p>
                </div>
                </div>

            <div className="row contenedor-boton-guardar-cambios">
                <button className="btn btn-success boton-guardar-cambios" id='boton-registrarse' onClick={(event) => checkearDatos(event)}>Registrarse</button>
            </div>

            <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => navigate('/')} data={resultado}/>
            <VentanaConfirmarCorreo isOpen={modalIntroducirCodigo} onClose={() => accionesModalConfirmarCorreo()} resultado={resultado} registrarse={() => registrarse()}/>
        </div>
    );
}

export default PaginaRegistroPacientes;