import React, { useEffect, useState } from 'react';
import '../estilos/nosotros.css'
import { cambiarTitulo } from '../funcionesFront';

const Nosotros = () => {

  cambiarTitulo(document, 'Nosotros');

  const nombre = 'CitasFácil';

  return (
    <div className="nosotros-container">

      <div className='quienes-somos'>
        <h1>CitasFácil</h1>
        <p>
          ¡Bienvenido a nuestro sitio web! En {nombre}, nos dedicamos a ofrecer servicios de administración de citas médicas, siendo una herramienta útil para los médicos, ayudando a tener un control mucho mas eficiente de las citas que ofrecen a sus pacientes, ¡al mismo tiempo que planifica y organiza su día a día!
          
        </p>
      </div>

      <div className='mision'>
        <h2>Nuestra Misión</h2>
        <p>
          En {nombre}, nuestra misión es ofrecer servicios para los médicos en la organizacion y toma de citas en sus consultas, y al mismo tiempo facilitar al paciente la reserva de las mismas. Estamos comprometidos a brindar el mejor servicio y facilitar el dia a dia de nuestros usuarios.
        </p>
      </div>

      <div className='que-ofrece'>
        <h2>Que ofrece la aplicación</h2>
        <p>
          La aplicación ofrece lo siguiente: 
        </p>
          <ul>
            <li>Administración de citas</li>
            <li>Personalización de horarios {"(Días y/o horas)"}</li>
            <li>Guía Médica (Directorio)</li>
          </ul>
          Además de todo lo anterior, los médicos podran elevar su alcance en la zona, puesto a que podra ser visible para cualquier persona que visite la pagina, ¡sin duda llegaras a mas pacientes con la suscripción a {nombre}! Ante cualquier inquietud, no dude en comunicarse con nosotros a través de nuestros canales de <a href='/contacto'>contacto</a>, siempre estaremos a la orden de brindar la mejor atención al cliente.
        
      </div>

      <div className='planes'>
      <h2>Planes</h2>
      <p>
        En {nombre}, contamos con planes de suscripción para médicos, en los cuales se ofrecen los servicios descritos anteriormente. El registro y/o uso de la aplicación por parte de los pacientes es completamente gratis.
      </p>
      </div>

      
    </div>
  );
}

export default Nosotros;
