import { useState } from "react";
import { fetchData, calcularEdad } from "../funcionesFront";
import '../estilos/ventanaAvisoUnloged.css' //usa el mismo css que el de unloged
import VentanaResultado from "./ventanaResultado";
import moment from "moment";


function VentanaInfoCita (props) {

    const [modalResultado, setModalResultado] = useState(false);
    const [resultado, setResultado] = useState('');

    
    const liberarCita = (datos) => {

        if(datos.idPaciente == 0){

            const data = {
                metodo: "DELETE",
                peticion: `paginaReservacion/liberarcita/${datos.idReserva}`,
                idElemento: 'boton-liberar-cita',
                datosReq: {
                    ubicacion: props.ubicacion
                } //SE QUEDA VACIO POR QUE SE USAN PARAMS
            }
            fetchData(data, (resultado) => setResultado(resultado), () => setModalResultado(true));
            
        }else {

            const data = {
                metodo: "PUT",
                peticion: `paginaReservacion/cancelarcita/${datos.idReserva}`,
                idElemento: 'boton-liberar-cita',
                datosReq: {
                    ubicacion: props.ubicacion.id
                } //SE QUEDA VACIO POR QUE SE USAN PARAMS
            }
            fetchData(data, (resultado) => setResultado(resultado), () => setModalResultado(true));
            
        }
    }

    return(
        props.isOpen ? (
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Información de la Cita</h5>
                        </div>
                        
                        <div className="modal-body">
                            {props.datos.idPaciente != 0 ? (

                                <span className="contenedor-datos">
                                <div className="foto-container-info-cita">
                                    <picture>
                                        <img className="foto-paciente-info-cita" src={props.imgPaciente} alt="Foto del Paciente" />
                                    </picture>
                                </div>
                                <div className="texto-container">
                                    <p>Fecha: {moment(props.datos.fecha).format("dddd, D \\d\\e MMMM \\d\\e YYYY").replace(/^\w/, (c) => c.toUpperCase())}</p>
                                    <p>Hora: {props.datos.hora}</p>
                                    <p>Paciente: {props.datos.nombres + ' ' + props.datos.apellidos}</p>
                                    <p>Edad: {props.datos.idPaciente !== -1 ? calcularEdad(props.datos.fechaNacimiento) : props.datos.edad}</p>
                                    <p>Teléfono: {props.datos.telefono}</p>
                                    <p>Motivo de cita: {props.datos.motivo}</p>
                                </div>
                                </span>


                                ) :
                                (
                                <span>
                                    <p>Cita reservada por usted</p>
                                    <p>Motivo: {props.datos.motivo}</p>
                                </span>)

                                }
                        </div>
                        <div className="modal-footer">
                                
                                
                                    
                                <button type="button" className="btn btn-primary boton-info-liberar" id='boton-liberar-cita' onClick={() => liberarCita(props.datos)}>{props.datos.idPaciente == 0 ? "Liberar" : "Cancelar Cita"}</button>

                                <button type="button" className="btn btn-secondary boton-info-cerrar" onClick={props.onClose}>Cerrar</button>

                                <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => props.onClose()} data={resultado}/>
                            
                        </div>
                    </div>
                </div>
        </div>
        
        ) : null
    );


}

export default VentanaInfoCita;