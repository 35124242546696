import { useEffect, useState } from "react";
import { solicitarDatos, convertirNumero, revertirFormatoNumero, fetchData } from "../../funcionesFront";
import CirculoGirador from '../../iconos/circuloGirador';
import VentanaResultado from '../ventanaResultado'
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import apiUrl from "../../url";


function OpcionMetodosDePago (props) {

    const [data, setData] = useState(null);

    const [metodoSeleccionado, setMetodoSeleccionado] = useState({tipo: 'Seleccionar...', datos: ''});

    const [tipoNuevoMetodo, setTipoNuevoMetodo] = useState('');
    const [datosNuevoMetodo, setDatosNuevoMetodo] = useState('');

    const [resultado, setResultado] = useState('');
    const [modalResultado, setModalResultado] = useState(false);

    const navigate = useNavigate();

    //useEffect( () => solicitarDatos('/api/admin/operador/obtenerMetodosDePago', "GET", {}, setData), []);
    useEffect(() => {
        fetch(apiUrl + `/api/admin/operador/obtenerMetodosDePago`, {
          method: "GET", 
          headers: {
            'Content-Type': 'application/json',
            'auth': Cookies.get('authToken'),
            'datosUsuario': Cookies.get('datosUsuario')
              
          },
              
       })
        .then((res) => res.json())
        .then((data) => setData(data));
        
      }, []);

    const enviar = async (accion) => {
        console.log(data)
        if(data !== ''){
            const datos = {
                metodo:  "POST",
                peticion: accion === 'M' ? `admin/operador/modificarMetodoDePago`: `admin/operador/agregarMetodoDePago`,
                idElemento: 'boton-guardar-cambios',
                datosReq: {
                        tipo: accion === 'M' ? metodoSeleccionado.tipo : tipoNuevoMetodo,
                        datos: accion === 'M' ? metodoSeleccionado.datos : datosNuevoMetodo
                }
        }

        await fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
        }else {
            setResultado({
                success: false,
                message: 'Debe llenar el campo'
            });

            setModalResultado(true);
        }
    };

    const eliminarMetodo = async () => {
        console.log(data)
        if(metodoSeleccionado.tipo !== 'Seleccionar...'){
            const datos = {
                metodo:  "DELETE",
                peticion: `admin/operador/eliminarMetodoDePago`,
                idElemento: 'boton-guardar-cambios',
                datosReq: {
                        tipo:  metodoSeleccionado.tipo,
                        datos: metodoSeleccionado.datos
                }
        }

        await fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));
        }else {
            setResultado({
                success: false,
                message: 'Debe llenar el campo'
            });

            setModalResultado(true);
        }
    };

    


    return(
        props.opcionSeleccionada === "Métodos de pago" ?
            data !== '' ? (
                <div className="contenedor-inicio">
                    <div className="modificar-metodos">
                        <h1>Métodos de pago</h1>
                        <br/>

                        <h3>Modificar Método de Pago</h3>
                        <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {metodoSeleccionado.tipo}
                        </button>
                        <ul className="dropdown-menu menu-metodos-pago">
                            {data.map((metodo, index) => 
                                <li key={metodo}><a className="dropdown-item metodo-pago" onClick={() => setMetodoSeleccionado({tipo: metodo.tipo, datos: metodo.datos})}>{metodo.tipo}</a></li>
                            )}
                        </ul>
                        
                        <br />
                        <label htmlFor="exampleFormControlInput1" className="form-label" >Datos</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder='Datos...' value={metodoSeleccionado.datos} onChange={(event) => setMetodoSeleccionado({tipo: metodoSeleccionado.tipo, datos: event.target.value})} />
                        
                        <button className="btn btn-success boton-guardar-cambios" id='boton-guardar-cambios' onClick={() => enviar('M')}>Guardar Cambios</button>
                        <button className="btn btn-danger boton-guardar-cambios" id='boton-guardar-cambios' onClick={() => eliminarMetodo()}>Eliminar Método</button>
                        
                        <br />
                        <br />

                    </div>
                    <div className="agregar-metodo">
                        <br/>
                        <h3>Agregar Método de Pago</h3>
                        <label htmlFor="exampleFormControlInput1" className="form-label" >Tipo</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder='Tipo...' value={tipoNuevoMetodo} onChange={(event) => setTipoNuevoMetodo(event.target.value)} />
                        
                        <br/>
                        <label htmlFor="exampleFormControlInput1" className="form-label" >{'Datos (separados por comas ,)'}</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder='Tipo...' value={datosNuevoMetodo} onChange={(event) => setDatosNuevoMetodo(event.target.value)} />

                        <button className="btn btn-success boton-guardar-cambios" id='boton-guardar-cambios' onClick={() => enviar('A')}>Guardar Cambios</button>
                    </div>
                    
                    <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={() => navigate('/admin/inicio')} data={resultado}/>
                </div>
            )

            : 
            
            <div>
                
                <CirculoGirador />
            </div>
        :

        null
    );
}

export default OpcionMetodosDePago;