import React from 'react';
import '../estilos/professionalPanel.css'; // Importando la hoja de estilos CSS
import fotoMedico from "../imagenes/profesional_medico.png";

function ProfesionalPanel() {
    return (
        <div className="panel">
            
            <img src={fotoMedico} alt="Profesional de la salud" className="panel-image" />
            
            <div className="panel-content">
                <h1 className="panel-title">¿Eres Médico o Profesional de la Salud?</h1>
                <p className="panel-description">Con CitasFácil ¡Tienes el consultorio en tus manos!</p>
                <a href="/registro/medicos" className="panel-button">Regístrate hoy!</a>
            </div>
        </div>
    );
}

export default ProfesionalPanel;
