import { Link } from "react-router-dom";

function VentanaOpcionRegistro (props) {
    return(
        props.isOpen ? (
            <div className="modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Registro</h5>
                        </div>
                        
                        <div className="modal-body">
                            <h3 style={{fontSize: '1.5rem', textAlign: 'center'}}>¿Cómo desea registrarse?</h3>
                            <Link to='/registro/pacientes'>
                                <button className="btn btn-primary" onClick={props.onClose}>Como paciente</button>
                            </Link>

                            <Link to='/registro/medicos'>
                                <button className="btn btn-primary" onClick={props.onClose}>Como médico</button>
                            </Link>

                        </div>
                        <div className="modal-footer">
                            
                        <button type="button" className="btn btn-secondary boton-info-cerrar" onClick={props.onClose}>Cerrar</button>
                            
                        </div>
                    </div>
                </div>
        </div>
        
        ) : null
    );
}

export default VentanaOpcionRegistro