import '../estilos/datosMedico.css'
import { buscarCiudadAcento, buscarEstadoAcento, obtenerImagen } from '../funcionesFront';
import { useEffect, useState } from 'react';

function DatosMedico (props){

    const [img, setImg] = useState('');

    useEffect(() => {
        obtenerImagen('medicos', props.data.id, (img) => setImg(img));
    }, []);

    return (
        <div className="container-fluid datos-medico">
            <div className='row'>
                <div className='col contenedor-columna'>
                <img className="foto-medico" src={img}></img>
                </div>
                <div className='col contenedor-columna'>
                <h3>{`${props.data.sexo === 'Masculino' ? "Dr. " : "Dra. "}` + props.data.nombres + " " + props.data.apellidos}</h3>
                <h3>{props.data.especialidad}</h3>
                <h3>{"M.P.P.S: " + props.data.mpps}</h3>
                {props.data.colegio ? <h3>Número de colegio: {props.data.colegio}</h3> : null}
                <h3>{"Teléfono: " + props.data.telf}</h3>
                {props.data.ubicaciones.map(ubicacion =>
                    <h3>{`${buscarCiudadAcento(ubicacion.estado, ubicacion.ciudad)} - Edo. ${buscarEstadoAcento(ubicacion.estado)}`}</h3> 
                )}
                
                </div>
                
            </div>
        </div>
    );
}

export default DatosMedico;