import { useRef, useState} from "react";
import IconoX from "../iconos/iconoX";
import { fetchData, cambiarFoto, capitalizarPalabras } from "../funcionesFront";
import VentanaResultado from "./ventanaResultado";

function ConfigPersonal (props) {


    const [resultado, setResultado] = useState('');
    const [modalResultado, setModalResultado] = useState(false);

	const [especialidades, setEspecialidades] = useState(props.data.especialidad.split(','));
    const [img, setImg] = useState(props.img);
    const [imgAEnviar, setImgAEnviar] = useState('');
     
	const [valorInputEspecialidad, setValorInputEspecialidad] = useState('');
    const inputFileRef = useRef(null);
	const inputRef = useRef(null);

    const [enviarCorreo, setEnviarCorreo] = useState(props.data.enviarCorreo);
    

    const inputs = {
        'Nombres': 'nombres',
        "Apellidos" : "apellidos",
        "Cédula de Identidad" : "cedula",
        "Fecha de nacimiento" : "fechaNacimiento",
        "Sexo" : "sexo",
        "Teléfono" : 'telf',
        "M.P.P.S": "mpps",
        "Número de colegio": "colegio" 
    };

    const handleFileInputChange = () => {
        // Simula hacer clic en el input de tipo "file" cuando se hace clic en el botón
        inputFileRef.current.click();
      };
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          // Aquí puedes realizar acciones con el archivo seleccionado, como subirlo a un servidor o procesarlo.
          setImg(URL.createObjectURL(file));
          setImgAEnviar(file);
          
        }
      };

    
	const agregarEspecialidad = () => {
			const modificarEspecialidad = especialidades
			modificarEspecialidad.push(capitalizarPalabras(valorInputEspecialidad));
			setEspecialidades(modificarEspecialidad);
			setValorInputEspecialidad('');
			inputRef.current.value = '';
	}
		
	const eliminarEspecialidad = (especialidad) => {
		const modificarEspecialidad = especialidades.filter(a => a !== especialidad);
		setEspecialidades(modificarEspecialidad);

	}

	const handleInputEspecialidadChange = (event) => {
		setValorInputEspecialidad(event.target.value);
	}

	const guardarCambios = () => {

		const datos = {
			metodo:  "PUT",
			peticion: `config/medicos/personal`,
			idElemento: 'boton-guardar-cambios',
			datosReq: {
					idMedico: props.data.id,
					especialidades: especialidades,
                    enviarCorreo: enviarCorreo,
                        
			}
	}
        
    if(imgAEnviar !== ''){
            
        cambiarFoto('config','medicos', props.data.id, imgAEnviar);
            
    }
        

	fetchData(datos, (resultado) => setResultado(resultado), () => setModalResultado(true));

	}

    console.log(enviarCorreo);
        
    return(
        <div className="contenedor-pagina-config">
            <div className="contenedor-config-personal row">

                <div className="informacion-personal">

                {Object.keys(inputs).map(dato => (
                    <div key={dato}>
                        <label htmlFor="exampleFormControlInput1" className="subtitulos" >{dato}</label>
                        <p></p>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={props.data[inputs[dato]]} disabled/>
                        <p></p>
                    </div>
                ))}

                <div className="contenedor-info-foto">
                    <p className="subtitulos">Foto de perfil</p>

                    <div className="foto-container">
                        <button className="boton-foto" onClick={handleFileInputChange}>
                            <picture>
                                <img className="foto-paciente" src={img} alt="Foto del Paciente" />
                            </picture>
                        </button>
                        <input
                        type="file"
                        accept="image/*"
                        ref={inputFileRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        />
                    </div>

                    <button className="btn btn-success" onClick={handleFileInputChange}>Cambiar foto de perfil</button>
                </div>

                </div>
            
                <label htmlFor="exampleFormControlInput1" className="subtitulos" >Especialidades</label>
                <div className="contenedor-especialidad">

                                    {especialidades.map(especialidad => (
                                        <div className="especialidad" key={especialidad}>
                                            <p className="palabra-especialidad">
                                                {especialidad + " |"}
                                                <button type="button" className="boton-eliminar" onClick={() => eliminarEspecialidad(especialidad)}><IconoX/></button>
                                            </p>
                                        </div>
                                    ))}

                                <p></p>
                                <div className="contenedor-agregar-especialidad">
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Agregar especialidad..." onChange={handleInputEspecialidadChange} ref={inputRef}/>
                                        <button type="button" className="btn btn-success" onClick={() => agregarEspecialidad()}>Agregar Especialidad</button>
                                </div>

                                <br/>
                                <br/>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={enviarCorreo} onChange={() => setEnviarCorreo(!enviarCorreo)}/>
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Notificar vía correo electrónico cuando un paciente agende una cita</label>
                                </div>


                </div>
                
                <div className="row botones-config">
                    <button className="btn btn-success boton-guardar-cambios" id='boton-guardar-cambios' onClick={() => guardarCambios()}>Guardar Cambios</button>
                    <button className="btn btn-danger boton-cancelar" onClick={props.cancelar}>Cancelar</button>
                </div>
        
                <VentanaResultado isOpen={modalResultado} onClose={() => setModalResultado(false)} cerrarVentanaConfirmacion={props.cancelar} data={resultado}/>
            </div>
        </div>
    );
}

export default ConfigPersonal;