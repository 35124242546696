import { useEffect, useState } from "react";
import { solicitarDatos, formatearFecha, handleSubmit } from "../funcionesFront";
import CirculoGirador from '../iconos/circuloGirador';
import Cookies from "js-cookie";
import '../estilos/paginaProximasCitasPaciente.css'
import apiUrl from "../url";


function PaginaProximasCitasPaciente (props) {

    const datosUsuario = JSON.parse(Cookies.get('datosUsuario'));

    const [data, setData] = useState('');
    const [refrescar, setRefrescar] = useState('');

    //useEffect( () => solicitarDatos(`https://citasfacil.com:8443/api/paginaReservacion/proximasCitasPaciente/${datosUsuario.id}`, "GET", {}, setData), [refrescar]);

    useEffect(() => {
        fetch(apiUrl + `/api/paginaReservacion/proximasCitasPaciente/${datosUsuario.id}`, {
          method: "GET", 
          headers: {
            'Content-Type': 'application/json',
            'auth': Cookies.get('authToken'),
            'datosUsuario': Cookies.get('datosUsuario')
              
          },
              
       })
        .then((res) => res.json())
        .then((data) => setData(data));
        
      }, [refrescar]);


    const formatoDatosTabla = (dato, index) => {
        if(index === 0){
          return (formatearFecha(dato));
        }else if(index===5){
          return (dato);
        }else {
          return(dato);
        }
      };

      const cancelarCita = async (numeroDeCita) => {
        const datos = {
            metodo: "DELETE",
            peticion: `paginaReservacion/liberarcita/${numeroDeCita}`,
            idElemento: 'boton-cancelar-cita',
            datosReq: {}
        }
        await handleSubmit(datos);
        setRefrescar(numeroDeCita);  //para actualizar el estado
    }

    const cabezas = (dato) => {
        const iteraciones = {
            'Fecha': formatearFecha(dato.fecha), 
            'Hora': dato.hora, 
            'Médico': dato.nombres, 
            '': dato.apellidos, 
            'Especialidad': dato.especialidad, 
            'Dirección': dato.direccion + ", " + dato.ciudad + " - Edo. " + dato.estado, 
            'Número de Cita': dato.idReserva, 
            'Estado': <span><button className='btn btn-danger' id='boton-cancelar-cita' onClick={() => cancelarCita(dato.idReserva)}>Cancelar Cita</button></span>
        };

        return iteraciones;
    }
    
    
    const encabezados = ['Fecha', 'Hora', 'Nombres', 'Apellidos', 'Especialidad', 'Direccion', 'Número de Cita', 'Estado'];

    return(
        
            data !== '' && data !== false ? (
                
                <div className="contenedor-proximas-citas">
                    <div className="table-responsiv">
                        <h1 style={{textAlign: 'center'}}>Próximas Citas</h1>
                        
                        
                            <table>
                                <thead>
                                    <tr>
                                        {encabezados.map(cabeza => <th style={{textAlign: 'center'}}>{cabeza}</th>)}
                                        
                                    </tr>
                                </thead>
                                <tbody>
            
                                    { 
                                        data.slice(0,5).map((info, indice) => 
                                            <tr id={indice}>
                                                {Object.values(cabezas(info)).map((element, index) => <td style={{textAlign: 'center', wordBreak: index == 5 ? 'break-word' : 'normal'}}>{element}</td>)}
                                            </tr>
                                        ) 
                                    }
                                </tbody>
                            </table>
                        
                    </div>
                </div>
            )

            : 
            
            <div>
                
            {data === '' ? <div className="contenedor-proximas-citas"><CirculoGirador /></div>
            : 
            <div className="contenedor-proximas-citas">
                <h1>Próximas Citas</h1>
                <h2 style={{marginTop: '10vh', color: "#00BFFF", textAlign: 'center'}}>No tiene citas programadas en este momento</h2>
                </div>
            }    
            </div>
    );
}

export default PaginaProximasCitasPaciente;